export const convertDate = (fieldContent, isDateTimeFormatRequired = true) => {
  if (fieldContent === null) {
    return '';
  }
  const date = new Date(fieldContent);
  // Handle invalid date
  if (isNaN(date.getTime())) {
    return '';
  }

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: timeZone,
    hour12: false,
  };
  const formattedDate = date.toLocaleString('en-US', options);
  const [datePart, timePart] = formattedDate.split(', ');
  const [month, day, year] = datePart.split('/');
  const [hour, minute, second] = timePart.split(':');

  return `${month}/${day}/${year}${isDateTimeFormatRequired ? ` ${hour}:${minute}:${second}` : ''}`;
};
export const utils = { convertDate }
