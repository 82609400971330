import React, { useEffect, useState } from "react";
import "./panelView.css"
import DropdownComponent from "../../commonui/commonformcomponents/dropdown/dropdown";
import FooterTechButtons from "../../commonui/commonformcomponents/footertechbuttons/FooterTechButtons";
import { Dialog } from '@fluentui/react-northstar';
import { GrClose } from "react-icons/gr";
import { CiSearch } from "react-icons/ci";

export function PanelView({ closePanel, panelOpen, param, renderControl, addGridData, updateStepData, searchFormStepData }) {
  const [selectedGroupedParam, setSelectedGroupedParam] = useState({});
  const [showControl, setShowControl] = useState(false);
  let panelHeader = param?.isEdit ? param?.groupDetails?.searchParamsData?.groupName : param?.groupDetails?.groupName;
  useEffect(() => {
    if (param?.groupDetails?.userInputs?.selectedValues?.length > 0 && !(param?.groupedParams?.length == 1)) {
      setSelectedGroupedParam({
        key: param?.groupDetails?.searchParamsData?.paramUniqueId,
        text: param?.groupDetails?.searchParamsData?.displayName,
        content: param?.groupDetails?.searchParamsData?.displayName,
        paramId: param?.groupDetails?.searchParamsData?.paramsId
      });
    }
    else {
      setSelectedGroupedParam({});
    }
  }, [panelOpen]);
  useEffect(() => {
    if (showControl === false)
      setShowControl(true);

  }, [showControl])

  const onChangeGroupedParam = (e) => {
    updateStepData(param?.groupedParams?.filter((item) => item?.paramsId === e?.paramId)?.[0]);
    setSelectedGroupedParam(e);
    setShowControl(false);
  }

  return (

    <div className="panelViewPopupContainer">
      <Dialog
        open={panelOpen}
        onCancel={() => {
          setSelectedGroupedParam({});
          closePanel();
        }}
        backdrop={false}
        closeOnOutsideClick={false}
        className="panelViewPopup"
        content={
          <>
            <div className="panelViewPopupHeaderContent">
              <div className="panelViewPopupHeader">
                <p>
                  {panelHeader}
                </p>
                <GrClose title='Close' className="closeParamsPanel" onClick={() => { setSelectedGroupedParam({}); closePanel(); }} />
              </div>
              <div className="panelControls">
                {
                  param?.groupedParams?.length == 1
                    ?
                    renderControl(param?.groupedParams[0])
                    :
                    !param?.isEdit && <DropdownComponent
                      items={param?.groupedParams?.map((item) => { return { key: item?.paramUniqueId, text: item?.displayName, content: item?.displayName, paramId: item?.paramsId } }) || []}
                      onDropDownChange={(e) => {
                        onChangeGroupedParam(e);
                      }}
                      multiple={false}
                      placeholder={
                        <>
                          <CiSearch className="placeHolderSearchIcon" />
                          <span className="sectionPlaceHolder">Click to make a selection</span>
                        </>
                      }
                      name={"Section"}
                      value={selectedGroupedParam?.content}
                      initialLoad={true}
                      page={"LibrarySearchForm"}
                      disabled={param?.groupDetails?.userInputs?.selectedValues?.length > 0 ? true : false}
                    />
                }
                {
                  (Object.keys(selectedGroupedParam)?.length > 0) && showControl
                  && renderControl(param?.groupedParams?.filter((item) => item?.paramsId === selectedGroupedParam?.paramId)?.[0])
                }
              </div>
            </div>
            <div className="panelViewPopupFooter">
              <FooterTechButtons
                disabled={Object.keys(searchFormStepData)?.length > 0
                  && searchFormStepData?.userInputs?.selectedValues?.length > 0 ? false : true}
                cancelText={"Cancel"}
                sendText={"Add"}
                onClickAddData={() => { addGridData(param?.isEdit); setSelectedGroupedParam({}) }}
                onClickCancel={() => { setSelectedGroupedParam({}); closePanel() }}
              />
            </div>
          </>
        }
      />
    </div>
  );
}

