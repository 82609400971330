import React from "react";
import "./textEditor.css";
import Editor from "../../../commonui/editor/Editor";

const TextEditor = (props) => {
  const { addComment } = props;

  const getHTMLstring = (htmlString, item = undefined) => {
    console.log(htmlString);
    if (props?.edit) {
      props?.changeEditLoader(true);
      props?.editComment(htmlString, item, changeEditLoader);
      props?.onChangeToggleEdit();

    } else { addComment(htmlString); }
  };

  const setHTMLstring = (htmlString) => {
    console.log(htmlString);
  };

  const changeEditLoader = (val) => {
    props?.changeEditLoader(val);
  };

  return (
    <div>
      <Editor
        placeholder="Reply"
        showToolBar={true}
        getHTMLstring={getHTMLstring}
        setHTMLstring={setHTMLstring}
        editorBackgroundColor={"#FFF"}
        sendOnEnterClick={true}
        edit={props?.edit}
        {...props}
      />
    </div>
  );
};

export default TextEditor;
