import { useEffect } from "react";

import {
  createCommand,
  COMMAND_PRIORITY_NORMAL,
  COMMAND_PRIORITY_LOW,
  $getSelection,
} from "lexical";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import {
  LinkPreviewNode,
  $createLinkPreviewNode,
} from "../nodes/LinkPreviewNode";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";

export const INSERT_LINKPREVIEW_COMMAND = createCommand("insertLinkPreview");
export const BLUR_COMMAND = createCommand("BLUR_COMMAND");

export function LinkPreviewPlugin(props): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    console.log(
      "this is to see if present",
      editor.hasNodes([LinkPreviewNode])
    );
    if (!editor.hasNodes([LinkPreviewNode])) {
      throw new Error(
        "LinkPreviewPlugin: LinkPreviewPlugin not registered on editor"
      );
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_LINKPREVIEW_COMMAND,
        () => {
          console.log(props);
          const LinkPreviewNode = $createLinkPreviewNode(
            props?.item?.title,
            props?.link,
            props?.img,
            props?.contentType
          );
          $insertNodeToNearestRoot(LinkPreviewNode);
          const selection = $getSelection();
          if (selection?.getNodes().length == 1) {
            if (selection?.getNodes()?.[0].__type == "paragraph") {
              selection?.getNodes()?.[0]?.remove();
            }
          }
          return true;
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          return true;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor]);

  return null;
}
