/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Panel } from "@fluentui/react/lib/Panel";
import "./notification.css";
import Notification from "./notification";
import { UseNotificationContext } from "../../context/notificationcontext/notificationContext";
import APIEndPoints from "../../utility/apiendpoints";
import { getData } from "../../services/customApis";
import { UseUserInfoContext } from "../../context/usercontext/userContext";

const NotificationPanel = () => {
  const { notifications, handleNotifications } = UseNotificationContext();
  const { userInfo } = UseUserInfoContext();
  const dismissPanel = () => {
    const deleteDialog = document.querySelectorAll(
      ".deleteNotificationsStyles"
    );
    if (deleteDialog.length < 1) {
      handleNotifications({
        isPanelOpen: !notifications.isPanelOpen,
      });
    }
  };

  const fetchNotifications = () => {
    if (!notifications.isFullPageNotification && userInfo.teams.companyId) {
      handleNotifications({
        isFetching: notifications?.fetchOnDelete ? false : true,
      });
      //console.log(notifications.isFetching);
      let endPoint =
        APIEndPoints.COMPANY_NOTIFICATIONS_LIST +
        "?companyId=" +
        userInfo?.teams?.key +
        "&page=1" +
        "&pageSize=5";

      // ------replace below api with number of unread notifications api
      handleNotifications({
        errorApiCall: false,
        accessDenied: false,
      });
      getData(endPoint)
        .then((res) => {
          handleNotifications({
            isFetching: false,
            list: res.data.notifications,
            count: res.data.notificationCount,
            fetchOnDelete: false,
          });
        })
        .catch((error) => {
          handleNotifications(
            error?.response?.data?.Errors?.[0]?.toLowerCase() ==
              "access denied."
              ? {
                  accessDenied: true,
                  fetchOnDelete: false,
                  count: 0,
                }
              : {
                  errorApiCall: true,
                  fetchOnDelete: false,
                  count: 0,
                }
          );
        })
        .finally(() => {
          handleNotifications({
            isFetching: false,
          });
        });
    }
  };
  useEffect(() => {
    if (userInfo?.teams?.companyId !== null) {
      fetchNotifications();
    }
  }, [userInfo.teams.companyId]);
  useEffect(() => {
    if (notifications?.fetchOnDelete) {
      fetchNotifications();
    }
  }, [notifications.fetchOnDelete]);

  useEffect(() => {
    if (notifications.reloadTheNotification) {
      fetchNotifications();
      handleNotifications({
        reloadTheNotification: false,
      });
    }
  }, [notifications.reloadTheNotification]);

  return (
    <div className="notification_panel">
      <Panel
        isOpen={notifications.isPanelOpen}
        onDismiss={dismissPanel}
        isBlocking={true}
        isLightDismiss={true}
        headerText={` Notifications${
          notifications.count !== 0 ? `(${notifications.count})` : ""
        }`}
        //onRenderHeader={() => <div className='panel_header'>test </div>}
        closeButtonAriaLabel="Close"
        className="notification_panel_sidebar"
      >
        <Notification isModelOpen={true} />
      </Panel>
    </div>
  );
};
export default NotificationPanel;
