import "./Attachment.css";
import { Attachment as ProgressAttachment } from "@fluentui/react-northstar";
import React from "react";

function Attachment(props) {
  return (
    props?.files?.length > 0 && (
      <div className="attachmentClass" id="attachmentClass">
        <ProgressAttachment
          header={props?.title()}
          actionable={props?.actionable}
          action={
            (props?.uploadProgress === 100 || props?.isPdf) && {
              icon: props?.actionIcon,
              onClick: () => props?.onRemoveClick(),
              title: props?.actionTitle,
            }
          }
          description={props?.description}
          progress={props?.progress}
          className={`attachmentStyles ${props?.isErrorClass ? "ErrorAttach" : props?.removeTransition ? "removeTranform" : ""}`}
          id={"attachmentStylesId"}
          data-testid="attachment-testid"
        />
      </div>
    )
  );
}

export default Attachment;
