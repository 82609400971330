import React, { useEffect, useRef, useState, useMemo } from "react";
import { UseUserManagementContext } from "../../../../context/usermanagementcontext/userManagementContext";
import DialogBox from "../../../../commonui/dialogBox/DialogBox";
import { GrClose } from "react-icons/gr";
import { UseUserInfoContext } from "../../../../context/usercontext/userContext";
import ReusableLoader from "../../../../commonui/reusableloader/reusableLoader";
import PopupBox from "../../../../commonui/popup/popup";
import PeoplePicker from "../../../../commonui/commonformcomponents/peoplepicker/peoplePicker";
import ButtonPrimary from "../../../../commonui/button/buttonPrimary";
import UsersTable from "../../userstable/UsersTable";
import { deleteData, getData, postData } from "../../../../services/customApis";
import APIEndPoints from "../../../../utility/apiendpoints";
import { UseToasterNotificationsContext } from "../../../../context/toasternotificationscontext/toasterNotificationsContext";
import _ from "lodash";
import TelemetryEventNames from "../../../../data/apptelemetry/appTelemetry.const";
import { TrackEvent } from "../../../../services/appInsights";
import { app, pages } from "@microsoft/teams-js";
import Constant from "../../../../utility/constants";
import {
  Input,
  Dropdown,
  Flex,
  List,
  Image,
  Text,
} from "@fluentui/react-northstar";
import { ImageUtil, getCountry } from "../../../../utility/utility";
import "./AddLeaveTeamDialogBoxes.css";

const listItems = [
  {
    key: "irving",
    header: "Irving Kuhic",
    headerMedia: "7:26:56 AM",
    content: "Program the sensor to the SAS alarm through the haptic SQL card!",
    media: <Image src={ImageUtil("Irving Kubrick")} avatar />,
  },
  {
    key: "skyler",
    header: "Skyler Parks",
    headerMedia: "11:30:17 PM",
    content:
      "Use the online FTP application to input the multi-byte application!",
  },
  {
    key: "dante",
    header: "Dante Schneider",
    headerMedia: "5:22:40 PM",
    content: "The GB pixel is down, navigate the virtual interface!",
  },
];

const AddUsersComponent = (props) => {
  const { usersData, handleDataChange } = UseUserManagementContext();
  const { userInfo } = UseUserInfoContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();

  const { setLoaderVal, loader } = props;

  const [addUser, setAddUser] = useState([]);
  const [inviteUser, setSetInviteUser] = useState(false);
  const [externalUser, setExternalUser] = useState(false);
  // const [users, setUsers] = useState([]);
  const [clear, setClear] = useState(false);
  const [alreadyExist, setAlreadyExist] = useState(false);
  const [alreadyExistMsg, setAlreadyExistMsg] = useState("");
  const [freeFormEmail, setFreeFormEmail] = useState("");
  const [clearSearchQuery, setClearSearchQuery] = useState(false);

  const { addUsersList, roles, fetchedFeatureFlags, featureFlags } = usersData;

  const onUserChange = (item) => {
    setClear(false);
    setAddUser(item);
    setExternalUser(!item?.[0]?.isInternal);
  };

  const addUsers = () => {
    setLoaderVal(true);
    setAlreadyExistMsg("");
    const item = addUser?.[0];
    let roletype = item?.isInternal ? 1 : 2;
    let role = roles?.find(
      (i) => i?.isDefaultRole === true && i?.roleType === roletype
    );

    if (!inviteUser)
      role = roles?.find((i) => i?.roleId === 2 && i?.roleName === "Member");

    console.log(item);

    let tempUser = {
      name: item?.name,
      title: item?.jobTitle ?? "",
      organization: item?.companyName ?? "",
      location: item?.officeLocation ?? "",
      role: role?.roleName ?? "Member",
      userProfileId: item?.id,
      email: item?.userid,
      isInternal: item?.isInternal ?? false,
      roles: role?.roleName ?? "Member",
    };

    // let role = roles?.find((i) => i?.roleName
    //     ?.toLowerCase() === "member");
    let reqObj = {
      // companyId: userInfo.teams.companyId,
      members: [
        inviteUser
          ? {
              userEmail: freeFormEmail,
              roleId: role?.roleId ?? 0,
              userName: "",
            }
          : {
              userEmail: item?.email,
              roleId: role?.roleId ?? 0,
              userName: item?.displayName ?? "",
              // adUserId: "",
              // tenantId: "",
              // title: item?.jobTitle ?? "",
              // organization: item?.companyName ?? "",
              // location: item?.officeLocation ?? "",
              firstName: item?.givenName ?? "",
              lastName: item?.surName ?? "",
              country: item?.country ?? "",
            },
      ],
    };

    postData(
      reqObj,
      APIEndPoints.USER_MANAGEMENT_ADD_USER(userInfo.teams.companyId)
    )
      .then((res) => {
        console.log(res);
        // setLoaderVal(false);
        // inviteUser && setClearSearchQuery(true);
        if (!item?.isInternal || inviteUser) {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: "User invited successfully",
            type: "Success",
            isFailed: false,
          });
        } else if (featureFlags?.requestMember?.requestMemberEnabled) {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: "Request to add user(s) was successfully sent",
            type: "Success",
            isFailed: false,
          });
        } else {
          setAlreadyExist(false);
          let tempUserWithFilter = {
            ...tempUser,
            slug: JSON.stringify(Object?.values(tempUser))?.toLowerCase(),
          };
          if (
            usersData.usersList?.filter(
              (item) => item?.email === tempUser?.email
            ).length === 0
          ) {
            handleDataChange({
              usersList: [...usersData.usersList, tempUserWithFilter],
              addUsersList: [...addUsersList, tempUserWithFilter],
            });
          } else {
            handleDataChange({
              usersList: [
                ...usersData.usersList?.filter(
                  (item) => item?.email !== tempUser?.email
                ),
                tempUserWithFilter,
              ],
              addUsersList: [
                ...addUsersList?.filter(
                  (item) => item?.email !== tempUser?.email
                ),
                tempUserWithFilter,
              ],
            });
          }
        }

        let trackEventObj = inviteUser
          ? {
              ImpactedUserEmail: freeFormEmail,
              CurrentRole: role?.roleName ?? "Member",
              ImpactedUserName: "",
              Title: "",
              Organization: "",
              Location: "",
              IsInternal: false,
            }
          : {
              ImpactedUserEmail: item?.email,
              CurrentRole: role?.roleName ?? "Member",
              ImpactedUserName: item?.displayName ?? "",
              Title: item?.jobTitle ?? "",
              Organization: item?.companyName ?? "",
              Location: item?.officeLocation ?? "",
              IsInternal: item?.isInternal ?? false,
            };
        TrackEvent(
          !item?.isInternal || inviteUser
            ? TelemetryEventNames.UCC_UMM_INVITE_USER
            : TelemetryEventNames.UCC_UMM_ADD_USER,
          userInfo,
          {
            SectionName: TelemetryEventNames.SECTION_TYPE_OTHER,
            ...trackEventObj,
          }
        );
      })
      .catch((e) => {
        try {
          if (
            _.includes(
              e?.response?.data?.Errors?.toString().toLowerCase(),
              "exist"
            )
          ) {
            setAlreadyExist(true);
            setAlreadyExistMsg(e?.response?.data?.Errors);
          } else {
            handleNotificationMsgsData({
              showMsgBar: true,
              started: false,
              completed: true,
              msg: e?.response?.data?.Errors,
              type: "fail",
              isFailed: false,
            });
          }
        } catch {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: e?.response?.data?.Errors,
            type: "fail",
            isFailed: false,
          });
        }
      })
      .finally(() => {
        // setUsers((prev) => [...prev, tempUser]);
        setClear(true);
        setAddUser([]);
        setInviteUserVal(false);
        setClearSearchQuery(true);
        setExternalUser(false);
        setLoaderVal(false);
      });
  };

  const setInviteUserVal = (val) => {
    setSetInviteUser(val);
  };

  const setFreeFormUserVal = (val) => {
    setFreeFormEmail(val);
  };
  const setExternalUserVal = (val) => {
    // setExternalUser(val);
  };
  const setClearSearchQueryVal = (val) => {
    setClearSearchQuery(val);
  };

  useEffect(() => {
    resetUsersList();
    return () => {
      resetUsersList();
    };
  }, []);

  const resetUsersList = () => {
    handleDataChange({
      addUsersList: [],
    });
  };
  return (
    <div className="add-users-container">
      {/* <div className="top-border-div"></div> */}
      <p style={{ padding: "10px 0" }}>
        This is a private channel, so only the people you add here will see it.
      </p>
      <div className="add-users__people-picker">
        <PeoplePicker
          about="OK"
          search={addUser?.length === 0}
          multiple={true}
          placeholder={"Start typing a name or email id of the user"}
          checkable={true}
          fluid={true}
          userManagement={true}
          onUserChange={onUserChange}
          className={
            addUser?.length !== 0 || inviteUser || loader
              ? "add-users__people-picker__dropdown"
              : ""
          }
          clear={clear}
          inviteUser={setSetInviteUser}
          setFreeFormUserVal={setFreeFormUserVal}
          clearSearchQuery={clearSearchQuery}
          setExternalUserVal={setExternalUserVal}
          setClearSearchQueryVal={setClearSearchQueryVal}
          userType={1}
          searchAd={true}
          // disabled={true}
        />
        <ButtonPrimary
          text={
            featureFlags?.requestMember?.requestMemberEnabled
              ? "Request"
              : featureFlags?.addMember?.addMemberEnabled && "Add"
          }
          onClick={addUsers}
          disabled={
            addUser?.length === 0 ||
            inviteUser ||
            loader ||
            !fetchedFeatureFlags ||
            !featureFlags?.addMember?.addMemberEnabled
          }
        />
      </div>
      <div className="add-users__results">
        {alreadyExist && alreadyExistMsg !== "" && (
          <div className="users-already-exist">
            <span>{alreadyExistMsg}</span>{" "}
            <span
              title="Close"
              onClick={() => setAlreadyExist(false)}
              style={{ cursor: "pointer", paddingRight: "10px" }}
            >
              &#10005;
            </span>
          </div>
        )}
        <div className="users-results">
          <UsersTable
            list={addUsersList}
            showHeaders={false}
            tableType={2}
            fromAddEYUsers={true}
          />
        </div>
      </div>
      {loader && (
        <div className="add-user-overlayLoader ">
          <ReusableLoader isOverlay={true} />
        </div>
      )}
    </div>
  );
};

const AddExternalUsersComponent = (props) => {
  const { userInfo } = UseUserInfoContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const { usersData, handleDataChange } = UseUserManagementContext();
  const [freeFormEmail, setFreeFormEmail] = useState("");
  const [clearSearchQuery, setClearSearchQuery] = useState(false);
  const [inviteUser, setSetInviteUser] = useState(false);
  const [clear, setClear] = useState(false);
  const [externalUser, setExternalUser] = useState(false);
  const [addUser, setAddUser] = useState([]);

  const [alreadyExist, setAlreadyExist] = useState(false);
  const [alreadyExistMsg, setAlreadyExistMsg] = useState("");

  const [usersTable, setUsersTable] = useState([]);

  const { setLoaderVal, loader } = props;
  const { addUsersList, roles, fetchedFeatureFlags, featureFlags } = usersData;

  const countryObject = useMemo(() => getCountry(), []);

  const [countries, setCountries] = useState(["United States"]);

  const [userInviteObj, setUserInviteObj] = useState({
    firstName: "",
    lastName: "",
    email: "",
    location: countries[0],
  });
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const handleChange = (e) => {
    if (e?.target?.value?.length > 200) {
      setError((prevState) => ({
        ...prevState,
        [e.target.name]: `Length cannot be more than 200 chars`,
      }));
    } else {
      setError((prevState) => ({ ...prevState, [e.target.name]: "" }));
    }
    setUserInviteObj({
      ...userInviteObj,
      [e.target.name]: e.target.value,
    });
  };
  const handleDropdownChange = (e, data) => {
    setUserInviteObj({
      ...userInviteObj,
      location: data.value,
    });
  };
  const resetField = () => {
    setUserInviteObj({
      firstName: "",
      lastName: "",
      email: "",
      location: countries[0],
    });
    setLoaderVal(false);
    setClear(true);
    setAddUser([]);
  };

  const setFreeFormUserVal = (val) => {
    setFreeFormEmail(val);
    setUserInviteObj({
      ...userInviteObj,
      email: val,
    });
  };

  const onUserChange = (item) => {
    setClear(false);
    setAddUser(item);
    setExternalUser(!item?.[0]?.isInternal);
    if (item?.[0]?.email) {
      if (item?.[0]?.email.indexOf("ey.com") === -1) {
        setUserInviteObj({
          ...userInviteObj,
          email: item?.[0]?.email,
        });
      }
    }
  };

  const inviteUserMethod = (val) => {
    setSetInviteUser(val);
    if (val === false) {
      setUserInviteObj({
        ...userInviteObj,
        email: "",
      });
    }
  };

  const checkIfbuttonShouldBeDisabled = () => {
    if (
      userInviteObj.firstName === "" ||
      userInviteObj.lastName === "" ||
      userInviteObj.email === "" ||
      userInviteObj.location === "" ||
      error.firstName !== "" ||
      error.lastName !== "" ||
      error.email !== ""
    ) {
      return true;
    }
    return false;
  };

  const setExternalUserVal = (val) => {
    // setExternalUser(val);
  };

  const setClearSearchQueryVal = (val) => {
    setClearSearchQuery(val);
  };
  const handleOnClicked = () => {
    setLoaderVal(true);
    console.log(userInviteObj);
    let roletype = 2;
    let role = roles?.find(
      (i) => i?.isDefaultRole === true && i?.roleType === roletype
    );
    setUsersTable([...usersTable, userInviteObj]);
    let reqObj = {
      // companyId: userInfo.teams.companyId,
      members: [
        {
          userEmail: userInviteObj.email,
          firstName: userInviteObj.firstName,
          lastName: userInviteObj.lastName,
          country: userInviteObj.location,
          roleId: role?.roleId ?? 0,
          userName: userInviteObj.firstName + " " + userInviteObj.lastName,
        },
      ],
    };

    postData(
      reqObj,
      APIEndPoints.USER_MANAGEMENT_ADD_USER(userInfo.teams.companyId)
    )
      .then((res) => {
        console.log(res);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: "User invited successfully",
          type: "Success",
          isFailed: false,
        });
        setUsersTable([
          ...usersTable,
          {
            key: userInviteObj.email,
            header: userInviteObj.firstName + " " + userInviteObj.lastName,
            content: userInviteObj.location,
            media: (
              <Image
                src={ImageUtil(
                  userInviteObj.firstName + " " + userInviteObj.lastName
                )}
                avatar
              />
            ),
          },
        ]);
      })
      .catch((e) => {
        try {
          if (
            _.includes(
              e?.response?.data?.Errors?.toString().toLowerCase(),
              "exist"
            )
          ) {
            setAlreadyExist(true);
            setAlreadyExistMsg(e?.response?.data?.Errors);
          } else {
            handleNotificationMsgsData({
              showMsgBar: true,
              started: false,
              completed: true,
              msg: e?.response?.data?.Errors,
              type: "fail",
              isFailed: false,
            });
          }
        } catch {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: e?.response?.data?.Errors,
            type: "fail",
            isFailed: false,
          });
        }
        setLoaderVal(false);
      })
      .finally(() => {
        setClearSearchQuery(true);
        resetField();
      });
  };

  return (
    <div className="containerExtUserForm">
      <div className="firstRow">
        <Flex gap="gap.small">
          <div className="firstNameClass">
            <Input
              placeholder="Type first name"
              value={userInviteObj.firstName}
              onChange={handleChange}
              name="firstName"
            />
            <div className="errorClass">
              {error.firstName && <Text error content={error.firstName} />}
            </div>
          </div>
          <div className="lastNameClass">
            <Input
              placeholder="Type last name"
              value={userInviteObj.lastName}
              onChange={handleChange}
              name="lastName"
            />
            <div className="errorClass">
              {error.lastName && <Text error content={error.lastName} />}
            </div>
          </div>
        </Flex>
      </div>
      <div className="secondRow">
        <Flex gap="gap.small">
          <div className="emailClass">
            <Input
              placeholder="Start typing email id of the user"
              value={userInviteObj.email}
              onChange={(e, val) => {
                if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)) {
                  handleChange(e);
                } else {
                  setUserInviteObj({
                    ...userInviteObj,
                    email: e.target.value,
                  });
                  setError((prevState) => ({
                    ...prevState,
                    [e.target.name]: `Please type a valid email id`,
                  }));
                }
              }}
              name="email"
            />
            <div className="errorClass">
              {error.email && <Text error content={error.email} />}
            </div>
          </div>
          <div className="locationClass">
            <Dropdown
              items={countries}
              multiple={false}
              fluid
              placeholder="Select location"
              checkable
              onChange={handleDropdownChange}
              getA11ySelectionMessage={{
                onAdd: (item) => `${item} has been selected.`,
              }}
              value={userInviteObj.location}
            />
          </div>
        </Flex>
      </div>
      <div className="inviteButtonClass">
        <ButtonPrimary
          text="Invite"
          onClick={handleOnClicked}
          disabled={checkIfbuttonShouldBeDisabled()}
        />
      </div>
      <div className="add-users__results">
        {alreadyExist && alreadyExistMsg !== "" && (
          <div className="users-already-exist">
            <span>{alreadyExistMsg}</span>{" "}
            <span
              title="Close"
              onClick={() => {
                setAlreadyExist(false);
                resetField();
              }}
              style={{ cursor: "pointer", paddingRight: "10px" }}
            >
              &#10005;
            </span>
          </div>
        )}
        <List items={usersTable} />
      </div>
      {loader && (
        <div className="add-user-overlayLoader ">
          <ReusableLoader isOverlay={true} />
        </div>
      )}
    </div>
  );
};

const AddLeaveTeamDialogBoxes = () => {
  const { usersData, handleDataChange } = UseUserManagementContext();
  const { userInfo, handleUserInfoChange } = UseUserInfoContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();

  const {
    addMemberDialogOpen,
    addExternalMemberDialogOpen,
    leaveCompanyDialogOpen,
  } = usersData;

  const [loader, setLoader] = useState(false);
  const { fetchedFeatureFlags } = usersData;

  const handleDialogToggle = (name, val) => {
    handleDataChange({ [name]: val });
  };

  const setLoaderVal = (val) => {
    setLoader(val);
  };

  const onLeaveCompany = () => {
    setLoader(true);
    deleteData(
      "",
      APIEndPoints.USER_MANAGEMENT_LEAVE_COMPANY(userInfo.teams.companyId)
    )
      .then((res) => {
        handleDataChange({
          usersList: usersData?.usersList.filter(
            (i) => i?.email?.toLowerCase() !== userInfo?.email?.toLowerCase()
          ),
          addUsersList: usersData?.addUsersList.filter(
            (i) => i?.email?.toLowerCase() !== userInfo?.email?.toLowerCase()
          ),
          filterUsersList: usersData?.filterUsersList.filter(
            (i) => i?.email?.toLowerCase() !== userInfo?.email?.toLowerCase()
          ),
        });
        let tempComp = userInfo?.allCompanies?.filter(
          (i) => i?.companyId !== userInfo.teams.companyId
        );
        window.localStorage.removeItem("curUserSelectedCompany");
        if (tempComp.length > 0) {
          handleUserInfoChange({
            teams: tempComp[0],
          });
          handleUserInfoChange({
            allCompanies: tempComp,
          });

          handleDataChange({
            resetCompanies: !usersData?.resetCompanies,
          });

          window.location.href = "/";
        } else {
          if (res?.data?.[0]?.isExternalUser) {
            handleUserInfoChange({ teams: { privacyAgreementDeclined: true } });
          } else {
            if (userInfo?.loggedInWeb == false) {
              app.getContext().then((item) => {
                if (item.page.id != Constant.HomeTab) {
                  let parms = {
                    appId: Constant.GetUserAppId(item?.user?.userPrincipalName),
                    pageId: Constant.HomeTab,
                  };
                  pages.navigateToApp(parms);
                } else {
                  window.location.href = "/";
                }
              });
            } else {
              window.location.href = "/";
            }
          }

          handleDataChange({
            resetCompanies: !usersData?.resetCompanies,
          });
        }
        TrackEvent(TelemetryEventNames.UCC_UMM_LEAVE_COMPANY, userInfo, {
          SectionName: TelemetryEventNames.SECTION_TYPE_OTHER,
        });
      })
      .catch((err) => {
        handleDataChange({
          usersList: usersData?.usersList.map((i) =>
            i?.email === userInfo.email
              ? {
                  ...i,
                  leaveError: true,
                  leaveErrorMsg: err?.response?.data?.Errors,
                }
              : i
          ),
          addUsersList: usersData?.addUsersList.map((i) =>
            i?.email === userInfo.email
              ? {
                  ...i,
                  leaveError: true,
                  leaveErrorMsg: err?.response?.data?.Errors,
                }
              : i
          ),
          filterUsersList: usersData?.filterUsersList.map((i) =>
            i?.email === userInfo.email
              ? {
                  ...i,
                  leaveError: true,
                  leaveErrorMsg: err?.response?.data?.Errors,
                }
              : i
          ),
        });
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors,
          type: "fail",
          isFailed: false,
        });
      })
      .finally(() => {
        setLoader(false);
        handleDataChange({ leaveCompanyDialogOpen: false });
      });
  };

  useEffect(() => {
    if (
      (usersData.roles.length === 0 && !usersData.fetchingRoles) ||
      !fetchedFeatureFlags
    ) {
      handleDataChange({ fetchingRoles: true });

      let subMenuItemId =
        userInfo?.teams?.featureFlagsObj["Your Channel"]?.subMenuItemId;

      getData(
        APIEndPoints.GET_PAGE_LEVEL_FLAGS(
          userInfo?.teams?.companyId,
          subMenuItemId
        )
      )
        .then(async (response) => {
          response.data.contentType.map((item) => {
            if (item.subMenuItemId === subMenuItemId) {
              handleDataChange({
                featureFlags: {
                  ...item?.contentCategories?.[0]?.featureFlagsDetails,
                  ...item?.contentCategories?.[1]?.featureFlagsDetails,
                },
                fetchedFeatureFlags: true,
              });
              // console.log({
              //   ...item?.contentCategories?.[0]?.featureFlagsDetails,
              //   ...item?.contentCategories?.[1]?.featureFlagsDetails,
              // });
            }
          });
        })
        .then(() => {
          getData(
            APIEndPoints.USER_MANAGEMENT_GET_ROLES(userInfo.teams.companyId)
          ).then((res) => {
            handleDataChange({
              roles: _.sortBy(
                res?.data?.output_Data?.filter(
                  (item) => item?.roleCategory !== 2
                ),
                "roleName"
              ),
              fetchingRoles: false,
            });
          });
        })
        .catch(() => {});
    }
  }, []);

  return (
    <>
      <DialogBox
        cancelButton={"Cancel"}
        onCancelMethod={() => {
          handleDialogToggle("leaveCompanyDialogOpen", false);
        }}
        closeOnOutsideClick={false}
        confirmButton={"Leave channel"}
        onConfirmMethod={onLeaveCompany}
        content={
          loader === true ? (
            <div
              className={loader && "loaderLayover userManagementLoaderLayover"}
            >
              <ReusableLoader
                isOverlay={true}
                label={
                  <p>
                    Are you sure you want to leave the channel "
                    {userInfo?.teams?.companyName}"?
                  </p>
                }
                labelPosition={"start"}
              />
            </div>
          ) : (
            <p>
              Are you sure you want to leave the channel "
              {userInfo?.teams?.companyName}"?
            </p>
          )
        }
        headerName={`Leave channel - "${userInfo?.teams?.companyName}"`}
        headerActionName={{
          icon: <GrClose />,
          title: "Close",
          onClick: () => {
            handleDialogToggle("leaveCompanyDialogOpen", false);
          },
        }}
        backdrop={true}
        openDialog={leaveCompanyDialogOpen}
        className={`dialogDeleteStyles userManagementDialog ${
          loader && "dialogLayover"
        }`}
      />
      <PopupBox
        title={`Add EY users to "${userInfo?.teams?.companyName}"`}
        isFooter={true}
        isBorderNeeded={true}
        isModalOpen={addMemberDialogOpen}
        hideModal={() => {
          handleDialogToggle("addMemberDialogOpen", false);
        }}
        showSendButton={false}
        userManagement={true}
        cancelButtonText={"Close"}
        backdrop={true}
        className={`dialogDeleteStyles manageTeamPopupStyle userManagementDialog addMemberDialog ${
          loader && " addMemberDialog__overlay"
        }`}
      >
        <AddUsersComponent setLoaderVal={setLoaderVal} loader={loader} />
      </PopupBox>

      <PopupBox
        title={`Add external users to "${userInfo?.teams?.companyName}"`}
        isFooter={true}
        isBorderNeeded={true}
        isModalOpen={addExternalMemberDialogOpen}
        hideModal={() => {
          handleDialogToggle("addExternalMemberDialogOpen", false);
        }}
        showSendButton={false}
        userManagement={true}
        cancelButtonText={"Close"}
        backdrop={true}
        className={`dialogDeleteStyles  userManagementDialog addMemberDialog addExternalUSerDialog ${
          loader && " addMemberDialog__overlay"
        }`}
      >
        <AddExternalUsersComponent
          setLoaderVal={setLoaderVal}
          loader={loader}
        />
      </PopupBox>
    </>
  );
};

export default AddLeaveTeamDialogBoxes;
