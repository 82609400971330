import React, { useEffect, useState } from "react";
import { postData, getData } from "../../services/customApis";
import APIEndPoints from "../../utility/apiendpoints";
import TemplateControl from "../../commonui/commonformcomponents/templatecontrol/templatecontrol";
import PopupBox from "../../commonui/popup/popup";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import axiosJWTDecoratorInstance from "../../services/axiosJWTdecorator";
import PostWithProgressEvent from "../../services/postWithProgressEvent";
import { getBaseUrl } from "../../utility/utility";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";

const UploadChanneLogo = (props) => {
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const { userInfo, handleUserInfoChange } = UseUserInfoContext();
  const { uploadForm } = PostWithProgressEvent();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [loader, setLoader] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [allKey, setAllKeys] = useState({
    uploadCompanyIcon: {
      hasAnyError: false,
      errorMessage: "",
      currentValue: null,
    },
  });

  const closeModal = () => {
    setIsModalOpen(false);
    props.handleChannelLogoDialogToggle();
  };

  const uploadChannelLogo = async () => {
    setLoader(true);
    let filestoAttach = [];
    let formData = new FormData();
    if (attachment.length > 0) {
      filestoAttach.push(attachment[0]);
    }
    formData.append("formFile", attachment[0]);

    axiosJWTDecoratorInstance
      .setupAuthorizationHeader()
      .then(async (response) => {
        await uploadForm(
          formData,
          response,
          getBaseUrl() +
            "/api" +
            APIEndPoints.UPLOAD_COMPANY_LOGO(userInfo?.teams?.companyId),
          true
        )
          .then((response) => {
            setLoader(false);
            closeModal();
            handleNotificationMsgsData({
              showMsgBar: true,
              started: false,
              completed: true,
              msg: response.data,
              type: "Success",
              isFailed: false,
            });

            getData(APIEndPoints.GET_COMPANY_LOGO(userInfo?.teams?.companyId))
              .then((res) => {
                res.data.isSuccess == true
                  ? handleUserInfoChange({ channelLogo: res.data.base64Image })
                  : handleUserInfoChange({ channelLogo: "" });
              })
              .catch((err) => {
                console.log("error", err);
              });
          })
          .catch((err) => {
            setLoader(false);
            closeModal();
            handleNotificationMsgsData({
              showMsgBar: true,
              started: false,
              completed: true,
              msg: err?.response?.data?.Errors,
              type: "fail",
              isFailed: false,
            });
          });
      });
  };

  const updateValidationState = (name, value, hasAnyError, errMessage) => {
    let updatedObj = {};
    let obj = {
      currentValue: value,
      hasAnyError: hasAnyError,
      errorMessage: errMessage,
    };
    updatedObj = {
      ...allKey,
      [name]: obj,
    };
    setAllKeys({ ...updatedObj });
  };

  const setEditProperties = (obj) => {
    console.log("inside set edit mode");
  };

  const fileHandler = (
    e,
    fieldName,
    name,
    isCustomField,
    isContentField,
    parentName,
    hasAnyError,
    errMessage
  ) => {
    const file = e == null ? undefined : e.target?.files[0] || e;
    let attachmentArray = [];
    if (file) {
      attachmentArray.push(file);
    }
    setAttachment(attachmentArray);
    updateValidationState(name, file, hasAnyError, errMessage);
  };

  return (
    <PopupBox
      title={"Upload Channel Logo"}
      isFooter={true}
      isBorderNeeded={true}
      isModalOpen={isModalOpen}
      hideModal={() => {
        setIsModalOpen(false);
        props.handleChannelLogoDialogToggle();
      }}
      backdrop={true}
      hideCloseIcon={false}
      className={"calendarPopupStyle addMemberDialog"}
      cancelButtonText={"Cancel"}
      showSendButton={true}
      sendButtonText={"Upload"}
      sendButtonDisabled={
        attachment.length == 0 || allKey.uploadCompanyIcon.hasAnyError
      }
      onClick={uploadChannelLogo}
    >
      <TemplateControl
        multiple={false}
        rootProps={{ className: "DropzoneBody" }}
        fieldsName={"iconUploaField"}
        onDrop={fileHandler}
        hasAnyError={allKey.uploadCompanyIcon.hasAnyError}
        errMessage={allKey.uploadCompanyIcon.errorMessage}
        validations={[
          {
            name: "FileType",
            allowedTypes: ["jpeg", "png", "jpg"],
            errorMessage: "Incorrect Image file. Review and re-upload.",
          },
          {
            name: "FileLength",
            maxLength: 2048,
            errorMessage: "Max allowed file size 2MB",
          },
        ]}
        parentName={"imageControl"}
        files={attachment}
        name={"uploadCompanyIcon"}
        placeholder={"Drag and drop a channel logo or"}
        // placeholderSubText={"Accepted files: 16x16 png file"}
        editMode={true}
        updateValidationState={updateValidationState}
        setEditProperties={setEditProperties}
        additionalMessage={
          "(Only JPG or PNG are supported up to a max file size of 2MB)"
        }
      />
      {loader && (
        <div className={loader && "loaderLayover bulletinBoardLoaderLayover"}>
          <ReusableLoader isOverlay={true} />
        </div>
      )}
    </PopupBox>
  );
};

export default UploadChanneLogo;
