import React, { useState, useEffect } from "react";
import CardExampleSize from "../../commonui/card/Card";
import APIEndPoints from "../../utility/apiendpoints";
import Constant from "../../utility/constants";
import { getData, getDataById, postData } from "../../services/customApis";
import PageNotFound from "../../commonui/pagenotfound/pageNotFound";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import LoadMore from "../../commonui/loadmorebtn/loadmore";
import { useMenuContext } from "../../context/menucontext/menuContext";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { isVisible } from "../../utility/menuscroll";
import { app } from "@microsoft/teams-js";
import {
  downloadPdf,
  getUrlParams,
  updateLocalStorageGroupMembers,
  updateViewCounter,
} from "../../utility/utility";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import { scrollIntoViewWithOffset } from "../../../src/utility/menuscroll";
import "./singleTypeDataContainer.css";
import { UseContentPaginateConfigContext } from "../../context/contentpaginateconfigcontext/contentPaginateConfigContext";
import ComingSoon from "../../commonui/comminsoon/comingSoon";
import { useNavigate, useParams } from "react-router-dom";
import { TrackEvent } from "../../services/appInsights";
import TelemetryEventNames from "../../data/apptelemetry/appTelemetry.const";
import {
  getContentTypeNamesById,
  getContentTypePathsById,
} from "../../utility/utility";
import DialogBox from "../../commonui/dialogBox/DialogBox";
import UnauthorizedPage from "../../commonui/unauthorizedpage/unauthorized";
import { GrClose } from "react-icons/gr";
import _ from "lodash";
import DeepLinkPopup from "../../components/polls/pollsCurationForm/pollPopup";
import { useLocation } from "react-router-dom";
import { UseLibrarySearchFormContext } from "../../context/librarysearchformcontext/librarySearchFormContext";
export const SingleTypeDataContainer = (props) => {
  const location = useLocation();
  const params = useParams();
  const { menuState } = useMenuContext();
  const { userInfo } = UseUserInfoContext();
  const { contentPaginateConfig } = UseContentPaginateConfigContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const [allCardsData, setAllCardsData] = useState({});
  const [cardConfigData, setCardConfigData] = useState([]);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [errorAPI, setErrorAPI] = useState(false);
  const [isAPICalled, setIsAPICalled] = useState(false);
  const [tilesFetchDetails, setTilesFetchDetails] = useState(
    props.cardFetchObj
  );
  const [detailsPageAccess, setDetailsPageAccess] = useState(true);
  const [internalAppDetails, setInternalAppDetails] = useState({});
  const [contentTypeLevelFlags, setContentTypeLevelFlags] = useState(undefined);
  const [errorAccessDenied, setAccessDenied] = useState(false);
  const [changeViewCount, setChangeViewCount] = useState(0);
  const [privilagesForTPAlertsApp, setPrivilagesForTPAlertsApp] = useState({});
  const navigate = useNavigate();
  const { librarySearchFormJsonData, handleLibrarySearchFormJsonData } =
    UseLibrarySearchFormContext();
  useEffect(() => {
    if (
      !isAPICalled &&
      ((props?.menuLevel ? true : menuState.submenu.name === props.name) ||
        props?.appsDetails) &&
      !allCardsData["sections"]?.all
    ) {
      setIsCardLoading(true);
      let subMenuItemId =
        userInfo?.teams?.featureFlagsObj[
          props?.appsDetails ? "Apps" : props.name
        ]?.subMenuItemId;
      getDataById(
        "",
        APIEndPoints.GET_PAGE_LEVEL_FLAGS(
          userInfo?.teams?.companyId,
          subMenuItemId
        )
      )
        .then(async (res) => {
          getUrlParams("backButton")
            ? getDataFromCache()
            : getCardDataFromAPI();
        })
        .catch(async (error) => {
          if (
            error?.response?.data?.Errors?.[0]?.toLowerCase() ==
            "access denied."
          ) {
            setAccessDenied(true);
          } else {
            setErrorAPI(true);
          }
          setIsCardLoading(false);
        });
    }
  }, [menuState.submenu.name]);

  const getCardDataFromAPI = () => {
    setTimeout(() => {
      if (isVisible(props.id)) {
        setIsAPICalled((prevValue) => {
          if (prevValue === false) {
            getCardLimit();
            getCardData();
            return true;
          }
          return false;
        });
      }
    }, 1000);
  };
  const handleAPIError = (isAccessDenied) => {
    if (isAccessDenied) {
      setAccessDenied(true);
    } else {
      setErrorAPI(true);
    }
  };
  const getCardLimit = () => {
    getData(
      APIEndPoints.CARD_LIMIT(props.contentTypeId, userInfo.teams.companyId)
    )
      .then((res) => {
        let data = Object.fromEntries(
          res.data.map((item) => [item.subContentTypeName, item.cardLimit])
        );
        setTilesFetchDetails((prevData) => {
          return {
            ...prevData,
            all: {
              ...prevData.all,
              pageLength: data["All"] / contentPaginateConfig.limit ?? 6,
            },
          };
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getDataFromCache = () => {
    if (!window.location.pathname?.includes("appsDetails")) {
      window.history.replaceState(null, "", props?.id + "?loadFromCache=true");
    }
    // getting data from cache
    let dataItem = JSON.parse(localStorage.getItem(props.name));
    if (!dataItem) {
      //if data is not present in cache, we are calling api
      getCardLimit();
      getCardData();
      return;
    }

    // else setting data from cache
    setAllCardsData(dataItem?.allCardsData);
    setCardConfigData(dataItem?.cardConfigData);
    setTilesFetchDetails(dataItem?.cardFetchDetails);
    setContentTypeLevelFlags(dataItem?.contentTypeLevelConfig);
    // set the flags again
    props.setNewButtonConfig(
      dataItem?.contentTypeLevelConfig?.[1]?.featureFlagsDetails?.addNew
    );
    props.setShortcutButtonConfig(
      dataItem?.contentCategories?.[1]?.featureFlagsDetails?.myShortcuts
    );
    props.setLibraryButtonConfig(
      userInfo?.teams?.isGlobalContentCompany == true
        ? dataItem?.contentTypeLevelConfig?.[0]?.featureFlagsDetails?.library
        : dataItem?.contentTypeLevelConfig?.[1]?.featureFlagsDetails?.library
    );
    setDetailsPageAccess(dataItem?.contentTypeLevelConfig);
    if (props?.appsDetails) {
      props?.setTotalTileCount(dataItem?.totalGroupMembers);
    }
    setIsCardLoading(false);
  };

  const getCardData = async (isLoadMore, cardFetch) => {
    !isLoadMore && setIsCardLoading(true);
    let param = {
      pageLength: 6,
      pageNumber: 1,
      subContentType: "All",
    };
    if (isLoadMore) {
      param = {
        ...param,
        pageLength: isNaN(tilesFetchDetails.all.pageLength)
          ? 6
          : tilesFetchDetails.all.pageLength,
        pageNumber: tilesFetchDetails.all.page,
        subContentType: "All",
      };
    }
    if (props?.appsDetails) {
      param = {
        ...param,
        groupMetaDataId: parseInt(params?.metaDataId),
      };
    }
    await postData(
      param,
      APIEndPoints.CONTENT_DATASET(
        props.contentTypeId,
        userInfo?.teams?.companyId
      )
    )
      .then((contentData) => {
        const newData =
          contentData.data.resultData == undefined
            ? contentData?.data
            : contentData?.data?.resultData;

        if (isLoadMore) {
          newData.sections = {
            ...allCardsData?.["sections"],
            all: allCardsData?.["sections"]?.all.concat(newData?.sections?.all),
          };
        }
        setAllCardsData(newData);
        setIsCardLoading(false);
        if (cardConfigData.length < 1) {
          handleCardConfig(contentData?.data?.contentType);
        }

        const {
          customData: {
            pageinfo: { rowIndex, totalCount } = { rowIndex: 0, totalCount: 0 },
          } = { pageinfo: { rowIndex: 0, totalCount: 0 } },
        } = newData?.sections?.all?.at(-1) || {};

        setTilesFetchDetails((newData) => {
          let previousData = {};
          if (cardFetch) {
            previousData = props?.cardFetchObj;
          } else {
            previousData = newData;
          }
          return {
            ...previousData,
            all: {
              ...previousData?.all,
              isFetching: false,
              page: isLoadMore
                ? previousData?.all?.page + 1
                : previousData?.all?.page,
              hasMoreData: rowIndex !== totalCount,
            },
          };
        });

        if (props?.appsDetails) {
          props?.setTotalTileCount(totalCount);
          updateLocalStorageGroupMembers({
            metaDataId: parseInt(params?.metaDataId),
            contentType: 13,
            groupInfo: { totalMembers: totalCount },
          });
        }
      })
      .catch((error) => {
        setIsCardLoading(false);
        if (
          error?.response?.data?.Errors?.[0]?.toLowerCase() == "access denied."
        ) {
          setAccessDenied(true);
        } else {
          setErrorAPI(true);
        }
      });
  };

  const handleCardConfig = async (configData) => {
    if (
      configData !== undefined &&
      configData[0]?.contentTypeId === props?.contentTypeId
    ) {
      setCardConfigData(configData[0]?.subContentTypes[0]?.contentCategories);
      setContentTypeLevelFlags(configData[0]?.contentCategories);

      props.setNewButtonConfig(
        configData[0]?.contentCategories?.[1]?.featureFlagsDetails?.addNew
      );
      props.setShortcutButtonConfig(
        configData[0]?.contentCategories?.[1]?.featureFlagsDetails?.myShortcuts
      );
      props.setLibraryButtonConfig(
        userInfo?.teams?.isGlobalContentCompany == true
          ? configData[0]?.contentCategories?.[0]?.featureFlagsDetails?.library
          : configData[0]?.contentCategories?.[1]?.featureFlagsDetails?.library
      );

      setDetailsPageAccess(configData[0]?.contentCategories);
    }
  };

  const handleLoadMore = () => {
    setTilesFetchDetails((previousData) => {
      return {
        ...previousData,
        all: {
          ...previousData.all,
          isFetching: true,
        },
      };
    });
    getCardData(true);
  };
  // Need to add navigation details for each content type ID
  const contentTypeNavigationMap = {
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK]: {
      path: "/apps/LibrarySearchPage",
      state: (internalAppDetails) => ({ appDetails: internalAppDetails })
    },
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES]: {
      path: "/apps/LibrarySearchPage",
      state: (internalAppDetails) => ({ appDetails: internalAppDetails })
    },
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS]: {
      path: "/apps/LibraryGrid",
      state: (internalAppDetails) => ({
        appDetails: internalAppDetails,
        formJson: null,
        templateDetails: { templateId: "10" }
      }),
      preNavigate: () => handleLibrarySearchFormJsonData({
        columnFilteringApplied: false,
        appliedFilteredValues: {},
        searchQuery: "",
        totalRecordsCount: 0
      })
    }
  };

  const navigateToContentType = async (contentTypeID, internalAppDetails) => {
  const navigationDetails = contentTypeNavigationMap[contentTypeID];
    if (navigationDetails) {
      if (navigationDetails.preNavigate) {
        navigationDetails.preNavigate();
      }
      navigate(navigationDetails.path, {
        state: navigationDetails.state(internalAppDetails)
      });
    } else {
      console.error("Unsupported contentTypeID:", contentTypeID);
    }
  };

  const navigateToInternalApps = async (internalAppDetails, contentTypeID) => {
    try {
      await navigateToContentType(contentTypeID, internalAppDetails);
    } catch (error) {
      console.error("Failed to navigate internal app details:", error);
    }
  };

  const handleOnTileClick = (param, section) => {
    let detailsAccess;
    if (param?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL) {
      detailsAccess =
        detailsPageAccess?.[0]?.featureFlagsDetails?.detailsPage
          ?.detailsPageDisplay == undefined
          ? true
          : detailsPageAccess[0]?.featureFlagsDetails?.detailsPage
            ?.detailsPageDisplay;
    } else {
      detailsAccess =
        detailsPageAccess?.[1]?.featureFlagsDetails?.detailsPage
          ?.detailsPageDisplay == undefined
          ? true
          : detailsPageAccess[1]?.featureFlagsDetails?.detailsPage
            ?.detailsPageDisplay;
    }
    if (detailsAccess == true) {
      let allCardsWithConfiguration = {
        allCardsData: allCardsData,
        cardFetchDetails: tilesFetchDetails,
        contentTypeLevelConfig: contentTypeLevelFlags,
        cardConfigData: cardConfigData,
      };
      if (props?.appsDetails) {
        allCardsWithConfiguration = {
          ...allCardsWithConfiguration,
          totalGroupMembers: props?.totalTileCount,
        };
      }
      localStorage.setItem(
        props.name,
        JSON.stringify(allCardsWithConfiguration)
      );
      // For localhost rendering:
      // const pdfWindow = window.open();
      // pdfWindow.location.href = param.articleUrl;
      // check if its tax agenda cause for ta , content info not available
      if (param?.type?.toLowerCase() === "group") {
        navigate(
          `/appsDetails/${param.metaDataId}/${props.contentTypeId}/${param?.groupMetadataId
          }/${param?.title}?prevPath=${window.location.pathname
          }&section=${section}&BB=${location?.state?.isFromBB ? true : false}`
        );
        viewCounterFunc(param, true);
      } else if (param?.contentInfo) {
        param?.contentInfo?.map(async (item) => {
          if (item.contentSourceType === "URL") {
            // go to link outside of the teams
            try {
              let urlToBeOpened = atob(item.url);
              viewCounterFunc(param);

              userInfo.loggedInWeb === true
                ? window.open(urlToBeOpened, "_blank")
                : app.getContext().then((item) => {
                  if (item.app.host.name == "Teams") {
                    app.openLink(urlToBeOpened);
                  } else {
                    window.open(urlToBeOpened, "_blank");
                  }
                });
            } catch (e) {
              console.log(e);
            }
          } else if (item.contentSourceType === "Template") {
            // download the pdf
            //downloadPdf(param.metaDataId, props.contentTypeId, item.content);

            navigate(
              `/pdfDetails/${param.metaDataId}/${props.contentTypeId}/${param?.contentSecurity?.availability?.domainLevel ==
                Constant.GLOBAL
                ? true
                : false
              }?prevPath=${window.location.pathname}&section=${section}&BB=${location?.state?.isFromBB ? true : false
              }`
            );
          } else if (
            item?.contentSourceType?.toLowerCase() === "HTML"?.toLowerCase()
          ) {
            //navigate to detail page
            navigate(
              `/itemDetails/${param.metaDataId}/${props.contentTypeId}/${param?.contentSecurity?.availability?.domainLevel ==
                Constant.GLOBAL
                ? true
                : false
              }?prevPath=${window.location.pathname}&section=${section}&BB=${location?.state?.isFromBB ? true : false
              }`
            );
          } else if (
            item?.contentSourceType?.toLowerCase() === "App"?.toLowerCase() &&
            param.isInternal == true
          ) {
            //navigate to internal Apps filter page with passing parameter values as content Type ID
            let internalAPPContentTypeID = Constant.INTERNAL_APPS_CONTENT_TYPE_IDS[item.appName] || 0;
            viewCounterFunc(param);
            let internalAppDetails = {
              breadCrumb: param.title ?? "",
              contentTypeId: internalAPPContentTypeID ?? 0,
              isInternal: param.isInternal ?? false,
              appID: item.appId ?? "",
              appName: item.appName ?? "",
              isGlobal: param?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL ? true : false,
              version: item?.searchTemplateVersion ?? 0
            }
         
            setInternalAppDetails(internalAppDetails);
            navigateToInternalApps(internalAppDetails, internalAPPContentTypeID);
          }
        });
      } else {
        if (param?.articleUrl) {
          userInfo.loggedInWeb === true
            ? window.open(param.articleUrl, "_blank")
            : app.getContext().then((item) => {
              if (item.app.host.name == "Teams") {
                app.openLink(param.articleUrl);
              } else {
                window.open(param.articleUrl, "_blank");
              }
            });
        }
      }
    } else {
      handleNotificationMsgsData({
        showMsgBar: true,
        started: false,
        completed: true,
        msg: Constant.ACCESSDENIED_DETAILSPAGE,
        type: "fail",
        isFailed: false,
      });
    }
  };
  const getInternalAppDetails = async (contentTypeId, appId) => {
    
    let params = {
      appId: appId,
      filter: ""
    }
    try {
      const response = await postData(
        params,
        APIEndPoints.GET_INTERNAL_APP_DETAILS(
          contentTypeId,
          userInfo?.teams?.companyId
        )
      );
      return response.data;
    } 
    catch (error) {
      if (
        error?.response?.data?.Errors[0]?.toLowerCase() === "access denied."
      ) {
        setAccessDenied(true);
      } 
    }
  };

  useEffect(() => {
    if(menuState.to == "/apps" || menuState.data == "/apps")
      {
        handleLibrarySearchFormJsonData(
          {
            powerKLibrarySearchGridData: [],
            powerKBooleanExpression: "",
            columnFilteringApplied: false,
            appliedFilteredValues: {},
            searchQuery: "",
            totalRecordsCount: 0
          });
        if(librarySearchFormJsonData.isBackToSearchClicked != false)
          {
            // let librarySearchFormJsonDataCopy = {...librarySearchFormJsonData};
            // for (const key in librarySearchFormJsonDataCopy) {
            //   if (librarySearchFormJsonData.hasOwnProperty(key) && typeof librarySearchFormJsonData[key] == "object") {
            //     librarySearchFormJsonDataCopy[key] = {};
            //   }
            // }
            handleLibrarySearchFormJsonData(
            {
                jsonStructure: {},
                isBackToSearchClicked: false,
                resetLibrarySearchFormJson: {},
                columnFilteringApplied: false,
                appliedFilteredValues: {},
                searchQuery: "",
                totalRecordsCount: 0
            });
          }
      }
  }, [menuState.menu]);
  const viewCounterFunc = (param, updateLS = false) => {
    let paramViewCounter = {
      metaDataId: param.metaDataId,
    };
    postData(
      paramViewCounter,
      APIEndPoints.CONTENT_VIEW_COUNTER(
        props.contentTypeId,
        param?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId
      )
    )
      .then((res) => {
        if (res.data.statusCode == "STCS002" || res.data == "") {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: Constant.ContentNotAvailable,
            type: "fail",
            isFailed: false,
          });
        } else {
          TrackEvent(TelemetryEventNames.UCC_CONTENT_VIEW_EVENT, userInfo, {
            SectionName:
              props?.contentTypeId == 4
                ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
                : TelemetryEventNames.SECTION_TYPE_CONTENT,
            ContentTypeId: props.contentTypeId.toString() ?? "",
            ContentTypeName: getContentTypeNamesById(props.contentTypeId) ?? "",
            MetadataId: param.metaDataId.toString(),
            UniqueId: param.contentUniqueId ?? "",
            Title: param.title ?? "",
            PublishDate: param.publishedDate.toString() ?? "",
            LatestViewCount: res?.data?.resultData?.viewCount.toString() ?? "",
          });
          setChangeViewCount(res?.data?.resultData?.viewCount);
          setAllCardsData(
            updateViewCounter(
              param,
              res?.data?.resultData?.viewCount,
              allCardsData,
              updateLS,
              props?.name
            )
          );
        }
      })
      .catch((err) => {
        console.log(err);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors,
          type: "fail",
          isFailed: false,
        });
      });
  };

  const handleReload = async () => {
    try {
      let topbarHeight = document
        .getElementById("topbar")
        .getBoundingClientRect().height;
      scrollIntoViewWithOffset(props?.id, -(topbarHeight + 25 - 10)); //added -10 so the scroll is little bigger so menuState and breadcrumb work correctly.
    } catch (e) {
      console.log(e);
    }
    await getCardData(undefined, true);
  };

  const updatePinInfoByMetaDataId = (metaDataId, pinInfoObject) => {
    setAllCardsData((prevData) => {
      const newData = _.cloneDeep(prevData);

      for (const sectionName in newData?.sections) {
        const section = newData?.sections[sectionName];
        const match = section.find((item) => item?.metaDataId === metaDataId);

        if (match) {
          match.customData.pinInfo = {
            ...match.customData.pinInfo,
            ...pinInfoObject,
          };
        }
      }

      return newData;
    });
  };

  if (errorAPI) return <PageNotFound />;
  if (errorAccessDenied) return <UnauthorizedPage />;
  return (
    <>
      {isCardLoading ? (
        <ReusableLoader />
      ) : (
        Object.entries(allCardsData).length !== 0 && (
          <div className="CardComponent">
            {allCardsData?.["sections"]?.all?.length > 0 ? (
              <>
                <div className="CardList">
                  {allCardsData?.["sections"]?.all?.map((item) => (
                    <CardExampleSize
                      key={item.metaDataId}
                      id={item.metaDataId}
                      item={item}
                      config={
                        item?.contentSecurity?.availability?.domainLevel ==
                          Constant.GLOBAL
                          ? cardConfigData?.[0]?.featureFlagsDetails
                          : cardConfigData?.[1]?.featureFlagsDetails
                      }
                      isImage={true}
                      contentType={props.contentTypeId}
                      pathname={props.id}
                      showMoreButton={true}
                      onClick={handleOnTileClick}
                      handleReload={handleReload}
                      contentTypeName={props?.name}
                      section={"all"}
                      contentSource={item.contentSource}
                      updatePinInfoByMetaDataId={updatePinInfoByMetaDataId}
                      changeViewCount={changeViewCount}
                    />
                  ))}
                </div>
                <LoadMore
                  isFetching={tilesFetchDetails.all.isFetching}
                  hasMoreData={tilesFetchDetails.all.hasMoreData}
                  onClick={() => handleLoadMore()}
                />
              </>
            ) : (
              <ComingSoon />
            )}
          </div>
        )
      )}
      {location?.state?.contentTypeName?.toLowerCase() ===
        props?.name?.toLowerCase() &&
        location?.state?.companyId > 0 && (
          <DeepLinkPopup
            location={{ ...location.state }}
            allCardsData={allCardsData}
            handleReload={handleReload}
            handleOnCardClick={handleOnTileClick}
            name={getContentTypeNamesById(location?.state?.contentType) ?? ""}
            path={getContentTypePathsById(location?.state?.contentType)}
            contentType={
              location?.state?.contentType &&
              parseInt(location?.state?.contentType)
            }
            updatePinInfoByMetaDataId={updatePinInfoByMetaDataId}
            handleAPIError={handleAPIError}
            {...props}
          />
        )}
    </>
  );
};
