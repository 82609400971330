import React from 'react';
import { getData, postData } from '../../services/customApis';
import { UseToasterNotificationsContext } from '../../context/toasternotificationscontext/toasterNotificationsContext';
import Constant from '../../utility/constants';

export default function ActionButton(props) {
    const { item, alertMessage, setAlertMessage, type, setAlertMessageType, isModelOpen } = props;
    const fileDetails = JSON.parse(item?.actionUrl);
    const { Body, FileName, Name, Type, Verb } = fileDetails;
    const { handleNotificationMsgsData } = UseToasterNotificationsContext();

    const showError = (error) => {
        if (isModelOpen) {
            setAlertMessageType("fail");
            setAlertMessage(error?.response?.data?.Errors);
        }
        else {
            handleNotificationMsgsData({
                showMsgBar: true,
                started: false,
                completed: true,
                msg: error?.response?.data?.Errors,
                type: "fail",
                isFailed: false,
            });
        }
    };
    const handleClick = async () => {
        try {
            switch (Type?.toLowerCase()) {
                case "download":
                    downloadAttachment();
                    break;
                default: break;
            }

        }
        catch {

        }
    };


    const getFileType = (fileName) => {
        if (fileName?.toLowerCase().includes(".pdf")) {
            return "application/pdf";
        }
        else if (fileName?.toLowerCase().includes(".xml")) {
            return "application/xml";
        }
        else if (fileName?.toLowerCase().includes(".xlsx")) {
            return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        }
    };

    const downloadAttachment = async () => {
        try {
            let response;
            if (isModelOpen) {
                setAlertMessageType("info");
                setAlertMessage("Downloading content...");
            }
            else {
                handleNotificationMsgsData({
                    showMsgBar: true,
                    started: false,
                    completed: true,
                    msg: "Downloading content...",
                    type: "Info",
                    isFailed: false,
                });

            }


            if (Verb?.toLowerCase() === "get") {
                response = await getData("/" + fileDetails?.URL)
                    .catch((error) => {
                        showError(error);
                    });
            }
            else {
                response = await postData({}, "/" + fileDetails?.URL)
                    .catch((error) => {
                        showError(error);
                    });
            }


            if (response.status != 200) {
                alert("Download attachment failure!");
            } else {
                if (response.data !== null) {                    
                    if(item.type == "Visualization"){
                        if (response.data.fileStream !== null) {
                            const filename = response.data.fileName,
                              base64Url = response.data.fileStream;
                  
                            //convert base64 to array buffer
                            const binaryString = window.atob(base64Url);
                            const bytes = new Uint8Array(binaryString.length);
                            for (let i = 0; i < binaryString.length; i++) {
                              let ascii = binaryString.charCodeAt(i);
                              bytes[i] = ascii;
                            }
                  
                            //create blob obj and download file
                            const blob = new Blob([bytes]);
                            const fileName = `${filename}`;
                            if (navigator.msSaveBlob) {
                              navigator.msSaveBlob(blob, fileName);
                            } else {
                              const link = document.createElement("a");
                              if (link.download !== undefined) {
                                const url = URL.createObjectURL(blob);
                                link.setAttribute("href", url);
                                link.setAttribute("download", fileName);
                                link.style.visibility = "hidden";
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                // alert("Download attachment successful");
                              }
                            }
                          }
                          else{
                            handleNotificationMsgsData({
                                showMsgBar: true,
                                started: true,
                                completed: false,
                                msg: response.data.responseMessage,
                                type: "Info",
                                isFailed: false,
                              });
                          }
                    }
                    else{
                        response?.data?.attachments?.map((item) => {
                            // const item = response.data;
                            const filename = item.fileName,
                                // extension = item.fileType,
                                base64Url = item.fileStream;
    
                            //convert base64 to array buffer
                            const binaryString = window.atob(base64Url);
                            const bytes = new Uint8Array(binaryString.length);
                            for (let i = 0; i < binaryString.length; i++) {
                                let ascii = binaryString.charCodeAt(i);
                                bytes[i] = ascii;
                            }
    
                            //create blob obj and download file
                            const blob = new Blob([bytes], { type: item?.fileType ?? getFileType(filename) });
                            const fileName = `${filename}`;
                            if (navigator.msSaveBlob) {
                                navigator.msSaveBlob(blob, fileName);
                            } else {
                                const link = document.createElement("a");
                                if (link.download !== undefined) {
                                    const url = URL.createObjectURL(blob);
                                    link.setAttribute("href", url);
                                    link.setAttribute("download", fileName);
                                    link.style.visibility = "hidden";
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                    // alert("Download attachment successful");
                                }
                            }
    
                            if (isModelOpen) {
                                setAlertMessageType("Success");
                                setAlertMessage(response.data.responseMessage ?? Constant.FILE_DOWNLOADED_SUCCESSFULLY);
                            }
                            else {
                                handleNotificationMsgsData({
                                    showMsgBar: true,
                                    started: false,
                                    completed: true,
                                    msg: response.data.responseMessage ?? Constant.FILE_DOWNLOADED_SUCCESSFULLY,
                                    type: "Success",
                                    isFailed: false,
                                });
    
                            }
    
                        });
                    }                   
                }
            }
        }
        catch { }
    };

    return (
        <div
            tabIndex={0}
            className="mark_delete_btn"
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    handleClick();
                }
            }}
            onClick={() => handleClick()
            }
        >
            {Name ?? "Download"}
        </div >
    );
}
