import _ from "lodash";
import React, { useState, createContext, useContext, useEffect } from "react";
import { UseUserInfoContext } from "../usercontext/userContext";

export const channelManagementObj = {
  pagination: {
    pageLength: 10,
    pageNumber: 1,
  },
  searchText: "",
  channelsList: [],
  filterChannelsList: [],
  addChannelsList: [],
  fetchingChannelsList: false,
  fetchingSearchResult: false,
  owner: false,
  featureFlags: {},
  fetchedFeatureFlags: false,
  showRequestChannel: false,
  addDelegateDialogOpen: false,
  companyOnboardTrackingId: null,
  isDelegateVisible: false,
  isDelegateEnabled: false,
  isReviewVisible: false,
  isReviewEnabled: false,
  isDelegateDisable: false,
  isAccessRequested: false
};

export const pendingApprovalManagementObj = {
  pagination: {
    pageLength: 10,
    pageNumber: 1,
  },
  // searchText: "",
  pendingChannelsList: [],
  // filterChannelsList: [],
  // addChannelsList: [],
  pendingFetchingChannelsList: false,
  // fetchingSearchResult: false,
  PendingOwner: false,
  pendingFeatureFlags: {},
  pendingFetchedFeatureFlags: false,
  // showRequestChannel: false
};

const channelManagementContextData = createContext({
  channelsData: channelManagementObj,
  handleDataChange: (props) => { },
});

const pendingApprovalContextData = createContext({
  pendingApprovalchannelsData: pendingApprovalManagementObj,
  handlePendingDataChange: (props) => { },
});

export function UsePendingApprovalManagementContext() {
  return useContext(pendingApprovalContextData);
}

export function UseChannelManagementContext() {
  return useContext(channelManagementContextData);
}

const ChannelManagementContext = ({ children }) => {
  const [channelsData, setChannelsData] = useState(channelManagementObj);
  const { userInfo } = UseUserInfoContext();
  const [pendingApprovalchannelsData, setPendingApprovalchannelsData] =
    useState(pendingApprovalManagementObj);

  const handlePendingDataChange = (props) => {
    setPendingApprovalchannelsData((prevData) => {
      return { ...prevData, ...props };
    });
  };

  const handleDataChange = (props) => {
    setChannelsData((prevData) => {
      return { ...prevData, ...props };
    });
  };

  const searchChannels = (searchString) => {
    if (!searchString) return [];

    const lowerSearch = searchString.toLowerCase();

    return channelsData?.channelsList?.filter((channel) => {
      return channel?.slug.includes(lowerSearch);
    });
  };

  useEffect(() => {
    if (channelsData.searchText !== "") {
      handleDataChange({
        filterChannelsList: searchChannels(channelsData.searchText),
      });
    }
  }, [channelsData.searchText]);

  // useEffect(() => {
  //   console.log(pendingApprovalchannelsData);
  // }, [pendingApprovalchannelsData]);

  return (
    <channelManagementContextData.Provider
      value={{
        channelsData,
        handleDataChange,
        pendingApprovalchannelsData,
        handlePendingDataChange,
      }}
    >
      {children}
    </channelManagementContextData.Provider>
  );
};

export default ChannelManagementContext;
