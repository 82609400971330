import React, { useEffect, useState, useMemo } from 'react';
import { Modal } from "@fluentui/react";
import CardExampleSize from "../../../commonui/card/Card";
import APIEndPoints from '../../../utility/apiendpoints';
import { postData } from "../../../services/customApis";
import './pollPopup.css';
import { MdClose } from "react-icons/md";
import { Image } from "@fluentui/react-northstar";
import Constant from "../../../utility/constants";
import { UseToasterNotificationsContext } from "../../../context/toasternotificationscontext/toasterNotificationsContext";
import BulletinCard from "../../../components/bulletinboard/bulletincard/bulletinCard";
import DialogBox from "../../../commonui/dialogBox/DialogBox";
import { getContentTypePathsById } from '../../../utility/utility';
import { UseUserInfoContext } from '../../../context/usercontext/userContext';

function DeepLinkPopup(props) {
    const [cardData, setCardData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { handleNotificationMsgsData } = UseToasterNotificationsContext();
    const { userInfo, handleUserInfoChange } = UseUserInfoContext();

    const fetchCardData = (param) => {
        postData(
            param,
            props?.isBulletinBoard == true || props?.location?.isFromCard
                ? APIEndPoints.GET_SINGLE_PINNED_CARD(props?.location?.isGlobal, props?.location?.companyId)
                : APIEndPoints.GET_DEEPLINK_CARDDETAILS_BY_ID(userInfo?.teams?.companyId, props.contentType, props?.location?.companyId)
        ).then(async (response) => {
            let _data = props?.isBulletinBoard == true || props?.location?.isFromCard
                ? response?.data
                : JSON.parse(response?.data?.contentDetails);
            if (_data.statusCode == "STCS002" || _data.status?.toLowerCase() == "failed") {
                handleNotificationMsgsData({
                    showMsgBar: true,
                    started: false,
                    completed: true,
                    msg: Constant.ContentNotAvailable,
                    type: "fail",
                    isFailed: false,
                });
            } else if (_data?.statusCode === "STCS001" || response?.status == 200) {
                if (props?.isBulletinBoard || props?.location?.isFromCard) {
                    let data = await props?.getCombinedData(_data)?.[0];
                    setCardData(() => data);
                } else {
                    setCardData(() => _data);
                }
                try {
                    setTimeout(() => { document?.getElementById(getContentTypePathsById(props.contentType))?.scrollIntoView(); }, 3000);
                } catch { }
            }
        }).catch(error => {
            console.log(error);
            if (error?.response?.data?.Errors[0]?.toLowerCase() == "access denied.") {
                props?.handleAPIError(true);
            } else {
                props?.handleAPIError(false);
                handleNotificationMsgsData({
                    showMsgBar: true,
                    started: false,
                    completed: true,
                    msg: error?.response?.data?.Errors[0] ? error?.response?.data?.Errors[0] : Constant.ContentNotAvailable,
                    type: "fail",
                    isFailed: false,
                });
            }
        }).finally(() => {
            handleUserInfoChange({ singlePinCardFetched: true });
        });
    };

    useEffect(() => {
        const param = props?.location?.isFromCard == true || props?.isBulletinBoard == true
        ? {
            contentTypeId: parseInt(props.contentType),
            pinMetaDataId: props?.location?.pinMetaDataId ? parseInt(props?.location?.pinMetaDataId) : 0,
            metaDataId: props?.location?.metaDataId ? parseInt(props?.location?.metaDataId) : 0,
        }
        : {
            metaDataId: parseInt(props?.location?.metaDataId),
            triggeredFrom: props?.name
        };

    if (!props?.isBulletinBoard && props?.location?.metaDataId != undefined && parseInt(props?.location?.metaDataId) == 0 && !props?.location?.isFromCard) {
        // handleNotificationMsgsData({
        //     showMsgBar: true,
        //     started: false,
        //     completed: true,
        //     msg: Constant.ContentNotAvailable,
        //     type: "fail",
        //     isFailed: false,
        // });
    } else {
        if (!userInfo.singlePinCardFetched) {
            fetchCardData(param);
        } else {
            try {
                fetchCardData(param);
            } catch { 
            }
        }
    }       
    }, []);
    const updatePopupData = (updatedCardData) => {
        let cardsDataObj = cardData?.resultData ? { ...cardData?.resultData } : { ...cardData };
        if (cardsDataObj != undefined && Object.entries(cardsDataObj).length !== 0) {
            let contentInfoObj = cardsDataObj?.contentInfo?.[0];
            let contentCustomDataObj = cardsDataObj?.contentCustomData;
            contentInfoObj = { ...contentInfoObj, content: updatedCardData?.contentInfo?.[0]?.content };
            contentCustomDataObj = { ...contentCustomDataObj, tileCustomData: updatedCardData?.contentCustomData?.tileCustomData };
            cardsDataObj = { ...cardsDataObj, contentCustomData: contentCustomDataObj, status: updatedCardData?.status };
            cardsDataObj["contentInfo"][0] = contentInfoObj;
            cardData?.resultData ? setCardData({ ...cardData, resultData: cardsDataObj }) : setCardData(cardsDataObj);
        }
    };
    const updateMultiContainerData = (cardData, id, sections, isVoted) => {
        props?.updateCardInfo(cardData, id, sections, isVoted);
    };
    const handleReload = async () => {
        setIsModalOpen(false);
        props?.handleReload();
    };
    const setDismissModal = () => {
        isModalOpen == true && setIsModalOpen(false);
    };
    const dismissModal = () => {
        setIsModalOpen(false);
        if (!props?.isBulletinBoard) {
            props?.handleReload();
            window.scrollBy(0, 0);
        }
        else {
            props?.fetchData(true, 1);
        }
    };
    useEffect(() => {
        if (cardData && Object.keys(cardData)?.length > 0 && isLoading == true) {
            setIsModalOpen(true);
            setIsLoading(false);
        }
    }, [cardData]);

    return (
        <>
            {
                <>
                    <DialogBox
                        closeOnOutsideClick={false}
                        content={
                            <>
                                <div className="pollPopupHeader">
                                    <div className='popupLeftHeader'>
                                        <Image
                                            alt=""
                                            src={require("../../../assets/EY_Logo.png")}
                                            fit="cover"
                                            id={props?.location?.isFromAF && !props?.isBulletinBoard ? "popupLeftHeaderImgVL" : "popupLeftHeaderImg"}
                                        />
                                        <div>
                                            <p className='popupHeaderTitle'>{Constant.APPLICATION_NAME}</p>
                                            <p>{props?.name || props?.location?.contentTypeName}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <span tabIndex={0} className="closeIconPopup" title="Close">
                                            <MdClose onClick={dismissModal} onKeyDown={(e) => { if (e.key === "Enter") { dismissModal(); } }} />
                                        </span>
                                    </div>

                                </div>

                                <div className={`pollPopupBody`}>
                                    {props?.isBulletinBoard == true && cardData && Object.keys(cardData).length > 0 ?
                                        <BulletinCard
                                            key={cardData?.metaDataId}
                                            item={cardData}
                                            contentTypeId={props?.contentType && parseInt(props?.contentType)}
                                            //config={cardData?.config?.subContentTypes?.[0]?.featureFlagsDetails}
                                            config={
                                                // cardData?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL ?
                                                // cardData?.config?.subContentTypes?.[0]?.contentCategories?.[0]?.featureFlagsDetails :
                                                cardData?.config?.subContentTypes?.[0]?.contentCategories?.[1]?.featureFlagsDetails
                                            }
                                            onClick={() => { props?.handleOnCardClick(cardData, props.contentType, cardData?.config?.contentCategories?.[1]?.featureFlagsDetails?.detailsPage); }}
                                            showDivider={props?.showDivider}
                                            handleUnpin={() => { props?.handleUnpin(cardData?.metaDataId, props.contentType); }}
                                            updateEachSection={props?.updateEachSection}
                                            sectionName={props?.sectionName}
                                            hasMoreData={props?.hasMoreData}
                                            isBulletinBoard={props?.isBulletinBoard}
                                            isFromDeeplink={true}
                                            updatePopupData={updatePopupData}
                                            updateMultiContainerData={updateMultiContainerData}
                                            dismissModal={dismissModal}
                                            showMoreButton={true}
                                        />
                                        :
                                        cardData && Object.keys(cardData).length > 0 &&
                                        <CardExampleSize
                                            key={cardData?.resultData?.metaDataId}
                                            id={cardData?.resultData?.metaDataId}
                                            item={cardData?.resultData}
                                            config={cardData?.resultData?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL ? 
                                            cardData?.contentType?.[0]?.subContentTypes?.filter((item) => {return item?.subContentTypeName?.toLowerCase() == "default"})?.[0]?.contentCategories?.[0]?.featureFlagsDetails
                                            : 
                                            cardData?.contentType?.[0]?.subContentTypes?.filter((item) => {return item?.subContentTypeName?.toLowerCase() == "default"})?.[0]?.contentCategories?.[1]?.featureFlagsDetails}
                                            //config={cardData?.contentType?.[0]?.subContentTypes?.filter((item) => {return item?.subContentTypeName?.toLowerCase() == "default"})?.[0]?.featureFlagsDetails}
                                            isImage={true}
                                            contentType={props?.contentType && parseInt(props?.contentType)}
                                            pathname={props?.path}
                                            fixHeight={true}
                                            contentTypeName={props?.name}
                                            sectionName={["new", "topprevious"]}
                                            isFromDeeplink={true}
                                            updatePopupData={updatePopupData}
                                            updateMultiContainerData={updateMultiContainerData}
                                            isPolls={props.contentType == 7 ? true : false}
                                            handleReload={handleReload}
                                            onClick={props?.handleOnCardClick}
                                            section={cardData?.contentType?.[0]?.subContentTypes?.filter((item) => { return item?.subContentTypeName == "default"; })?.[0]?.subContentTypeName}
                                            contentSource={cardData?.resultData?.contentSource}
                                            updateCardData={props?.updateCardInfo}
                                            showMoreButton={true}
                                            updatePinInfoByMetaDataId={props?.updatePinInfoByMetaDataId}
                                            setDismissModal={setDismissModal}
                                        />
                                    }
                                </div></>
                        }
                        backdrop={true}
                        openDialog={isModalOpen}
                        className={props?.isBulletinBoard == true ? "BBDeepLinkPopupStyles" : "deepLinkPopupStyles"}
                    />
                </>
            }
        </>
    );
}

export default DeepLinkPopup;