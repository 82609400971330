import React, { useState, useEffect, useRef } from "react";
import "./membersDropdown.css";
import { Loader } from "@fluentui/react-northstar";
import { ImageUtil } from "../../utility/utility";
import { AiOutlineClose } from "react-icons/ai";
import Constant from "../../utility/constants";

const MembersDropdown = (props) => {
  const { allOptions } = props;
  const [filterText, setFilterText] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(false);
  const [loadedOptions, setLoadedOptions] = useState(5);
  const [searchText, setSearchText] = useState("");  

  useEffect(() => {
    const handleScroll = () => {
      const dropdownOptions = document?.getElementById("mdropdown-options");
      if (
        dropdownOptions.scrollHeight - dropdownOptions.scrollTop <=
        dropdownOptions.clientHeight + 10
      ) {
        if (allOptions.length > loadedOptions) {
          setLoading(true);
          setTimeout(() => {
            setLoadedOptions((prevLoadedOptions) => prevLoadedOptions + 5);
            setLoading(false);
          }, 1000);
        }
      }
    };

    document
      ?.getElementById("mdropdown-options")
      ?.addEventListener("scroll", handleScroll);
    return () =>
      document
        ?.getElementById("mdropdown-options")
        ?.removeEventListener("scroll", handleScroll);
  }, [loadedOptions, options]);

  useEffect(() => {
    setOptions(
      allOptions
        ?.filter((option) =>
          filterText.length > 0
            ? option?.name?.toLowerCase().includes(filterText?.toLowerCase())
            : true
        )
        ?.slice(0, loadedOptions)
    );
  }, [loadedOptions, filterText, allOptions]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value.length > 2 || e.target.value.length == 0) {
      if (filterText.length < 2) {
        setLoadingText(true);
        setLoading(true);
        setTimeout(() => {
          setFilterText(e.target.value);
          setLoadingFalse();
        }, 1000);
      } else {
        setFilterText(e.target.value);
      }
    } else if (e.target.value.length == 1 || e.target.value.length == 2) {
      setFilterText("");
    }
  };

  const clearSearch = (e = null) => {
    e?.stopPropagation();
    setLoadingFalse();
    setSearchText("");
    setFilterText("");
  };

  const setLoadingFalse = () => {
    setLoadingText(false);
    setLoading(false);
  };
  

  return (
    <div className="member-dropdown">      
        <div style={{ position: "absolute", width: "100%" }}>
          <div className="msearch-container" style={{}}>
            <input
              type="text"
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange}
              spellCheck={false}
              
            />
            {searchText && (
              <AiOutlineClose
                className="mclear-search-icon"
                onClick={(e) => clearSearch(e)}
              />
            )}
          </div>
          {searchText.length < 3 && searchText.length > 0 && (
            <div className="mHintMsg">{Constant.HINT_MESSAGE}</div>
          )}
          {searchText.length > 2 && options.length < 1 && (
            <div className="mHintMsg">{Constant.NO_RESULT_MESSAGE}</div>
          )}
          {options?.length > 0 && (
            <div
              className="mdropdown-options"
              id="mdropdown-options"
              style={{}}
            >
              {!loadingText && (
                <>
                  {" "}
                  {options?.map((option) => (
                    <span className="membername_and_avatar">
                      <img
                        className="users-avatar-mdropdown"
                        src={ImageUtil(option.name)}
                        alt=""
                      />
                      &nbsp;
                      <div key={option.name} className={`mdropdown-option`}>
                        {option.name}
                      </div>
                    </span>
                  ))}
                </>
              )}
              {loading && (
                <div className={`mdropdown-loader`}>
                  <Loader />
                </div>
              )}
            </div>
          )}
        </div>
      </div>    
  );
};

export default MembersDropdown;
