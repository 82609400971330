import "./pbiReport.css";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useEffect, useContext, useState } from "react";
import {
  models,
  Report,
  Embed,
  service,
  Page,
  IReportEmbedConfiguration,
} from "powerbi-client";
import { useHamburgerMenuContext } from "../../../../../context/menucontext/menuContext";

const PowerBiReport = (props) => {
  const { toggleHamburger } = useHamburgerMenuContext();
  const [report, setReport] = useState({});

  // const [heightClass , setAddHeightClass] = useState("");
  const [isReportLoaded, setReportLoaded] = useState(false);

  const [repConfig, setConfig] = useState({
    type: "report",
    embedUrl: undefined,
    id: undefined,
    hostname: "https://app.powerbi.com",
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: {
      layoutType:
        window.innerWidth > 768
          ? models.LayoutType.Custom
          : models.LayoutType.MobilePortrait,
      customLayout: {
        displayOption: models.DisplayOption.FitToWidth,
      },
      navContentPaneEnabled: false,
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
    },
  });

  useEffect(() => {
    if (props.item) {
      setConfig({
        ...repConfig,
        embedUrl: props.item.embedUrl,
        accessToken: props.item.accessToken,
        id: props.item.id,
      });
    }
  }, [props.item]);

  useEffect(() => {
    if (repConfig.embedUrl) {
      const handleOrientationChange = async (orientation) => {
        console.log(orientation);
        try {
          if (orientation.currentTarget.type.indexOf("portrait") > -1) {
            const newSettings = {
              layoutType: models.LayoutType.MobilePortrait,
            };
            const response = await window.report?.updateSettings(newSettings);
            return response;
          } else {
            const settings = {
              panes: {
                filters: {
                  expanded: true,
                  visible: true,
                },
              },
            };
            const response = await window.report?.updateSettings(settings);
            // setKeyStrng(_guid());
            // setConfig(newSettings);
            return response;
          }
        } catch (err) {
          //alert(err);
        }
      };

      window.screen.orientation.addEventListener(
        "change",
        handleOrientationChange
      );

      return () => {
        window.screen.orientation.removeEventListener(
          "change",
          handleOrientationChange
        );
      };
    }
  }, [repConfig.embedUrl]);

  // useEffect(() => {
  //   if (repConfig.embedUrl) {
  //     const handleOrientationChange = async (orientation) => {
  //       console.log(orientation);
  //       try {
  //         if (orientation.currentTarget.type.indexOf("portrait") > -1) {
  //           const newSettings = {
  //             layoutType: models.LayoutType.MobilePortrait,
  //           };
  //           const response = await window.report?.updateSettings(newSettings);
  //           return response;
  //         } else {
  //           let embedConfig = {
  //             ...repConfig,
  //             settings: {
  //               layoutType: models.LayoutType.Custom,
  //               customLayout: {
  //                 pageSize: {
  //                   type: models.PageSizeType.Custom,
  //                   width: 1600,
  //                   height: 1200,
  //                 },
  //                 displayOption: models.DisplayOption.ActualSize,
  //                 pagesLayout: {
  //                   ReportSection1: {
  //                     defaultLayout: {
  //                       displayState: {
  //                         mode: models.VisualContainerDisplayMode.Hidden,
  //                       },
  //                     },
  //                     visualsLayout: {
  //                       VisualContainer1: {
  //                         x: 1,
  //                         y: 1,
  //                         z: 1,
  //                         width: 400,
  //                         height: 300,
  //                         displayState: {
  //                           mode: models.VisualContainerDisplayMode.Visible,
  //                         },
  //                       },
  //                       VisualContainer2: {
  //                         displayState: {
  //                           mode: models.VisualContainerDisplayMode.Visible,
  //                         },
  //                       },
  //                     },
  //                   },
  //                 },
  //               },
  //             },
  //           };
  //           const response = await window.report?.updateSettings(settings);
  //           // setKeyStrng(_guid());
  //           // setConfig(newSettings);
  //           return response;
  //         }
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     };

  //     window.screen.orientation.addEventListener(
  //       "change",
  //       handleOrientationChange
  //     );

  //     return () => {
  //       window.screen.orientation.removeEventListener(
  //         "change",
  //         handleOrientationChange
  //       );
  //     };
  //   }
  // }, [repConfig.embedUrl]);

  return (
    <div className="App-Report">
      <header className="App-header">
        <PowerBIEmbed
          embedConfig={repConfig}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                  props.closeLoader();
                  setReportLoaded(true);
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  //alert(JSON.stringify(event?.detail, null, 2))
                },
              ],
            ])
          }
          cssClassName={`Embed-container ${
            toggleHamburger == false &&
            isReportLoaded == true &&
            "hamburgerclosed"
          }`}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      </header>
    </div>
  );
};

export default PowerBiReport;
