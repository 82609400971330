/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dropdown, Datepicker, Checkbox } from "@fluentui/react-northstar";
import PopupBox from "../../commonui/popup/popup";
import { CiClock2 } from "react-icons/ci";
import { AiOutlineArrowRight, AiOutlineWarning } from "react-icons/ai";
import timepickerdata from "../../data/calenderinvite/timePicker.json";
import TextArea from "../textarea/textArea";
import { MdCalendarToday } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { TfiMenuAlt } from "react-icons/tfi";
import { UseformDataContext } from "../../context/genericdatacontext/formdatacontext";
import { postData, getData } from "../../services/customApis";
import APIEndPoints from "../../utility/apiendpoints";
import Constant from "../../utility/constants";
import { formatDecodedURLs } from "../../utility/utility";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import PeoplePicker from "../../commonui/commonformcomponents/peoplepicker/peoplePicker";
import { getContentTypeNamesById } from "../../utility/utility";
import TelemetryEventNames from "../../data/apptelemetry/appTelemetry.const";
import { TrackEvent } from "../../services/appInsights";
import { DatePicker } from "@fluentui/react";
import { calendar, app } from "@microsoft/teams-js";
import CustomizedToolTip from "../../commonui/tooltip/toolTip";
import moment from "moment";
import "./calenderPopup.css";

function CalenderPopup(props) {
  const { formInput, handleFormDataIuput } = UseformDataContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const { userInfo } = UseUserInfoContext();
  const [error, SetError] = useState(false);
  const [errorMsg, SetErrorMsg] = useState("");
  const [showDeepLink, setShowDeepLink] = useState(true);
  const [state, setState] = useState({
    isOpen: false,
    timeFrom: "12:00 PM",
    timeTo: "12:30 PM",
    timeDifference: "30m",
    dropdownOptionsFrom: timepickerdata,
    dropdownOptionsTo: timepickerdata,
    meetingStartDate: new Date(), // formatCurrentDate(),
    meetingEndDate: new Date(), //formatCurrentDate(),
    isTeamsMeeting: false,
    sendButtonDisabled: true,
    toOpen: false,
    fromOpen: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchQueryFrom, setSearchQueryFrom] = useState("12:00 PM");
  const [searchQueryTo, setSearchQueryTo] = useState("12:30 PM");
  const [isValidDate, setIsValidDate] = useState(true);
  const [loggedInM365Disable, setloggedInM365Disable] = useState(false);

  useEffect(() => {
    handleFormDataIuput({
      isOnlineMeeting: state.isTeamsMeeting,
    });
    //app.initialize();
    return () => {
      setState({});
    };
  }, []);

  useEffect(() => {
    app.getContext().then((context) => {
      if (
        context?.app?.host?.name === "Outlook" ||
        context?.app?.host?.name === "Office"
      ) {
        setloggedInM365Disable(true);
      }
    });
  }, []);

  useEffect(() => {
    if (formInput.linkViewClosed === true) {
      setShowDeepLink(false);
    } else {
      setShowDeepLink(true);
    }
  }, [formInput.linkViewClosed]);

  const stateReset = () => {
    handleFormDataIuput({});
    let newState = {
      isOpen: false,
      timeFrom: "12:00 PM",
      timeTo: "12:30 PM",
      timeDifference: "30m",
      dropdownOptionsFrom: timepickerdata,
      dropdownOptionsTo: timepickerdata,
      meetingStartDate: new Date(), // formatCurrentDate(),
      meetingEndDate: new Date(), //formatCurrentDate(),
      isTeamsMeeting: false,
      sendButtonDisabled: true,
      toOpen: false,
      fromOpen: false,
    };
    setState((state) => ({
      ...state,
      ...newState,
    }));
    setShowDeepLink(true);
    setSearchQueryFrom("12:00 PM");
    setSearchQueryTo("12:30 PM");
    SetError(false);
    SetErrorMsg("");
  };

  const calculateEndDate = (time1, time2) => {
    let dt = null;
    let fromDate = new Date(state.meetingStartDate);
    let toDate = new Date(state.meetingStartDate);
    let ary1 = time1.split(":");
    let ary2 = time2.split(":");
    if (ary1[1] !== undefined) {
      ary1.push(ary1[1].split(" ")[1]);
      ary1[1] = ary1[1].split(" ")[0];
    }
    if (ary2[1] !== undefined) {
      ary2.push(ary2[1].split(" ")[1]);
      ary2[1] = ary2[1].split(" ")[0];
    }

    if (ary1[2] !== undefined) {
      if (ary1[2] === "AM" && ary1[0] === "12") {
        ary1[0] = "00";
      } else if (ary1[2] === "PM" && ary1[0] === "12") {
      } else if (ary1[2] === "PM" && ary1[0] !== "12") {
        ary1[0] = parseInt(ary1[0]) + parseInt(12);
      }
    }
    if (ary2[2] !== undefined) {
      if (ary2[2] === "AM" && ary2[0] === "12") {
        ary2[0] = "00";
      } else if (ary2[2] === "PM" && ary2[0] === "12") {
      } else if (ary2[2] === "PM" && ary2[0] !== "12") {
        ary2[0] = parseInt(ary2[0]) + parseInt(12);
      }
    }
    fromDate.setHours(ary1[0]);
    toDate.setHours(ary2[0]);
    fromDate.setMinutes(ary1[1]);
    toDate.setMinutes(ary2[1]);
    let toTimeStamp = toDate.valueOf();
    let fromTimeStamp = fromDate.valueOf();
    if (checkIfToTimeIsLessThanFrom(time1, time2)) {
      toTimeStamp += 24 * 60 * 60 * 1000;
      dt = new Date(toTimeStamp).toLocaleDateString("en-US");
    } else {
      dt = state.meetingStartDate;
      toDate = new Date(state.meetingStartDate);
      toDate.setHours(ary2[0]);
      toDate.setMinutes(ary2[1]);
      toTimeStamp = toDate.valueOf();
    }
    return [dt, fromTimeStamp, toTimeStamp, fromDate, toDate];
  };

  const checkIfToTimeIsLessThanFrom = (time1, time2) => {
    var items1 = time1.split(" ")[0].split(":");
    var hours1 = Number(items1[0]);
    var minutes1 = Number(items1[1]);
    var AMPM1 = time1.split(" ")[1];
    if (AMPM1 == "PM" && hours1 < 12) hours1 = hours1 + 12;
    if (AMPM1 == "AM" && hours1 == 12) hours1 = hours1 - 12;

    var time1ToMins = hours1 * 60 + minutes1;

    var items2 = time2.split(" ")[0].split(":");
    var hours2 = Number(items2[0]);
    var minutes2 = Number(items2[1]);
    var AMPM2 = time2.split(" ")[1];
    if (AMPM2 == "PM" && hours2 < 12) hours2 = hours2 + 12;
    if (AMPM2 == "AM" && hours2 == 12) hours2 = hours2 - 12;

    var time2ToMins = hours2 * 60 + minutes2;

    return time2ToMins < time1ToMins;
  };

  const createMeetingInvite = async () => {
    setIsLoading(true);
    let dt = calculateEndDate(state.timeFrom, state.timeTo)[0];
    let startDate = calculateEndDate(state.timeFrom, state.timeTo)[3];
    let endDate = calculateEndDate(state.timeFrom, state.timeTo)[4];
    let formattedStartDate =
      ("0" + startDate.getHours()).slice(-2) +
      ":" +
      ("0" + startDate.getMinutes()).slice(-2);
    let formattedEndDate =
      ("0" + endDate.getHours()).slice(-2) +
      ":" +
      ("0" + endDate.getMinutes()).slice(-2);
    let formInputObj = formInput;
    //if (!formInputObj.hasOwnProperty('start')) {
    formInputObj = {
      ...formInputObj,
      start: {
        dateTime: formatDateTime(state.meetingStartDate, formattedStartDate),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      end: {
        dateTime: formatDateTime(state.meetingEndDate, formattedEndDate),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
    let newFormInput = updateformInput(formInputObj);
    console.log("new ", newFormInput);
    await postData(
      newFormInput,
      APIEndPoints.CALENDAR_INVITE(
        props.contentType,
        props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId
      )
    )
      .then((item) => {
        setIsLoading(false);
        stateReset();
      })
      .then(() => {
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: Constant.CALENDAR_SUCCESS_MESSAGE,
          type: "Success",
          isFailed: false,
        });
        TrackEvent(
          //TESTED
          TelemetryEventNames.UCC_CONTENT_CALENDAR_INVITE_EVENT,
          userInfo,
          {
            SectionName:
              props?.contentType == 4
                ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
                : TelemetryEventNames.SECTION_TYPE_CONTENT,
            ContentTypeId: props.contentType.toString() ?? "",
            ContentTypeName: getContentTypeNamesById(props.contentType) ?? "",
            MetadataId: props.item.metaDataId.toString(),
            UniqueId: props.item.contentUniqueId ?? "",
            Title: props.item.title ?? "",
            PublishDate: props.item.publishedDate ?? "",
          }
        );
      })
      .catch(async (err) => {
        //console.log(err);
        setIsLoading(false);
        //stateReset();
        SetError(true);
        SetErrorMsg(err?.response?.data?.Errors);
      });
  };

  const openMeetingInTeams = async () => {
    if (teamsButtonValidation()) {
      //formatting date time
      let startDate = calculateEndDate(state.timeFrom, state.timeTo)[3];
      let endDate = calculateEndDate(state.timeFrom, state.timeTo)[4];
      let formattedStartTime =
        ("0" + startDate.getHours()).slice(-2) +
        ":" +
        ("0" + startDate.getMinutes()).slice(-2) +
        ":" +
        "00";
      let formattedEndTime =
        ("0" + endDate.getHours()).slice(-2) +
        ":" +
        ("0" + endDate.getMinutes()).slice(-2) +
        ":" +
        "00";

      let _startdatetime = formatDateTimeOpeninTeams(
        state.meetingStartDate,
        formattedStartTime
      );
      let _enddatetime = formatDateTimeOpeninTeams(
        state.meetingEndDate,
        formattedEndTime
      );

      let _startdatetime_webversion = formatDateTime_WebVersion(
        state.meetingStartDate,
        formattedStartTime
      );
      let _enddatetime_webversion = formatDateTime_WebVersion(
        state.meetingEndDate,
        formattedEndTime
      );

      let _subject =
        props.contentType === 7
          ? atob(
              unescape(decodeURIComponent(formInput.catalystTitle))
            ).substring(0, 250)
          : formInput.catalystTitle.substring(0, 250);

      let _content =
        formInput.catalystUserText !== undefined
          ? // ? formInput.catalystUserText.replace(/<[^>]*>?/gm, "")
            formInput.catalystUserText
          : "";

      let _attendees =
        formInput.recipient !== undefined
          ? formInput.recipient.userEmailList.toString()
          : [];

      if (
        formInput.recipient !== undefined &&
        formInput.recipient.userGroupList.length > 0
      ) {
        let userGroupDetails = formInput.recipient.userGroupList;
        let userGroupEmailIds = await Promise.all(
          userGroupDetails.map(async (item) => {
            try {
              let res = await getData(
                APIEndPoints.GET_CUSTOM_USER_GROUP_BY_ID(
                  userInfo.teams.companyId,
                  item.Id
                )
              );
              return (
                res?.data?.userDetails?.map((item, i) => item.emailId) || ""
              );
            } catch (err) {
              return "";
            }
          })
        );
        _attendees = _attendees.concat(",", userGroupEmailIds.flat().join(","));
      }

      let weblink = "";
      userInfo.loggedInWeb === true || loggedInM365Disable
        ? (weblink = atob(decodeURIComponent(formInput.catalystContentWebLink)))
        : (weblink =
            atob(decodeURIComponent(formInput.catalystContentDeepLink)).split(
              "?"
            )[0] +
            "%3F" +
            atob(decodeURIComponent(formInput.catalystContentDeepLink)).split(
              "?"
            )[1]);

      let text =
        "Hey there... check out this content on Catalyst! Click the link below or copy/paste it into your web-browser. ";

      _content +=
        "<br /><br />" + text + "<br /><br />" + encodeURIComponent(weblink);

      if (userInfo.loggedInWeb === true || loggedInM365Disable) {
        let newWindow = window.open(
          "https://outlook.office.com/calendar/deeplink/compose?&teams=true&subject=" +
            _subject +
            "&startdt=" +
            _startdatetime_webversion +
            "&enddt=" +
            _enddatetime_webversion +
            "&body=" +
            _content +
            "&to=" +
            _attendees,
          "_blank"
        );
        setTimeout(() => {
          stateReset();
        }, 1500);

        if (newWindow === null) {
          // Handle the error
          alert(
            "Failed to open a new window. Please disable your popup blocker and try again."
          );
        }
      } else {
        await app
          .initialize()
          .then(async () => {
            let isSupported = calendar.isSupported();
            if (isSupported) {
              calendar
                .composeMeeting({
                  attendees: [formInput.recipient.userEmailList],
                  content: _content,
                  subject: _subject,
                  endTime: _enddatetime,
                  startTime: _startdatetime,
                })
                .then((item) => {
                  setIsLoading(false);
                  stateReset();
                  console.log(
                    "Teams meeting is properly being setup via composeMeeting"
                  );
                })
                .catch((err) => {
                  console.log(err);
                  setIsLoading(false);
                });
            } else {
              app
                .openLink(
                  "https://teams.microsoft.com/l/meeting/new?&teams=true&subject=" +
                    _subject +
                    "&attendees=" +
                    _attendees +
                    "&startTime=" +
                    _startdatetime +
                    "&endTime=" +
                    _enddatetime +
                    "&content=​" +
                    _content
                )
                .then((item) => {
                  setIsLoading(false);
                  stateReset();
                  console.log(
                    "Teams meeting is properly being setup via deeplink"
                  );
                })
                .catch((err) => {
                  console.log(err);
                  setIsLoading(false);
                });
            }
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    }
  };

  const updateformInput = (formInputObj) => {
    let attendees = [];
    let userGroupDetails = [];
    let allEmail = formInputObj.recipient.userEmailList;
    let allIdsForUserGroup = formInputObj.recipient.userGroupList;
    delete formInputObj.recipient;
    allEmail.map((item) => {
      let obj = {
        emailAddress: {
          address: item,
        },
        type: "required",
      };
      attendees.push(obj);
      return item;
    });
    allIdsForUserGroup.map((item) => {
      let ugobj = {
        userGroupId: parseInt(item.Id),
        userGroupName: item.Name,
      };
      userGroupDetails.push(ugobj);
      return item;
    });

    let newFormInput = { ...formInputObj, attendees, userGroupDetails };
    let sub = {
      subject:
        props.contentType === 7
          ? newFormInput.catalystTitle
          : btoa(unescape(encodeURIComponent(newFormInput.catalystTitle))),
    };
    let id = {
      metaDataId: props.item.metaDataId,
    };
    // let userIdentifier = {
    //   userIdentifier: userInfo.email,
    // };
    newFormInput = { ...newFormInput, ...sub };
    newFormInput = { ...newFormInput, ...id };
    restructureTheEmailPayload(newFormInput);
    // newFormInput = { ...newFormInput, ...userIdentifier };
    delete newFormInput.catalystDetails;
    delete newFormInput.catalystTitle;
    delete newFormInput.catalystImage;
    delete newFormInput.catalystTextwithUrl;
    delete newFormInput.sender;
    delete newFormInput.catalystTextWithDeepLink;
    delete newFormInput["catalystUserText"];
    newFormInput["body"]["content"] = btoa(
      unescape(encodeURIComponent(newFormInput["body"]["content"]))
    );
    //newFormInput["body"]['content'] = "This a test from integration"
    return newFormInput;
  };

  const customFormatter = (date) => {
    const day = date.getDate();
    const month = "" + (date.getMonth() + 1);
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const restructureTheEmailPayload = (obj) => {
    let parser = new DOMParser();
    let doc = parser.parseFromString(obj["body"]["content"], "text/html");
    if (formInput.isImageClosed) {
      doc.body.getElementsByClassName("ac-columnSet")[0].remove();
    }
    let removedTagsFromImg = doc.body.innerHTML
      .replace(/[\n\t]+/g, "")
      .replace(/\s+/g, " ")
      .replace('<o:p=""', "/");
    let removeunWantedTag = removedTagsFromImg
      .replace("<o:p>&nbsp;</o:p>", "")
      .replace("<o:p>", "")
      .replace("</o:p>", "")
      .replace("&nbsp;", "");

    // encode the title as well
    obj["body"]["content"] = removeunWantedTag;
  };

  const formatDateTime = (date, time) => {
    let dt = new Date(date);
    return (
      dt.getFullYear() +
      "-" +
      (dt.getMonth() + 1) +
      "-" +
      dt.getDate() +
      "T" +
      time
    );
  };

  const formatDateTimeOpeninTeams = (date, time) => {
    let dt = new Date(date);
    return (
      dt.getMonth() +
      1 +
      "/" +
      dt.getDate() +
      "/" +
      dt.getFullYear() +
      " " +
      time
    );
  };

  const formatDateTime_WebVersion = (date, time) => {
    let dt = new Date(date);
    let year = dt.getFullYear();
    let month = String(dt.getMonth() + 1).padStart(2, "0");
    let day = String(dt.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}T${time}`;
  };

  const calculateTimeDiff = (tim1, tim2) => {
    // let ary1 = tim1.split(":");
    // let ary2 = tim2.split(":");
    let [dt, fromTimeStamp, toTimeStamp /*, fromDate, toDate*/] =
      calculateEndDate(tim1, tim2);
    // let fromDate = new Date(state.meetingStartDate)
    // let toDate = new Date(state.meetingEndDate)
    // fromDate.setHours(ary1[0])
    // toDate.setHours(ary2[0])
    // fromDate.setMinutes(ary1[1])
    // toDate.setMinutes(ary2[1])
    // let toTimeStamp = toDate.valueOf()
    // let fromTimeStamp = fromDate.valueOf()
    // if (toTimeStamp < fromTimeStamp) {
    //   toTimeStamp += 24 * 60 * 60 * 1000
    //   dt = new Date(toTimeStamp).toLocaleDateString('en-US')
    // }
    // else {
    //   dt = state.meetingStartDate
    // }
    let diff = toTimeStamp - fromTimeStamp;
    let minutes = diff / (1000 * 60);
    let hours = parseInt(minutes / 60);
    minutes = parseInt(minutes % 60);
    return [String(hours) + ":" + String(minutes), dt];
  };

  const onChangeHandler = (value, field, event) => {
    switch (field) {
      case "from":
        if (value !== "" && value !== null && value !== state.timeFrom) {
          let optionsTo = state.dropdownOptionsFrom;
          let index = state.dropdownOptionsFrom.indexOf(value);
          if (index !== optionsTo.length - 1) {
            optionsTo = optionsTo.slice(index + 1);
          }
          let strTime = "";
          let [diff, endDate] = calculateTimeDiff(value, state.timeTo);

          let [hours, mins] = (diff ?? "").split(":");

          if (parseInt(hours) > 0) {
            strTime = parseInt(hours).toString() + "h";
          }
          if (parseInt(mins) > 0) {
            strTime += parseInt(mins).toString() + "m";
          }

          let positiveDiff =
            timepickerdata.indexOf(value) >=
            timepickerdata.indexOf(state.timeTo);
          if (positiveDiff) {
            setSearchQueryTo(optionsTo[0]);
            strTime = "30min";
          }
          setState({
            ...state,
            dropdownOptionsTo: optionsTo,
            timeFrom: value,
            timeTo: positiveDiff ? optionsTo[0] : state.timeTo,
            fromOpen: event === "change" ? false : true,
            timeDifference: strTime,
          });
          console.log(state);
        }
        handleFormDataIuput({
          start: {
            dateTime: formatDateTime(state.meetingStartDate, value),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });
        setSearchQueryFrom(value);
        formValidation();
        break;
      case "to":
        let endtime = value;
        let startTime = state.timeFrom;
        let strTime = "";
        let meetingEndDate = null;
        if (value !== "" && value !== null) {
          let [diff, endDate] = calculateTimeDiff(startTime, endtime);
          meetingEndDate = endDate;
          let [hours, mins] = (diff ?? "").split(":");
          if (parseInt(hours) > 0) {
            strTime = parseInt(hours).toString() + "h";
          }
          if (parseInt(mins) > 0) {
            strTime += parseInt(mins).toString() + "m";
          }
        }
        setState({
          ...state,
          timeTo: value,
          timeDifference: strTime,
          meetingEndDate: meetingEndDate,
          toOpen: event === "change" ? false : true,
        });
        handleFormDataIuput({
          end: {
            dateTime: formatDateTime(state.meetingEndDate, value),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });
        setSearchQueryTo(value);
        formValidation();
        break;
      case "isTeamsMeeting":
        setState({ ...state, isTeamsMeeting: value });
        handleFormDataIuput({
          isOnlineMeeting: value?.checked,
        });
        break;
      case "date":
        setState({
          ...state,
          meetingStartDate: value, //.target.ariaLabel,
          meetingEndDate: value, //.target.ariaLabel,
        });
        handleFormDataIuput({
          end: {
            dateTime: formatDateTime(state.meetingEndDate, state.timeTo),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          start: {
            dateTime: formatDateTime(state.meetingStartDate, state.timeFrom),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });
        break;
      default:
        break;
    }
  };
  const formValidation = () => {
    if (formInput.recipient !== undefined) {
      console.log(state.timeFrom);
      if (
        (formInput.recipient.userEmailList.length !== 0 ||
          formInput.recipient?.userGroupList?.length !== 0) &&
        state.timeDifference !== "" &&
        showDeepLink &&
        state.meetingStartDate != null &&
        state.meetingStartDate !== "Select a date." &&
        state.meetingEndDate !== "Select a date." &&
        state.meetingEndDate != null &&
        isValidDate &&
        state.timeFrom !== "" &&
        state.timeTo !== ""
      ) {
        //&& state.meetingStartDate !== ""  ValidateTime(state.timeFrom) && ValidateTime(state.timeTo) &&
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const teamsButtonValidation = () => {
    if (
      state?.meetingStartDate != null &&
      state?.meetingStartDate !== "Select a date." &&
      state?.meetingEndDate !== "Select a date." &&
      state?.meetingEndDate != null &&
      isValidDate &&
      state?.timeFrom !== "" &&
      state?.timeFrom !== undefined &&
      state?.timeTo !== "" &&
      state?.timeTo !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        sendButtonDisabled: formValidation(),
      };
    });
  }, [
    state.meetingStartDate,
    state.meetingEndDate,
    state.timeFrom,
    state.timeTo,
    formInput.recipient,
    showDeepLink,
    isValidDate,
  ]);

  return (
    <>
      <div
        tabIndex={0}
        className="action-button-div"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setState({ ...state, isOpen: !state.isOpen });
          }
        }}
      >
        <CustomizedToolTip
          tabIndex={0}
          normalTooltip={true}
          position="below"
          align="center"
          content={"Calendar"}
        >
          <MdCalendarToday
            className={`actionIcon ${
              !props.config.calendar.calendarEnabled && "actionIcon-disable"
            }`}
            onClick={() => setState({ ...state, isOpen: !state.isOpen })}
            data-testid="calenderIcon"
          />
        </CustomizedToolTip>
      </div>
      {props.config.calendar.calendarEnabled && state.isOpen && (
        <PopupBox
          title="Calendar invite"
          isFooter={true}
          isBorderNeeded={true}
          isModalOpen={state.isOpen}
          // sendButtonDisabled={formValidation()}
          sendButtonDisabled={state.sendButtonDisabled}
          hideModal={stateReset}
          onClick={createMeetingInvite}
          className="calendarPopupStyle"
          isOpenInTeams={true}
          onClickOpenInTeams={openMeetingInTeams}
        >
          <div
            className="calender_component_container"
            data-testid="calender_container"
          >
            {isLoading && <ReusableLoader isOverlay={true} isCalendar={true} />}
            <div>
              <span className="cc calendar_title">Title: </span>
              <span className="calender_component_headerBold">
                {props.contentType == 7
                  ? formatDecodedURLs(props?.item?.title)
                  : props.item.title}
              </span>
            </div>
            {/* <div className="calender_component_headerBold">{props.item.title}</div> */}
            <div
              className="calender_component_peoplepicker"
              data-testid="peoplepicker_container"
            >
              <div className="tocontainer">
                <span className="popuplabel">Share with:</span>
                {/* <PeoplePicker /> */}
                <div className="calendarPeoplePickerStyles">
                  <PeoplePicker
                    about="OK"
                    search={true}
                    multiple={true}
                    placeholder={
                      "Type the name of person, email id or user group"
                    }
                    checkable={true}
                    fluid={true}
                    freeFormEmail={true}
                    userType={0}
                    fromChatCalenderSendMail={true}
                    searchAd={true}
                    searchUserGroup={true}
                  />
                </div>
              </div>
            </div>
            <div
              className="calender_component_row"
              data-testid="datepicker_container"
            >
              <div className="calender_component_datepicker">
                <span className="calender_component_calenderIcon">
                  <SlCalender />
                </span>
                {/* <Datepicker
                  formatMonthDayYear={customFormatter}
                  inputPlaceholder="mm/dd/yy"
                  allowManualInput={true}
                  defaultSelectedDate={new Date()}
                  // input={{
                  //   clearable: true,
                  // }}
                  //className={`${!state.meetingDate && "dropdownIcon"} datePicker`}
                  
                  onDateChange={(e) => onChangeHandler(e, "date", null)}
                /> */}

                <DatePicker
                  // formatMonthDayYear={customFormatter}
                  placeholder="mm/dd/yy"
                  // defaultSelectedDate={new Date()}
                  className="calendarDatePicker"
                  value={state?.meetingStartDate}
                  allowTextInput
                  formatDate={customFormatter}
                  // onSelectDate={(value) => { handleDateChange(value, props?.fieldsName, props?.name); }}
                  onChange={(e) =>
                    setIsValidDate(moment(e.target.value).isValid())
                  }
                  onSelectDate={(e) => onChangeHandler(e, "date", null)}
                  title="Open calendar"
                />
              </div>
            </div>
            <div
              className="calender_component_row"
              data-testid="timepicker_container"
            >
              <div className="calender_component_timepicker">
                <div className="divClock">
                  {" "}
                  <CiClock2 />
                </div>
                <Dropdown
                  aria-labelledby="freeform-label"
                  allowFreeform
                  onSearchQueryChange={(_, data) => {
                    onChangeHandler(data.searchQuery, "from", "search");
                  }}
                  onChange={(_, event) => {
                    onChangeHandler(event.value, "from", "change");
                  }}
                  fluid
                  search={() => state.dropdownOptionsFrom}
                  items={state.dropdownOptionsFrom}
                  // placeholder=""
                  getA11ySelectionMessage={{
                    onAdd: (item) => `${item} has been selected.`,
                  }}
                  open={state.fromOpen}
                  onFocus={() => setState({ ...state, fromOpen: true })}
                  onBlur={() => setState({ ...state, fromOpen: false })}
                  searchQuery={searchQueryFrom}
                  data-testid="start_time_picker"
                  value={state.timeFrom}
                  defaultHighlightedIndex={state.dropdownOptionsFrom?.indexOf(
                    "12:00 PM"
                  )}
                  // DropdownItem={{onClick:}}
                />
                <div className="divtill">
                  <AiOutlineArrowRight />
                </div>
                <Dropdown
                  aria-labelledby="freeform-label"
                  allowFreeform
                  onSearchQueryChange={(_, data) => {
                    onChangeHandler(data.searchQuery, "to", "search");
                  }}
                  fluid
                  search={() => state.dropdownOptionsTo}
                  items={state.dropdownOptionsTo}
                  getA11ySelectionMessage={{
                    onAdd: (item) => `${item} has been selected.`,
                  }}
                  onChange={(_, event) =>
                    onChangeHandler(event.value, "to", "change")
                  }
                  open={state.toOpen}
                  onFocus={() => setState({ ...state, toOpen: true })}
                  onBlur={() => setState({ ...state, toOpen: false })}
                  searchQuery={searchQueryTo}
                  data-testid="end_time_picker"
                  value={state.timeTo}
                  defaultHighlightedIndex={state?.dropdownOptionsTo?.indexOf(
                    "12:30 PM"
                  )}
                />
                <p className="spnDiff">
                  {state.timeDifference !== "" && state.timeDifference}
                </p>
              </div>
              <div
                className="calender_component_toggleContainer"
                data-testid="teamsmeeting_container"
              >
                <p>Teams Meeting</p>
                {/* <Toggle
                  inlineLabel
                  onChange={(ev, checked) =>
                    onChangeHandler(checked, "isTeamsMeeting", null)
                  }
                /> */}
                <Checkbox
                  toggle
                  defaultChecked
                  onChange={(ev, checked) =>
                    onChangeHandler(checked, "isTeamsMeeting", null)
                  }
                />
              </div>
            </div>
            <div className="calender_component_card">
              <div className="menuIocn">
                <TfiMenuAlt />
              </div>
              <div className="linkCard">
                <TextArea {...props} showPreviewLink={showDeepLink} />
              </div>
            </div>
            {!showDeepLink && (
              <div className="chatValidationError">
                <p>
                  <AiOutlineWarning />
                  {Constant.CHAT_VALIDATION_MESSAGE}
                </p>
              </div>
            )}
            {error && (
              <div className="calenderServerError">
                <p>
                  <AiOutlineWarning />
                  {errorMsg}
                </p>
              </div>
            )}
          </div>
        </PopupBox>
      )}
    </>
  );
}

export default CalenderPopup;
