import { UseNotificationContext } from "../../context/notificationcontext/notificationContext";
import NotificationCount from "./notificationCount";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import "./notificationIcon.css";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import React from "react";
import { BiSolidMessageRoundedError } from "react-icons/bi";
import { checkIfDeviceIsMob } from "../../utility/utility";
import CustomizedToolTip from "../../commonui/tooltip/toolTip";

const NotificationIcon = () => {
  const { notifications, handleNotifications } = UseNotificationContext();
  //const [isPanelOpen, setIsPanelOpen] = useState(true)
  let { userInfo } = UseUserInfoContext();

  const dismissPanel = () => {
    handleNotifications({
      isPanelOpen: !notifications.isPanelOpen,
    });
  };

  initializeIcons();
  // const { handlePanelOpen } = UseNotificationContext();
  // console.log(notifications);

  return userInfo.teams.featureFlagsObj["Notification"]?.isEnabled ? (
    <div
      id="notificationOption"
      onClick={() => dismissPanel()}
      className={`notification-action-div
      ${checkIfDeviceIsMob() && "notification-action-flex"}
      `}
      data-testid="notification_icon"
    >
      {/* <img
        className="notification_icon"
        src={require("../../assets/notificationicon.svg").default}
        alt=""
      /> */}
      {!checkIfDeviceIsMob() ? (
        <CustomizedToolTip
          tabIndex={0}
          normalTooltip={true}
          position="below"
          align="center"
          content={"Notifications"}
        >
          <BiSolidMessageRoundedError size={24} className="notification_icon" />
        </CustomizedToolTip>
      ) : (
        <BiSolidMessageRoundedError size={20} />
      )}
      {notifications.count > 0 && (
        <NotificationCount count={notifications.count} />
      )}
      {checkIfDeviceIsMob() ? (
        <p className="notification-text">Notifications</p>
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  );
};

export default NotificationIcon;
