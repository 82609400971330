import React from "react";
import "./staticDialogMessage.css";
import { app } from "@microsoft/teams-js";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { useNavigate } from "react-router-dom";
import { Image } from "@fluentui/react-northstar";

export function DropPopupBox_1() {
  const navigate = useNavigate();
  return (
    <div className={"justifyContent"}>
      <p>
        Uploading a US XML e-File will enable you to run various Excel-based
        reports and will automatically generate various visualizations
        (“Catalyst Reports and Visualizations”). You may only upload a client
        XML e-File if you answer “Yes” to this question.
      </p>
      <br />

      <p>
        <strong>Acknowledgement 1:</strong>
      </p>
      <p>
        <strong>
          Did you obtain the XML e-file directly from the client for the purpose
          of running Catalyst Reports and Visualizations and not by any other
          means (e.g., from a tax compliance engagement)?
        </strong>
      </p>
    </div>
  );
}

export function DropPopupBox_2() {
  const { userInfo } = UseUserInfoContext();
  const url =
    "https://sites.ey.com/:w:/r/sites/americastaxquality/_layouts/15/Doc.aspx?sourcedoc=%7B6FFB4118-D1CF-4EE3-9C01-A305AFD8C1EA%7D&file=Professional%20Fees%20Policy%20and%20Supplemental%20Guidance%20for%20US%20Tax.docx&action=default&mobileredirect=true&DefaultItemOpen=1";
  return (
    <div className={"justifyContent"}>
      <p>
        <strong>Acknowledgement 2:</strong>
      </p>
      <p>
        Catalyst Reports and Visualizations are{" "}
        <u>
          <strong>not</strong> intended to provide a service that can be relied
          on or used for any way. The content of the Catalyst Reports and
          Visualizations do not contain any tax technical or other professional
          advice and should not be seen as such. Catalyst Reports and
          Visualizations may only be used to facilitate internal engagement team
          conversations and/or client conversations.
        </u>
      </p>
      <p>
        The use of the Catalyst Reports and Visualizations is subject to the
        Pre-engagement scoping{" "}
        <a
          className={"hyperlink"}
          onClick={() =>
            userInfo.loggedInWeb === true
              ? window.open(url, "_blank")
              : app.openLink(url)
          }
        >
          {" "}
          Professional Fees Policy and Supplemental Guidance for US Tax.docx
          (ey.com)
        </a>{" "}
        .
      </p>
      <p>
        If a client does not want to engage our firm for a feasibility phase,
        then pre-engagement scoping may be appropriate. Pre-engagement scoping
        occurs prior to the engagement and prior to the provision of services.
        It consists of the limited exploration of the client’s facts and
        circumstances to determine whether a particular service may be
        applicable, and potentially beneficial, to the client and assists in the
        budgeting process for the purpose of submitting a proposal to the client
        for performance of the service. Pre-engagement scoping is encouraged and
        permissible with the following limitations:
      </p>

      <ul>
        <li>
          Service or advice must not be delivered to a potential client during
          pre-engagement scoping. If the pre-engagement scoping is for a US SEC
          audit client, the GCSP should be consulted to confirm that
          professional services will not be provided and, as such, audit
          committee preapproval is not required.
        </li>
        <br />
        <li>
          Deliverables, including PowerPoint presentations, should be limited in
          pre-engagement scoping and should not reflect what would normally be
          considered the results of our services or advice. Any communication to
          a taxpayer during pre-engagement scoping should not be sufficiently
          detailed and complete that the client could interpret the
          communication as advice upon which it could rely. It should be noted
          that the more detail provided to a potential client and the fewer
          remaining issues to be resolved, the more likely it is that the
          taxpayer may consider that service or advice is being delivered.
        </li>
        <br />
        <li>
          Communications to a potential client during pre-engagement scoping,
          whether written or oral, must include a caveat that any information
          provided does not constitute tax advice and cannot be relied upon by
          the taxpayer. The following caveat can be used: “The information
          contained herein is general in nature and is not intended, and should
          not be construed, as legal, accounting or tax advice, or as an opinion
          provided by Ernst & Young LLP. Client also is cautioned that this
          material may not be applicable to, or suitable for, Client’s specific
          circumstances or needs, and may require consideration of nontax and
          other tax factors if any action is to be contemplated. Consequently,
          Client should not rely upon the information provided herein for any
          purpose.”
        </li>
        <br />
        <li>
          Pre-engagement scoping is generally not subject to the
          accountant-client privilege under Internal Revenue Code (IRC) section
          7525 and, hence, pre-engagement scoping documents should not include
          any section 7525 privilege label.
        </li>
        <br />
        <li>
          The expected tax benefit of the service for the taxpayer should only
          be provided in the form of a range or sample calculation. Generally,
          ranges of benefits should avoid unreasonable expectations and should
          not imply too much precision. Any quantification of a range or sample
          calculation of potential benefit should contain the following caveat:
          “This range (sample calculation) is preliminary and based on the
          experience of Ernst & Young LLP in the area but is not a reasonably
          accurate projection of your benefits. The actual benefit Client may
          receive could differ substantially from this range (sample).
          Consequently, this range (sample) cannot be relied upon by Client and
          cannot be used for tax return purposes. Additional analysis must be
          conducted in order to determine what tax benefit (if any) Client may
          receive from this service.”
        </li>
        <br />
        <li>
          Pre-engagement scoping is conducted for no fee. Time and expenses
          associated with pre-engagement scoping cannot be recouped upon
          engagement by the client (as compared to a feasibility phase in a
          phased engagement where we have been engaged by the client). Because
          of the restriction against billing for pre-engagement scoping,
          managing the amount of time dedicated to pre-engagement scoping is a
          business decision and the alternative of phased engagement feasibility
          (discussed above in section 5.4) should be considered.
        </li>
        <br />
        <li>
          The amount of time spent during pre-engagement scoping assessing the
          client’s facts and circumstances as related to a particular service
          offering should be limited. If it is envisioned that time spent on
          pre-engagement scoping for one particular type of service could exceed
          10% of the projected total engagement time to deliver this service,
          then consultation with Americas Tax Quality is required, and in the
          case of a client subject to US Independence fee guidance, consultation
          with the GCSP is also required prior to beginning the scope assessment
          to determine that the arrangement is otherwise consistent with the
          overarching principles in the US Independence fee guidance.
        </li>
        <br />
        <li>
          The fee structure for the ensuing engagement cannot directly correlate
          to the range of benefits projected in pre-engagement scoping if the
          engagement is not being performed under a findings-based fee
          structure. For instance, it is impermissible to set fees lower if the
          low end of the range of benefits is realized and higher if the high
          end of the range is realized.
        </li>
      </ul>
    </div>
  );
}

export function Web_Version_Message() {
  const { userInfo } = UseUserInfoContext();
  const prodURL =
    "https://teams.microsoft.com/l/app/d9e7a294-acc4-490c-bb39-c0ac254e4f76?source=app-details-dialog";
  const uatURL =
    "https://teams.microsoft.com/l/app/269bf58d-f04d-4f6e-95fa-533ff17e925b?source=app-details-dialog";
  const qaURL =
    "https://teams.microsoft.com/l/app/672f6905-c1ad-4321-9a7b-a7b282174436?source=app-details-dialog";

  return (
    <div className={"justifyContent"}>
      <br />
      <p>
        This feature is not supported in the
        <strong> EY Catalyst Connect</strong>{" "}
        {userInfo.loggedInWeb === true ? "web version" : "M365/Outlook"}. To
        access this feature, please follow the link to install the{" "}
        <a
          href={
            window.location.origin.indexOf("https://catalyst.ey.com") !== -1
              ? prodURL
              : window.location.origin.indexOf(
                  "https://catalyst-uat.ey.com"
                ) !== -1
              ? uatURL
              : qaURL
          }
          className={"hyperlink"}
          target="_blank"
        >
          <strong>Catalyst Teams App</strong>
        </a>{" "}
      </p>
      <br />
      <p>
        If you are unable to install the app,{" "}
        <a href="/contactUs" className={"hyperlink"}>
          <strong>Contact Us</strong>
        </a>{" "}
        to initiate the request to get the app installed or reach out to your EY
        Engagement Partner.
      </p>
    </div>
  );
}
