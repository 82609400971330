import React, { useState, createContext, useContext } from "react";

export const notificationsData = createContext({
  notificationMsgsData: {
    showMsgBar: false,
    started: false,
    completed: false,
    msg: "",
    type: "",
    isFailed: false,
    button: null
  },
  handleNotificationMsgsData: (props) => { },
});

export function UseToasterNotificationsContext() {
  return useContext(notificationsData);
}

const ToasterNotificationsContext = ({ children }) => {
  const [notificationMsgsData, setNotificationMsgsData] = useState({
    showMsgBar: false,
    started: false,
    completed: false,
    msg: "",
    type: "",
    isFailed: false,
    button: null
  });

  const handleNotificationMsgsData = (props) => {
    setNotificationMsgsData((prevData) => {
      return { ...prevData, ...props, button: props?.button };
    });
  };

  return (
    <notificationsData.Provider value={{ notificationMsgsData, handleNotificationMsgsData }}>
      {children}
    </notificationsData.Provider>
  );
};

export default ToasterNotificationsContext;
