import React, { useEffect, useState } from "react";
import { BsXLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import TextBox from "../../commonui/commonformcomponents/textbox/TextBox";
import PeoplePicker from "../../commonui/commonformcomponents/peoplepicker/peoplePicker";
import ButtonPrimary from "../../commonui/button/buttonPrimary";
import { useHamburgerMenuContext } from "../../context/menucontext/menuContext";
import UsersTable from "../usermanagement/userstable/UsersTable";
import { Checkbox } from "@fluentui/react-northstar";
import FooterTechButtons from "../../commonui/commonformcomponents/footertechbuttons/FooterTechButtons";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import { showLayover, hideLayover } from "../../utility/utility";
import { getDataById, getData, postData } from "../../services/customApis";
import APIEndPoints from "../../utility/apiendpoints";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import Label from "../../commonui/commonformcomponents/label/Label";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import TelemetryEventNames from "../../data/apptelemetry/appTelemetry.const";
import { TrackEvent } from "../../services/appInsights";
import { ImageUtil } from "../../utility/utility";
import { useLocation } from "react-router-dom";
import Constant from "../../utility/constants";
import "./userGroupCreationForm.css";
import Alert from "../../commonui/commonformcomponents/alert/alert";
import { AiFillInfoCircle } from "react-icons/ai";
import { UseContactUsContext } from "../../context/contactuscontext/contactUsContext";
import { useMenuContext } from "../../context/menucontext/menuContext";

function UserGroupCreationForm() {
  const location = useLocation();
  const { menuState } = useMenuContext();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userEmails, setUserEmailArray] = useState([]);
  const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();

  const [isLoading, setIsLoading] = useState(false);
  const [alreadyExist, setAlreadyExist] = useState(false);
  let { userInfo } = UseUserInfoContext();
  const [isAddButtonDisable, setIsAddButtonDisable] = useState(true);
  const [groupName, setGroupName] = useState("");
  const [clear, setClear] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [isExtendedToAllChannelUsers, setIsExtendedToAllChannelUsers] =
    useState(false);
  const [customUserGroupId, setCustomUserGroupId] = useState(0);
  const navigate = useNavigate();
  const [toggleFeatureFlag, setToggleFeatureFlag] = useState(false);
  const [toggleButtonDisable, setToggleButtonDisable] = useState(true);
  const [showToaster, setShowToaster] = useState(true);
  const handleCloseNotifications = () => {
    if (window.innerWidth >= 900) {
      if (!toggleHamburger) {
        handleHamburgerChange();
      }
    }
    navigate(`/settings/customusergroups`);
  };
  const handleRedirection = () => {
    if (window.innerWidth >= 900) {
      if (!toggleHamburger) {
        handleHamburgerChange();
      }
    }
    navigate(`/settings/customusergroups`);
  };
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const {handleMenuInfo} = UseContactUsContext();   

  useEffect(() => {
    handleMenuInfo({
      menuName: "Settings",
      subMenuName: menuState?.menu?.name,
      sourceComponent: ""
    });
  }, []);

  useEffect(() => {
    setShowToaster(true);
  }, [userInfo?.teams?.companyName]);

  const onUserChange = (item) => {
    setClear(false);

    setIsAddButtonDisable(false);

    /*setting people picker value */
    setUserEmailArray(item);

    groupName !== "" && selectedUsers.length > 0
      ? setIsButtonDisable(true)
      : setIsButtonDisable(false);
  };

  const textBoxChangeHandler = (e) => {
    setGroupName(e.target.value.trim());

    if (e.target.value.trim() !== "" && selectedUsers.length > 0) {
      setIsButtonDisable(true);
    } else {
      setIsButtonDisable(false);
    }
  };

  const updateToggle = (checked) => {
    setIsExtendedToAllChannelUsers(checked.checked);
    customUserGroupId !== 0 &&
      setIsButtonDisable(
        groupName !== "" && selectedUsers.length > 0 ? true : false
      );
  };

  const handleUserListChange = (userList, fromCustomUserGroupForm) => {
    if (!fromCustomUserGroupForm) {
      /* remove from the users table grid and emails list to save */
      setSelectedUsers(userList);
      setIsButtonDisable(
        groupName !== "" && userList.length > 0 ? true : false
      );
    } else {
      /*no action on people picker when removed from grid" */
    }
  };

  const addToUsersList = () => {
    /* add values to the users table grid below */
    let userList = [];
    selectedUsers?.length > 0
      ? (userList = [...selectedUsers])
      : (userList = []);

    /* check for duplicates */
    let usersListArrayToCheckDuplicates = [...userEmails];

    usersListArrayToCheckDuplicates.forEach((item) => {
      let isDuplicate = userList.some(
        (arrayItem) => arrayItem.name === item.name
      );
      if (isDuplicate) {
        setAlreadyExist(true);
        const updatedEmailList = userEmails.filter(
          (arrayItem) => arrayItem.name !== item.name
        );
        console.log("updatedEmailList", updatedEmailList);
      } else {
        userList.push(item);
        /* sorting alphabetically */
        userList.sort((a, b) => a.name.localeCompare(b.name));
        setSelectedUsers(userList);
        setIsButtonDisable(
          groupName !== "" && userList?.length > 0 ? true : false
        );
      }
    });
    /* remove values from people picker */
    setUserEmailArray([]);
  };

  useEffect(() => {
    setIsLoading(true);

    window.scrollTo(0, 0);
    if (toggleHamburger) {
      handleHamburgerChange();
    }
    //write a post method to fetch users list

    let customUserGroupId = location?.state?.isCustomUserGroupEdit;

    if (customUserGroupId !== 0 && customUserGroupId !== undefined) {
      setCustomUserGroupId(customUserGroupId);
      getCustomUserGroupFormData();
    } else {
      setIsLoading(false);
      getFeatureFlags(customUserGroupId);
    }

    return () => {};
  }, []);

  const getFeatureFlags = async (groupId, creatorEmailId) => {
    let subMenuItemId =
        userInfo?.teams?.featureFlagsObj["User Groups"]?.subMenuItemId;
        
      let result =  await getDataById(
      "",
      APIEndPoints.GET_PAGE_LEVEL_FLAGS(
        userInfo?.teams?.companyId,
        subMenuItemId
      )
    );
    console.log("res", result?.data?.contentType);
    const configData = result?.data?.contentType;

    if (groupId !== 0 && groupId !== undefined) {
      /*edit mode*/
      setToggleFeatureFlag(
        configData[0]?.contentCategories?.[1]?.featureFlagsDetails
          ?.editAdminCustomUserGroup.editAdminCustomUserGroupDisplay
      );
      setToggleButtonDisable(
        configData[0]?.contentCategories?.[1]?.featureFlagsDetails
          ?.editAdminCustomUserGroup.editAdminCustomUserGroupEnabled &&
          creatorEmailId === userInfo?.email
      );
    } else {
      /*add mode */
      setToggleFeatureFlag(
        configData[0]?.contentCategories?.[1]?.featureFlagsDetails
          ?.createAdminCustomUserGroup.createAdminCustomUserGroupDisplay
      );
    }
  };
  const getCustomUserGroupFormData = async () => {
    await getData(
      APIEndPoints.GET_CUSTOM_USER_GROUP_BY_ID(
        userInfo.teams.companyId,
        location?.state?.isCustomUserGroupEdit
      )
    )
      .then((response) => {
        let selectedUsersData = response?.data?.userDetails?.map((item, i) => {
          return {
            content: item?.emailId,
            header: item?.name,
            image: ImageUtil(item?.name),
            name: item?.name,
            userid: item?.emailId,
          };
        });
        /* the people picker value should be empty */
        setUserEmailArray([]);
        /* the users table should have values */
        setSelectedUsers(selectedUsersData);
        setIsButtonDisable(false);
        const creatorEmailId = response?.data?.creatorEmail;

        setGroupName(response?.data?.customUserGroupName);

        setIsExtendedToAllChannelUsers(
          response?.data?.isExtendedToAllChannelUsers
        );

        getFeatureFlags(location?.state?.isCustomUserGroupEdit, creatorEmailId);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        handleRedirection();
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: Constant.SOMETHING_WENTWRONG,
          type: "fail",
          isFailed: false,
        });
      });
  };

  const validateContent = async () => {
    customUserGroupId !== 0 ? updateCustomUserGroup() : createCustomUserGroup();
  };

  /* FORM SUBMIT */
  const createCustomUserGroup = () => {
    let usersData = selectedUsers?.map((item, i) => {
      return {
        name: item?.name,
        emailId: item?.email,
      };
    });
    let obj = {
      customUserGroupName: groupName,
      isExtendedToAllChannelUsers: isExtendedToAllChannelUsers,
      userDetails: usersData,
    };
    console.log(obj);
    setIsLoading(true);
    showLayover();

    postData(
      obj,
      APIEndPoints.CREATE_CUSTOM_USER_GROUP(userInfo?.teams?.companyId)
    )
      .then((response) => {
        hideLayover();
        setIsLoading(false);
        console.log(response);
        if (response.status === 200) {
          handleRedirection();
          setTimeout(() => {
            handleNotificationMsgsData({
              showMsgBar: true,
              started: false,
              completed: true,
              msg: Constant.CUSTOM_USER_GROUPS
                .CREATE_CUSTOM_USER_GROUP_SUCCESS_MESSAGE,
              type: "Success",
              isFailed: false,
            });
          }, 1000);
          //TESTED
          TrackEvent(
            TelemetryEventNames.UCC_UPDATE_USER_EEPERIENCE_EVENT,
            userInfo,
            {
              SectionName: TelemetryEventNames.SECTION_TYPE_OTHER,
              PageType: "CustomUserGroup",
            }
          );
        } else {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: response?.Errors[0],
            type: "Fail",
            isFailed: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        let errorText = error?.response?.data?.Errors?.[0];
        setIsLoading(false);
        hideLayover();
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: errorText
            ? errorText
            : Constant.CUSTOM_USER_GROUPS
                .CREATE_CUSTOM_USER_GROUP_FAILURE_MESSAGE,
          type: "fail",
          isFailed: false,
        });
      });
  };

  const updateCustomUserGroup = () => {
    let usersData = selectedUsers?.map((item, i) => {
      return {
        name: item?.name,
        emailId: item?.content,
      };
    });

    let obj = {
      customUserGroupName: groupName,
      isExtendedToAllChannelUsers: isExtendedToAllChannelUsers,
      userDetails: usersData,
      customUserGroupId: customUserGroupId,
    };
    console.log(obj);
    setIsLoading(true);
    showLayover();

    postData(
      obj,
      APIEndPoints.UPDATE_CUSTOM_USER_GROUP(userInfo?.teams?.companyId)
    )
      .then((response) => {
        setIsLoading(false);
        console.log(response);
        if (response.status === 200) {
          hideLayover();
          handleRedirection();
          setTimeout(() => {
            handleNotificationMsgsData({
              showMsgBar: true,
              started: false,
              completed: true,
              msg: Constant.CUSTOM_USER_GROUPS
                .UPDATE_CUSTOM_USER_GROUP_SUCCESS_MESSAGE,
              type: "Success",
              isFailed: false,
            });
          }, 1000);
          //TESTED
          TrackEvent(
            TelemetryEventNames.UCC_UPDATE_USER_EEPERIENCE_EVENT,
            userInfo,
            {
              SectionName: TelemetryEventNames.SECTION_TYPE_OTHER,
              PageType: "CustomUserGroup",
            }
          );
        } else {
          hideLayover();
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: response?.Errors[0],
            type: "Fail",
            isFailed: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        let errorText = error?.response?.data?.Errors?.[0];
        setIsLoading(false);
        hideLayover();

        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: errorText
            ? errorText
            : Constant.CUSTOM_USER_GROUPS
                .UPDATE_CUSTOM_USER_GROUP_FAILURE_MESSAGE,
          type: "fail",
          isFailed: false,
        });
      });
  };

  return (
    <>
      {isLoading && <ReusableLoader isOverlay={true} isAppBody={true} />}
      <div>
        <div className="common_container_header customUserGroupCreationHeader">
          <header>
            <p className="common_title">
              {"User Groups"}
              <BsXLg
                title="Close"
                className="contactus-close"
                onClick={() => handleCloseNotifications()}
              />
            </p>
          </header>
        </div>
        <div className="usergroupbody">
          <Alert
            content={
              <div style={{ display: "flex", alignItems: "center" }}>
                <AiFillInfoCircle />
                {Constant.CUSTOM_USER_GROUPS.CUSTOM_USER_GROUP_FORM}
              </div>
            }
            visible={showToaster}
            warning={true}
            dismissible={true}
            onClickDismiss={() => {
              setShowToaster(false);
            }}
            className={"alertPadding"}
          />
          <div className={`row_wrapper`}>
            <Label content={"Group Name"} checkIfRequired={true} />
            <TextBox
              placeholder={"Type a group name"}
              onChange={textBoxChangeHandler}
              value={groupName}
              isCustomUserGroup={true}
              validations={[
                {
                  name: "MaxLength",
                  maxChar: 250,
                  errorMessage:
                    Constant.CUSTOM_USER_GROUPS.GROUP_NAME_MAX_LIMIT_MESSAGE,
                },
              ]}
              name={"Group Name"}
            />
          </div>
          <Label content={"Search Users"} checkIfRequired={true} />
          <div className="add-users__people-picker">
            <PeoplePicker
              about="OK"
              search={true}
              multiple={true}
              placeholder={"Type the name of person or email id"}
              checkable={true}
              fluid={true}
              userType={0}
              value={userEmails}
              searchAd={false}
              onUserChange={onUserChange}
              fromCustomUserGroup={true}
              clear={clear}
            />
            <ButtonPrimary
              text={"Add"}
              onClick={addToUsersList}
              disabled={isAddButtonDisable || !(userEmails?.length > 0)}
            />
          </div>
          <div className="add-users__results">
            {alreadyExist && (
              <div className="users-already-exist">
                <span>
                  {Constant.CUSTOM_USER_GROUPS.USER_ALREADY_EXISTS_MESSAGE}
                </span>{" "}
                <span
                  title="Close"
                  onClick={() => setAlreadyExist(false)}
                  style={{ cursor: "pointer", paddingRight: "10px" }}
                >
                  &#10005;
                </span>
              </div>
            )}
            <div className="users-results-group">
              <UsersTable
                list={selectedUsers}
                showHeaders={false}
                tableType={2}
                fromCustomUserGroup={true}
                handleUserListChange={handleUserListChange}
              />
            </div>
          </div>
        </div>
        {toggleFeatureFlag && (
          <div className="usergroup-toggleContainer">
            <Checkbox
              toggle
              checked={isExtendedToAllChannelUsers}
              disabled={!toggleButtonDisable}
              onChange={(ev, checked) => updateToggle(checked)}
            />

            <p>{Constant.CUSTOM_USER_GROUPS.EXTEND_CUSTOM_USER_GROUP}</p>
          </div>
        )}

        <div className="formFooter">
          <FooterTechButtons
            disabled={!isButtonDisable}
            cancelText={"Cancel"}
            sendText={customUserGroupId !== 0 ? "Update" : "Create"}
            onClickAddData={validateContent}
            onClickCancel={handleCloseNotifications}
          />
        </div>
      </div>
    </>
  );
}
export default UserGroupCreationForm;
