import { createContext, useContext, useState } from 'react';
const searchAndFilterColumnsObj = {
};
const searchAndFilterColumns = createContext({
    searchAndFilterColumnsData: searchAndFilterColumnsObj,
    handleSearchAndFilterColumnsData: (props) => {},
});

export const SearchAndFilterColumnContext = ({ children }) => {
  const [searchAndFilterColumnsData, setSearchAndFilterColumnsData] = useState({});
  const handleSearchAndFilterColumnsData = (props) => {
    setSearchAndFilterColumnsData((prevData) => {
      return { ...prevData, ...props };
    });
  };
  return (
    <searchAndFilterColumns.Provider value={{ searchAndFilterColumnsData, handleSearchAndFilterColumnsData }}>
      {children}
    </searchAndFilterColumns.Provider>
  );
};

export const UseSearchAndFilterColumnContext = () => useContext(searchAndFilterColumns);
export default SearchAndFilterColumnContext;
