import { createContext, useState } from "react";

export const BreadCrumbContext = createContext();

//CONTEXT IMPLEMENTATION
// import { useContext } from 'react';

// import { BreadCrumbContext } from '../../context/breadcrumbcontext/

// const {cardTitle, isDetailOpen, ResetBreadCrumb3LevelTitle, actualBreadCrumbArray} = useContext(BreadCrumbContext)

const BreadCrumbProvider = ({ children }) => {
  const [cardTitle, setCardTitle] = useState("");
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const SetBreadCrumb3LevelTitle = (prop) => {
    // let title = prop.split(" ");
    // let finalTitle;
    // //let pinLibraryDetailsTitle = `${title[1]?.replaceAll(',', '')}`;
    // if (title[2] === undefined) {
    //   finalTitle = `${title[0]}...`;
    // } else {
    //   finalTitle = `${title[0]} ${title[1]} ${title[2]?.replaceAll(
    //     ",",
    //     ""
    //   )}...`;
    // }
    setCardTitle(prop);
    setIsDetailOpen(true);
    console.log(prop);
  };

  const ResetBreadCrumb3LevelTitle = () => {
    setCardTitle("");
    setIsDetailOpen(false);
  };

  let breadcrumbFunctionalities = {
    cardTitle,
    SetBreadCrumb3LevelTitle,
    ResetBreadCrumb3LevelTitle,
    isDetailOpen,
  };

  return (
    <BreadCrumbContext.Provider value={breadcrumbFunctionalities}>
      {children}
    </BreadCrumbContext.Provider>
  );
};

export default BreadCrumbProvider;
