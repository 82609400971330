import React, { useEffect, useState, useRef, useMemo } from "react";
import Constant from "../../../utility/constants";
import DropdownComponent from "../../../commonui/commonformcomponents/dropdown/dropdown";
import MultiSelectReactDropdown from "../../../commonui/commonformcomponents/multiselectreactdropdown/MultiSelectReactDropdown";
import DatePicker from "../../../commonui/commonformcomponents/datepicker/datePicker";
import { SearchAndFilterColumnDynamic } from "../../../commonui/searchandfiltercolumndynamic/searchAndFilterColumnDynamic";
import { TooltipHost, tooltipHostProps } from "@fluentui/react";
import { PanelView } from "../../../commonui/panelview/panelView";
import DialogBox from "./../../../commonui/dialogBox/DialogBox";
import { Input } from "@fluentui/react-northstar";
import { IoInformationCircle } from "react-icons/io5";
import BooleanExpressionEditor from "./booleanExpressionTextBox";
import { TbXboxX } from "react-icons/tb";
import {
  DetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  ConstrainMode,
  Selection
} from "@fluentui/react/lib/DetailsList";
import { useNavigate } from "react-router-dom";
import Label from "../../../commonui/commonformcomponents/label/Label";
import { Button } from "@fluentui/react-northstar";
import TextBox from "../../../commonui/commonformcomponents/textbox/TextBox";
import { getData, postData } from "../../../services/customApis";
import APIEndPoints from "../../../utility/apiendpoints";
import { customFormatter, checkIfDeviceIsMob } from "../../../utility/utility";
import { utils } from "../../../commonui/datagrid/utils";
import CheckboxComponent from "../../../commonui/commonformcomponents/checkbox/checkbox";
import { RxDragHandleDots2 } from "react-icons/rx";
import { RiDeleteBin6Line } from "react-icons/ri";
import { UseUserInfoContext } from "../../../context/usercontext/userContext";
import ReusableLoader from "../../../commonui/reusableloader/reusableLoader";
import { UseLibrarySearchFormContext } from "../../../context/librarysearchformcontext/librarySearchFormContext";
import { UseToasterNotificationsContext } from "../../../context/toasternotificationscontext/toasterNotificationsContext";
import {
  showLayover,
  hideLayover
} from "../../../utility/utility";
import PowerBiReport from "../../../components/visualizations/pinlibrary/pinlibrarydetails/pbireport/pbiReport";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";
import LibraryGrid from "../libraryGrid/libraryGrid";
import { constant } from "lodash";
import "./landingPage.css";
function LandingPage(props) {
  let internalAppDetails = props.state?.appDetails;
  let templateDetails = props.state?.templateDetails;
  let privilagesForPowerKApp = props.state?.privilagesPowerKApp;
  const navigate = useNavigate();
  let { userInfo } = UseUserInfoContext();
  const scrollDiv = useRef();
  const {librarySearchFormJsonData, handleLibrarySearchFormJsonData } =
  UseLibrarySearchFormContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const [gridData, setGridData] = useState(librarySearchFormJsonData?.powerKLibrarySearchGridData);
  const [panelOpen, setPanelOpen] = useState(false);
  const [currentParam, setCurrentParam] = useState({});
  const [specialCharacters, setSpecialCharacters] = React.useState();
  const [searchFormStepData, setSearchFormStepData] = useState({});
  const [booleanExpression, setBooleanExpression] = useState(librarySearchFormJsonData?.powerKBooleanExpression);
  const [hasDrodownUpdated, setHasDrodownUpdated] = useState(false);
  const [selectedSection, setSelectedSection] = useState("");
  const [errorAccessDenied, setAccessDenied] = useState(false);
  const [errorApiCall, setErrorApiCall] = useState(false);
  const [librarySearchForm, setLibrarySearchForm] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isGridLoading, setIsGridLoading] = useState(false);
  const [columnsForSearchGrid, setColumnsForSearchGrid] = useState([]);
  const [masterGroupsSorted, setMasterGroupsSorted] = useState([]);
  const [masterOperators, setMasterOperators] = useState([]);
  const [draggedItem, setDraggedItem] = useState(gridData || undefined);
  const [draggedIndex, setDraggedIndex] = useState(-1);
  const selection = useRef(new Selection()).current;
  const [currentItemToBeDeleted, setCurrentItemToBeDeleted] = useState({});
  const [loading, setLoading] = useState(false);
  const [pbReportConfig, setPBReportConfig] = useState({});
  const [openItems, setOpenItems] = useState(['1']);
  const [executeData, setExecuteData] = useState({
    searchQuery : librarySearchFormJsonData?.searchQuery
  } ?? {});
  const [searchFormPayload, setSearchFormPayload] = useState({
    stepData: [],
    booleanExpression: {
      expression: ""
    },
    customData: {
      stepsApplied: 0,
      totalSearchResults: librarySearchFormJsonData?.totalRecordsCount
    }
  });
  const [validationMessage, setValidationMessage] = useState("");
  const inputRef = useRef(null);
  const previousBooleanExpression = useRef("");
  const selectionPoints = useRef({
    startingPoint: 0,
    endingPoint: 0
  })
  const [fromToDatePicker, setFromToDatePicker] = useState([null, null]);
  const [buttonContent, setButtonContent] = useState(Constant.LANDING_PAGE_CONSTANTS.EXECUTE);
  const [showAccordion, setShowAccordion] = useState(Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_LIBRARY_ACCORDION);
  const [isBackToSearch,setIsBackToSearch] = useState(true);
  const changedByExecute = useRef(false);
  const shouldShowAccordion = librarySearchForm?.searchPageConfig?.type === showAccordion;
  const [isAccordionDisabled, setIsAccordionDisabled] = useState(true);

  const isButtonDisabled = (param) => {
    if (!gridData.length > 0) {
      if (param?.groupType === "child") {
        return true;
      }
      if (param?.groupType === "parent") {
        return false;
      }
    } 
    else {
      if (param?.groupType === "parent") {
        return true;
      }
    }
    return false;
  };
  const handleClearSelection = () => {
    if (gridData?.length > 0) {
      setGridData([]);
      setIsAccordionDisabled(true);
    }
  };
  const closeLoader = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (searchFormStepData?.searchParamsData?.type === Constant.LANDING_PAGE_CONSTANTS.DATEPICKER_TYPE) {
      let selectedValues = searchFormStepData?.userInputs?.selectedValues || [null, null];
      if (Array.isArray(selectedValues) && selectedValues.length > 0) {
        selectedValues = selectedValues.map(date => (date ? new Date(date) : null));
      } else {
        selectedValues = [null, null];
      }
      setFromToDatePicker(selectedValues);
    }
  }, [searchFormStepData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getAllowedChacter = () => {
      getData(APIEndPoints.ALLOWED_SPECIAL_CHARACTERS).then((response) => {
        if (response.data) {
          setSpecialCharacters(response.data);
        }
      })
        .catch((error) => {
          setIsLoading(false);
        });
    };
    const getLibrarySearchFormJsonData = async () => {
      const params = internalAppDetails
        ? {
          isInternalApp: internalAppDetails.isInternal,
          appId: internalAppDetails.appID,
          customData: {},
          templateId: templateDetails?.templateId?.toString()
        }
        : {
          isInternal: false,
          appId: null,
          customData: {},
          templateId: templateDetails?.templateId?.toString()
        };
      try {
        const jsonTemplateData = await postData(
          params,
          APIEndPoints.GET_SEARCHTEMPLATE_JSON_DETAILS(
            internalAppDetails?.contentTypeId,
            userInfo?.teams?.companyId
          )
        );
        handleAttachmentDownload(jsonTemplateData?.data?.resultData);
      }
      catch (error) {
        setIsLoading(false);
        if (
          error?.response?.data?.Errors?.[0]?.toLowerCase() === "access denied."
        ) {
          setAccessDenied(true);
        } else {
          setErrorApiCall(true);
        }
      }
    };
    getAllowedChacter();
    getLibrarySearchFormJsonData();
  }, []);

  useEffect(() => {
    if(isBackToSearch)
      {
        setIsBackToSearch(false);
      }
    else if (gridData?.length > 0) 
      {    
      let allIsSelectedSteps = gridData.filter((item) => item?.uiConfigs?.isSelected === true);
      if (allIsSelectedSteps.length > 0) {
        let updatedBooleanExpression = allIsSelectedSteps.map((item) => {
          if (item?.uiConfigs?.reOrderIndex === 1 &&
            item?.userInputs?.selectedOperators?.length > 0 &&
            item?.userInputs?.selectedOperators[0].toLowerCase() !== "not") {
            return `${item["stepId"]}`
          }
          else {
            return item?.userInputs?.selectedOperators?.length > 0 ?
              `${item?.userInputs["selectedOperators"][0]} ${item["stepId"]}`
              : `${item["stepId"]}`
          }
        }).join(" ");
        let trimmedUpdatedBooleanExpression = updatedBooleanExpression.replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').toLowerCase();
        let trimmedBooleanExpression = booleanExpression.replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').toLowerCase();
        if (trimmedUpdatedBooleanExpression !== trimmedBooleanExpression)
          setBooleanExpression(`${updatedBooleanExpression.toUpperCase()}`);
      }
      else {
        setBooleanExpression("");        
      }      
    }
    else {
      setBooleanExpression("");      
    }
    if(changedByExecute.current || isBackToSearch)
    {
      changedByExecute.current = false;
    }
    else
    {
      setSearchFormPayload((prevValue)=>({ ...prevValue, customData: { ...prevValue.customData, totalSearchResults: "" } }));
    }
    handleLibrarySearchFormJsonData({
      powerKLibrarySearchGridData: gridData,
    });
  }, [gridData]);
  useEffect(() => { 
    if (internalAppDetails?.contentTypeId == Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES) {
      setLoading(true);
      let templatID = librarySearchForm?.templateConfig?.vizualisationReportId;
      const decodeUniqeId = atob(executeData?.searchQuery);
      let obj = {
        templateid: templatID,
        uniqueid: decodeUniqeId,
      };
      let reportDetail = [];
      reportDetail.push(obj);
      
      let postParams = {
        contentType: internalAppDetails?.contentTypeId,
        reportDetail: reportDetail[0],
      };
      getPowerBIReportDetails(postParams, userInfo);
    }
  }, [searchFormPayload]);

  const getPowerBIReportDetails = async (postParams, userInfo)=> {
    try {
      const tokenDetails = await postData(
        postParams,
        APIEndPoints.PBREPORT_TOKEN_ENDPOINT(userInfo?.teams?.companyId),
        false,
        true
      );
      console.log("Token Details", tokenDetails);
      setPBReportConfig(tokenDetails.data);
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.Errors[0]?.toLowerCase() === "access denied.") {
        setAccessDenied(true);
      } else {
        setErrorApiCall(true);
      }
    }
  }
  const handleExecuteClick = (e) => {
  if (e.target?.innerText?.toLowerCase() === Constant.LANDING_PAGE_CONSTANTS.EXECUTE?.toLowerCase()) {
      setButtonContent(internalAppDetails?.contentTypeId ==  Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES ? Constant.LANDING_PAGE_CONSTANTS.VIEW_REPORTS : Constant.LANDING_PAGE_CONSTANTS.VIEW_SEARCH_RESULTS);
      execute();
    }
    else {
      viewSearchResults();
    }
  };

  const deleteStep = (item) => {
    const checkRemainingSteps = gridData.filter((items) => items?.stepId > item?.stepId);
    let remainingSelectedSteps;
    let nextEligibleFirstStep = {};
    if (item?.uiConfigs?.reOrderIndex === 1) {
      remainingSelectedSteps = gridData.filter((filterItem) => filterItem?.uiConfigs?.isSelected && filterItem?.stepId > item?.stepId);
      if (remainingSelectedSteps.length > 0) {
        nextEligibleFirstStep = remainingSelectedSteps[0];
      }
    }
    let removeSelectedStep = gridData.filter((items) => items?.stepId !== item?.stepId);
    if (checkRemainingSteps?.length > 0) {
      const updatedTheStepIds = removeSelectedStep.map((currentItem) => {
        if (currentItem?.stepId > item?.stepId) {
          if (item?.uiConfigs?.reOrderIndex === 1 && (currentItem?.stepId === nextEligibleFirstStep?.stepId)) {
            return {
              ...currentItem,
              stepId: currentItem?.stepId - 1,
              userInputs: { ...currentItem.userInputs, selectedOperators: currentItem?.userInputs?.selectedOperators[0].toLowerCase() !== "not" ? [] : currentItem?.userInputs?.selectedOperators },
              uiConfigs: { ...currentItem.uiConfigs, reOrderIndex: 1 }
            };
          }
          return { ...currentItem, stepId: currentItem?.stepId - 1 }
        }
        return currentItem

      })
      setGridData(updatedTheStepIds);
    }
    else {
      setGridData(removeSelectedStep);
    }
    setCurrentItemToBeDeleted({});
    setButtonContent(Constant.LANDING_PAGE_CONSTANTS.EXECUTE);
  }

  useEffect(() => {
    const trimmedPrevious = previousBooleanExpression.current.replace(/\s/g, '');
    const trimmedCurrent = booleanExpression.replace(/\s/g, '');

    if (trimmedPrevious === trimmedCurrent && buttonContent === (Constant.LANDING_PAGE_CONSTANTS.VIEW_SEARCH_RESULTS||Constant.LANDING_PAGE_CONSTANTS.VIEW_REPORTS)) {
      setButtonContent(internalAppDetails?.contentTypeId ==  Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES ? Constant.LANDING_PAGE_CONSTANTS.VIEW_REPORTS : Constant.LANDING_PAGE_CONSTANTS.VIEW_SEARCH_RESULTS);
    } else {
      setButtonContent(Constant.LANDING_PAGE_CONSTANTS.EXECUTE);
    }

    if (validationMessage?.length < 2) {
      let openParenthesisCount = booleanExpression.match(/\(/g) ? booleanExpression.match(/\(/g).length : 0;
      let closedParenthesisCount = booleanExpression.match(/\)/g) ? booleanExpression.match(/\)/g).length : 0;
      if (openParenthesisCount !== closedParenthesisCount) {
        setValidationMessage(Constant.LANDING_PAGE_CONSTANTS.PARENTHESES_COUNT_NOT_MATCHING);
      }
    }
    previousBooleanExpression.current = booleanExpression;
    handleLibrarySearchFormJsonData({
      powerKBooleanExpression: booleanExpression,
    });
  }, [booleanExpression]);
  const handleAttachmentDownload = async (fileDetails) => {
    try {
      const response = await postData(
        {
          metaDataId: 0,
          contentType: internalAppDetails.contentTypeId,
          attachments: [fileDetails],
          appId: internalAppDetails.appID
        },
        APIEndPoints.DOWNLOAD_IMAGES(
          internalAppDetails.contentTypeId,
          true,
          userInfo?.teams?.companyId
        )
      );

      if (response?.status === false) {
        setIsLoading(false);
        throw new Error("Download failed");
      }
      else if (response?.data !== null) {
        const base64Data = response.data
          .replace("data:application/json;base64,", "")
          .replace("data:application/octet-stream;base64, ", "");
        const decodedString = atob(base64Data);
        try {
          const jsonObject = JSON.parse(decodedString);
          setLibrarySearchForm(jsonObject);
          setMasterGroupsSorted(jsonObject?.masterGroups?.sort((a, b) => a?.displayOrder - b.displayOrder));
          setMasterOperators(jsonObject?.masterOperators?.sort((a, b) => a?.displayOrder - b.displayOrder));
          const operatorColumns = jsonObject?.masterOperators?.length > 0 && jsonObject?.masterOperators.map(operator =>
            createColumn(operator?.operatorName?.toLowerCase(), operator?.operatorName, operator?.operatorName?.toLowerCase(), 30, 30, false, "checkbox")
          );
          setColumnsForSearchGrid([
            ...staticColumns.slice(0, 4),
            ...operatorColumns,
            ...staticColumns.slice(4),
          ])
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.error("Failed to parse JSON:", error);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to download attachment:", error);
    }
  };

  const closePanel = () => {
    setPanelOpen(false);
    setSelectedSection(""); 
  };

  const openPanel = (param, isEdit) => {
    setPanelOpen(true);
    let currentGroup = librarySearchForm?.searchParams
      ?.filter(
        (searchParam) =>
          searchParam.groupId === param.groupId ||
          searchParam.groupId === param?.searchParamsData?.groupId
      )
      ?.sort((a, b) => a?.displayOrder - b.displayOrder);

    setCurrentParam({
      ...currentParam,
      groupDetails: param,
      groupedParams: currentGroup,
      isEdit: isEdit ?? false,
    });
    if (param?.userInputs?.selectedValues?.length > 0) {
      setSearchFormStepData(param);
    } else {
      setSearchFormStepData({});
    }
  };

  const handleSelectedValues = (selectedValues) => {
    if (selectedValues.length === 2) {
      if (!selectedValues[0] && selectedValues[1]) {
        selectedValues[0] = selectedValues[1];
      } else if (selectedValues[0] && !selectedValues[1]) {
        selectedValues[1] = selectedValues[0];
      }
    }
    return selectedValues;
  };

  const addGridData = (isEdit) => {
    let gridDataArr = [...gridData];
    if (isEdit) {
      const existingIndex =
        gridData &&
        gridData?.findIndex(
          (item) => item.stepId === searchFormStepData.stepId
        );
      if (existingIndex !== -1) {
        if (searchFormStepData?.searchParamsData?.type === Constant.LANDING_PAGE_CONSTANTS.DATEPICKER_TYPE && searchFormStepData?.userInputs?.selectedValues?.length > 0) {
          searchFormStepData.userInputs.selectedValues = handleSelectedValues(searchFormStepData.userInputs.selectedValues);
        }
        let existingIndexSelectedValues = gridDataArr[existingIndex];
        if (JSON.stringify(existingIndexSelectedValues) !== JSON.stringify(searchFormStepData)) {
          setButtonContent(Constant.LANDING_PAGE_CONSTANTS.EXECUTE);
        }
        gridDataArr[existingIndex] = searchFormStepData;
      } else {
        gridDataArr.push(searchFormStepData);
      }
      setGridData(gridDataArr);
    }
    else {
      if (searchFormStepData?.searchParamsData?.type === Constant.LANDING_PAGE_CONSTANTS.DATEPICKER_TYPE && searchFormStepData?.userInputs?.selectedValues?.length > 0) {
        searchFormStepData.userInputs.selectedValues = handleSelectedValues(searchFormStepData.userInputs.selectedValues);
      }
      gridDataArr.push(searchFormStepData);
      setGridData(gridDataArr);
      setButtonContent(Constant.LANDING_PAGE_CONSTANTS.EXECUTE);
    }
    setSearchFormStepData({});
    setPanelOpen(false);
    setSelectedSection(""); 
  };

  const renderControl = (controlObj) => {
    if (controlObj != undefined) {
      switch (controlObj?.type) {
        case "text":
          return (
            <>
              <Label content={controlObj?.displayName} />
              <TextBox
                placeholder={controlObj.placeholder}
                required={controlObj?.isRequired}
                onChange={(e) => {
                  controlsChangeHandler(e, controlObj);
                }}
                additionalMessage={controlObj?.additionalMessage}
                value={searchFormStepData?.userInputs?.selectedValues}
                name={controlObj?.displayName}
                id={controlObj?.paramUniqueId}
                disabled={false}
                isLibrarySearch={true}
              />
            </>
          );
        case "datePicker":
          let val = searchFormStepData?.userInputs?.selectedValues;
          if (val != null && val != undefined && val != "") {
            val = new Date(val);
          }
          return (
            <>
              <Label content={controlObj?.displayName} />
              <DatePicker
                onChangeHandler={(e) => {
                  controlsChangeHandler(e, controlObj);
                }}
                allowManualInput={true}
                inputPlaceholder="mm-dd-yyyy"
                clearable={true}
                name={controlObj?.displayName}
                placeholder={controlObj.placeholder}
                value={val}
                id={controlObj?.paramsId}
                disabled={false}
                isLibrarySearch={true}
              />
            </>
          );
        case "fromToDatePicker":
          let fromToDatePicker = searchFormStepData?.userInputs?.selectedValues || [null, null];
          if (Array.isArray(fromToDatePicker) && fromToDatePicker.length > 0) {
            fromToDatePicker = fromToDatePicker.map(date => (date ? new Date(date) : null));
          }
          return (
            <div className="fromToDatePickersContainer">
              {controlObj?.typeConfig?.length > 0 && controlObj.typeConfig.map((config, index) => (
                <div key={index} className={index === 0 ? "fromToDatePickerStart" : "fromToDatePickerEnd"}>
                  <Label content={config.displayName} />
                  <DatePicker
                    onChangeHandler={(e) => {
                      const newDates = [...fromToDatePicker];
                      newDates[index] = e;
                      controlsChangeHandler(newDates, controlObj);
                    }}
                    allowManualInput={true}
                    inputPlaceholder="mm-dd-yyyy"
                    clearable={true}
                    name={config.displayName}
                    placeholder={config.placeholder}
                    value={fromToDatePicker[index]}
                    id={config.paramsId}
                    disabled={false}
                    isLibrarySearch={true}
                  />
                </div>
              ))}
            </div>
          );

        case "dropdown":
          if (controlObj?.isMultiSelect == true) {
            if (controlObj?.isSearchable) {
              return (
                <>
                  <Label content={controlObj?.displayName} />
                  <SearchAndFilterColumnDynamic
                    appDetails={internalAppDetails}
                    appSearchQueryValue={""}
                    fieldName={controlObj?.paramUniqueId}
                    itemsSelected={
                      searchFormStepData?.userInputs?.selectedValues || []
                    }
                    columnFilteringUpdateMethod={(
                      controlObj,
                      searchOptionID,
                      eachSection,
                      eachRow,
                      selectedValues
                    ) => {
                      columnFilteringMethodSearchAndFilter(
                        controlObj,
                        searchOptionID,
                        eachSection,
                        eachRow,
                        selectedValues
                      );
                    }}
                    isFromSearch={true}
                    controlParams={{
                      controlObj: controlObj,
                    }}
                    templateDetails={templateDetails}
                    specialCharacters={specialCharacters}
                    hasDrodownUpdated={hasDrodownUpdated}
                    setHasDrodownUpdated={setHasDrodownUpdated}
                  />
                </>
              );
            } else {
              return (
                <>
                  <Label content={controlObj?.displayName} />
                  <MultiSelectReactDropdown
                    options={controlObj?.valueObject?.data}
                    showCheckbox={true}
                    selectedValues={searchFormStepData || []}
                    emptyRecordMsg={controlObj.EmptyMsg}
                    placeholder={controlObj.placeHolderText}
                    displayValue={"value"}
                    customCloseIcon={true}
                    onSelect={(selectedList) => {
                      controlsChangeHandler(selectedList, controlObj);
                    }}
                    onRemove={(selectedList) => {
                      controlsChangeHandler(selectedList, controlObj);
                    }}
                    name={controlObj?.controlID}
                    closeOnSelect={true}
                    showArrow={true}
                    hideSelectedList={false}
                    isDragDisabled={true}
                    defaultCheckedValue={""}
                    id={controlObj?.controlID}
                    isLibrarySearch={true}
                  />
                </>
              );
            }
          } else {
            return (
              <>
                <Label content={controlObj?.displayName} />
                <DropdownComponent
                  items={controlObj?.data}
                  fieldsName={controlObj?.fieldsName}
                  isCustomField={controlObj?.isCustomData}
                  onDropDownChange={(e) => {
                    controlsChangeHandler(e, controlObj);
                  }}
                  defaultCheckedValue={""}
                  placeholder={controlObj?.placeHolderText}
                  search={controlObj?.isSearchable}
                  value={searchFormStepData?.userInputs?.selectedValues || ""}
                  checkable={controlObj?.isCheckable}
                  id={controlObj?.paramsId}
                  disabled={false}
                  multiple={controlObj?.isMultiSelect}
                  initialLoad={true}
                  page={"LibrarySearchForm"}
                  isLibrarySearch={true}
                />
              </>
            );
          }
        default:
      }
    }
  };

  const createColumn = (
    key,
    name,
    fieldName,
    minWidth,
    maxWidth,
    isResizable,
    type = null
  ) => ({
    key,
    name,
    fieldName,
    ariaLabel: name,
    minWidth: window.innerWidth >= 900 ? minWidth : maxWidth,
    maxWidth: window.innerWidth >= 900 ? minWidth : maxWidth,
    isResizable: isResizable,
    isMultilineEnable: true,
    type,
  });
  // const onRenderSelectionIcons = (props) => {
  //   const isChecked = props?.userInputs?.selectedValues?.length > 0;
  //   return (
  //     <div className="columnContainer">
  //       <div className="dragIcon" tabIndex={0}>
  //         <RxDragHandleDots2 />
  //       </div>
  //       <div className="columnCheckbox">
  //         <CheckboxComponent
  //           label={""}
  //           checked={isChecked}
  //           onChange={(e) => handleCheckboxChange(props, e.target.checked)}
  //           index={`${props?.["stepId"]}_${props?.["key"]} `}
  //           disabled={false}
  //           className={"searchFormCheckbox"}
  //         />
  //       </div>
  //     </div>
  //   );
  // };

  const handleCheckboxChange = (
    e,
    value,
    fN,
    isCF,
    name,
    hE,
    eM,
    index,
    option
  ) => {
    let gridDataArr = [...gridData];
    let selReOrderIndexRow = gridDataArr?.filter((item) => item.uiConfigs.reOrderIndex == 1);

    let firstIsSelectedRowIndexNumber = gridDataArr?.findIndex(
      (item) => item.uiConfigs.reOrderIndex === 1 ?? -1
    );
    let remainingSelectedSteps = gridDataArr.filter((item) => item?.uiConfigs?.isSelected && item?.stepId > index + 1)
    let nextEligibleFirstStep = {};

    if (value?.checked === false) {
      if (gridDataArr[index].uiConfigs.reOrderIndex === 1) {
        if (remainingSelectedSteps.length > 0) {
          nextEligibleFirstStep = remainingSelectedSteps[0];
          gridDataArr[nextEligibleFirstStep.stepId - 1].uiConfigs.reOrderIndex = 1;
        }
        gridDataArr[index].uiConfigs = { isSelected: value?.checked, reOrderIndex: 0 };
      }
      else {
        gridDataArr[index].uiConfigs.isSelected = value?.checked;
      }
    }
    else {
      if (index < firstIsSelectedRowIndexNumber) {
        gridDataArr[index].uiConfigs = { isSelected: value?.checked, reOrderIndex: 1 };
        gridDataArr[firstIsSelectedRowIndexNumber].uiConfigs.reOrderIndex = gridDataArr[firstIsSelectedRowIndexNumber].stepId;
        if (gridDataArr[firstIsSelectedRowIndexNumber].userInputs.selectedOperators.length < 1)
          gridDataArr[firstIsSelectedRowIndexNumber].userInputs.selectedOperators = ["AND"]
      }
      else if (remainingSelectedSteps.length > 0) {
        gridDataArr[index].uiConfigs.isSelected = value?.checked;
      }
      else if (firstIsSelectedRowIndexNumber === -1) {
        gridDataArr[index].uiConfigs = { isSelected: value?.checked, reOrderIndex: 1 }

      }
      else if (firstIsSelectedRowIndexNumber < index) {
        gridDataArr[index].uiConfigs.isSelected = value?.checked;

      }
      else {
        gridDataArr[index].uiConfigs = { isSelected: value?.checked, reOrderIndex: 1 }
      }
    }
    setGridData(gridDataArr);
    setButtonContent(Constant.LANDING_PAGE_CONSTANTS.EXECUTE);
  };


  let staticColumns = [
    {
      key: "selectionIcons",
      name: "",
      fieldName: "selectionIcons",
      minWidth: 40,
      maxWidth: 40,
      isResizable: false,
      isMultilineEnable: false,
      type: "selectionIcon",
    },
    createColumn("steps", "Steps", "stepId", 50, 50, false),
    createColumn("type", "Type", "displayName", 100, 100, false, "hyperLink"),
    createColumn(
      "searchCriteria",
      "Search Criteria",
      "selectedValues",
      390,
      390,
      false,
      "description"
    ),
    createColumn("stepResults", "Step Results", "stepCount", 90, 90, false),
    createColumn(
      "searchResults",
      "Search Results",
      "casCadedStepCount",
      100,
      100,
      false
    ),
    {
      key: "actionIcons",
      name: "Action",
      fieldName: "actionIcons",
      minWidth: 60,
      maxWidth: 60,
      type: "deleteIcon",
    },
  ];

  const createStep = (id, searchParamData, value) => {
    const ExistingSelectedSteps = gridData.filter(item => item?.uiConfigs?.isSelected === true)
    return {
      stepId: id,
      searchParamsData: searchParamData,
      uiConfigs: {
        reOrderIndex: ExistingSelectedSteps?.length > 0 ? id : 1,
        isSelected: true,
      },
      userInputs: {
        selectedValues: value,
        selectedOperators: id === 1 ? [] : ["AND"],
      },
      customData: {
        stepCount: "",
        casCadedStepCount: "",
      },
    };
  };

  const controlsChangeHandler = (values, controlObj) => {
    let stepData = {};
    let value;
    if (controlObj?.isMultiSelect && values?.length < 1) {
      value = [];
    }
    else {
      value = getValueBasedOnFieldType(controlObj, values);
    }
    let searchFormControlDataObj = { ...searchFormStepData };
    if (Object.keys(searchFormControlDataObj)?.length > 0) {
      setSearchFormStepData({
        ...searchFormStepData,
        userInputs: { ...searchFormStepData.userInputs, selectedValues: value },
      });
    } else {
      stepData = createStep(gridData?.length + 1, controlObj, value);
      setSearchFormStepData(stepData);
    }
  };

  const updateStepData = (arr) => {
    setHasDrodownUpdated(true);
    let updatedStep = createStep(
      gridData?.length + 1,
      arr,
      arr?.selectedValueType == "array" ? [] : ""
    );
    setSearchFormStepData(() => {
      return updatedStep;
    });
  };

  const getValueBasedOnFieldType = (controlObj, value) => {
    switch (controlObj?.type) {
      case "text":
        return value.target.value;
      case "textarea":
        return value.target.value;
      case "datePicker":
        return customFormatter(new Date(value));
      case "fromToDatePicker":
        value =
          value?.length > 0 &&
          value.map((date) => {
            date = date ? customFormatter(new Date(date)) : null;
            return date;
          });
        return value;
      case "dropdown":
        if (controlObj?.isMultiSelect == true) {
          return value?.map((element) => element.name);
        } else {
          return value?.content;
        }
      default:
        console.log("No control found");
    }
  };

  const columnFilteringMethodSearchAndFilter = (
    controlObj,
    searchOptionID,
    eachSection,
    eachRow,
    selectedValues
  ) => {
    // if (selectedValues?.length !== 0) {
    controlsChangeHandler(selectedValues, controlObj);
    // }
  };
  const findValueByKey = (obj, keyToFind) => {
    if (obj.hasOwnProperty(keyToFind)) {
      return obj[keyToFind];
    }
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        const result = findValueByKey(obj[key], keyToFind);
        if (result !== undefined) {
          return result;
        }
      }
    }
    return undefined;
  };
  const execute = async () => {
    setIsGridLoading(true);
    showLayover();
    const formData = new FormData();
    let params = {
      isInternal: true,
      pageLength: Constant.INTERNAL_APPS_GENERAL_CONSTANTS.FIELD_LEVEL_PAGE_SIZE,
      pageNumber: 1,
      version: internalAppDetails?.version ?? 0,
      sortBy: {
        type: "",
        order: "",
      },
      filters: {
        fileDetail:
        {
          actualFileName: "librarySearchForm.json"
        },
        fields: ""
      },
    };
    if (executeData?.searchQuery)
      params["searchQuery"] = executeData?.searchQuery;
    let payload = {};
    if (internalAppDetails) {
      formData.append("RequestJson", JSON.stringify(params));
      formData.append("AppId", internalAppDetails.appID);
      formData.append("isInternalApp", internalAppDetails.isInternal);
      formData.append("TemplateId", templateDetails?.templateId?.toString() ?? "");
      let gridDataArrString = JSON.stringify([...gridData]);
      let gridDataArr = JSON.parse(gridDataArrString);
      let newGridDataArr = [];
      if (gridDataArr?.length > 0) {
        if (gridDataArr[0]["userInputs"]["selectedOperators"]?.length == 0)
          gridDataArr[0]["userInputs"]["selectedOperators"] = ["AND"];
        newGridDataArr = gridDataArr.map((item) => {
          if (item.userInputs.selectedOperators?.length > 0) {
            item.userInputs.selectedOperators = item.userInputs.selectedOperators.map((operator) => {
              return operator?.toUpperCase()
            });
          }
          if(item.searchParamsData.type?.toLowerCase() === Constant.LANDING_PAGE_CONSTANTS.DATEPICKER_TYPE?.toLowerCase())
            {
              item.userInputs.selectedValues = {
                from: item?.userInputs?.selectedValues[0] ?? "",
                to: item?.userInputs?.selectedValues[1] ?? ""
              }
            }
          return item;
        });
      }
      payload = {
        stepData: newGridDataArr?.filter((item) => item?.uiConfigs?.isSelected === true),
        booleanExpression: {
          expression: booleanExpression
        },
        customData: {
          stepsApplied: newGridDataArr?.filter((item) => item?.uiConfigs?.isSelected === true)?.length ?? 0,
          totalSearchResults: searchFormPayload?.customData?.totalSearchResults ?? 0
        },
        keywordFields: librarySearchForm?.keywordFields
      }
      const jsonStr = JSON.stringify(payload);
      const file = new Blob([jsonStr], { type: 'application/json' });
      formData.append("Attachments", file, 'librarySearchForm.json');
    }
    try {
      const powerKDBData = await postData(
        formData,
        APIEndPoints.GET_FILTERED_DATA_LIBRARY_META_DATA(
          internalAppDetails?.contentTypeId,
          userInfo?.teams?.companyId
        ),
        true
      );
      if (powerKDBData?.data?.[0]?.status == "failed") {
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: powerKDBData?.data?.[0]?.statusMessage ?? Constant.SOMETHING_WENTWRONG,
          type: "fail",
          isFailed: false,
        });
      }
      else {
        let searchResults = powerKDBData?.data?.[0]?.resultData?.filterDetails;
        if (searchResults?.length > 0) {
          setButtonContent(internalAppDetails?.contentTypeId ==  Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES ? Constant.LANDING_PAGE_CONSTANTS.VIEW_REPORTS : Constant.LANDING_PAGE_CONSTANTS.VIEW_SEARCH_RESULTS);
          let gridDataCopy = [...gridData];
          searchResults?.length > 0 && searchResults.map((item) => {
            let eachStep = gridDataCopy?.filter((gridItem) => gridItem?.stepId === item?.stepId)?.[0];
            if (eachStep) {
              eachStep["customData"]["stepCount"] = item?.count
              eachStep["customData"]["casCadedStepCount"] = item?.cascadeCount
            }
          });
          setGridData(gridDataCopy);
          setExecuteData(powerKDBData?.data?.[0]?.resultData);
          handleLibrarySearchFormJsonData({
            searchQuery: powerKDBData?.data?.[0]?.resultData?.searchQuery ?? "",
            totalRecordsCount: searchResults[searchResults.length - 1]?.cascadeCount ?? 0,
            powerKLibrarySearchGridData:gridDataCopy
          });
          searchResults?.length > 0 && setSearchFormPayload({ ...payload, customData: { ...payload.customData, totalSearchResults: searchResults[searchResults.length - 1]?.cascadeCount } });
          changedByExecute.current =true;
        }
      }
      setIsGridLoading(false);
      hideLayover();
    } catch (error) {
      console.error(error);
      setIsGridLoading(false);
      hideLayover();
      handleNotificationMsgsData({
        showMsgBar: true,
        started: false,
        completed: true,
        msg: error?.response?.data?.Errors ?? Constant.SOMETHING_WENTWRONG,
        type: "fail",
        isFailed: false,
      });
    }
  };
  const viewSearchResults = async (formData) => {
    handleLibrarySearchFormJsonData({
      appliedFilteredValues: {},
      searchQuery: executeData?.searchQuery,
      powerKLibrarySearchGridDataLastRow: executeData?.filterDetails?.length > 0 ? executeData?.filterDetails[executeData?.filterDetails.length - 1] : {}
    });
    if (internalAppDetails?.contentTypeId == Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES) {
      if (buttonContent === Constant.LANDING_PAGE_CONSTANTS.VIEW_REPORTS) {
        setOpenItems(['2']); 
        setIsAccordionDisabled(false);
      }
    }
    else{
      navigate("/apps/LibraryGrid", {
        state: {
          appDetails: internalAppDetails,
          privilagesPowerKApp: privilagesForPowerKApp,
          templateDetails: templateDetails
        },
      });
    }
  };
  const handleAccordionClick = (item) => {
    setOpenItems(openItems.includes(item) ? [] : [item]);
  };
  const _renderItemColumn = (item, index, column) => {
    const fieldContent = findValueByKey(item, column.fieldName);
    return renderColumns(fieldContent, column, item, index);
  };
  const handleCheckboxChangeForOperators = (e, column, rowData) => {
    let gridDataArrString = JSON.stringify([...gridData]);
    let gridDataArr = JSON.parse(gridDataArrString);
    let selRow = gridDataArr?.filter((item) => item.stepId == rowData.stepId);
    if (selRow?.length > 0) {
      let index = gridDataArr?.findIndex(
        (item) => item.stepId === rowData.stepId
      );
      gridDataArr[index].userInputs.selectedOperators = [column.fieldName];
      setGridData(gridDataArr);
    }
    setButtonContent(Constant.LANDING_PAGE_CONSTANTS.EXECUTE);
  };
  const renderColumns = (fieldContent, column, item, index) => {
    let parent = masterGroupsSorted.filter((item) => item.groupType == "parent")
      ? masterGroupsSorted.filter((fileItem) => {
          return (
            item.searchParamsData.groupId == fileItem.groupId &&
            fileItem.groupType == "parent"
          );
        })?.[0]
        ? true
        : false
      : true;
    switch (column.type) {
      case "checkbox":
        return (
          <CheckboxComponent
          label={""}
          checked={
            parent
              ? false
              : item?.userInputs?.selectedOperators?.[0]?.toLowerCase() ===
                column?.fieldName?.toLowerCase()
              ? true
              : false
          }
          onChange={(e) => {
            handleCheckboxChangeForOperators(e, column, item);
          }}
          index={item?.stepId - 1}
          disabled={
            parent ||
            item?.uiConfigs?.isSelected === false ||
            (item?.uiConfigs?.reOrderIndex === 1 && column?.fieldName?.toLowerCase() !== "not")
              ? true
              : false
          }
          className={"searchFormCheckbox"}
        />
        );
      case "Date":
        return <span>{utils.convertDate(fieldContent)}</span>;
      case "LetterCase":
        return <span>{fieldContent.toLowerCase()}</span>;
      case "hyperLink":
        return (
          <>
          {parent ? (
            <b className="searchParamTypeForFootnote">
              {item?.searchParamsData?.groupName}
            </b>
          ) : (
            <p
              onClick={(event) => {
                event.preventDefault();
                if (item?.uiConfigs?.isSelected)
                  openPanel(item, true);
              }}
              className="searchParamType"
            >
              {item?.searchParamsData?.groupName}
            </p>
          )}
        </>
        );
      case "description":
        return (
          <>
            <span className="descriptionParam">{`${item?.searchParamsData?.displayName}: `}</span>
            <span>
              {item?.searchParamsData?.type === Constant.LANDING_PAGE_CONSTANTS.DATEPICKER_TYPE
                ? `${fieldContent[0]} to ${fieldContent[1]
                }`
                :
                item?.searchParamsData?.isMultiSelect ? fieldContent.join(" | ")
                  :
                  fieldContent}
            </span>
          </>
        );
      case "deleteIcon":
        return (
          <div className="paramActionIcons">
            <div>
              <RiDeleteBin6Line
                size={15}
                title="Delete"
                className="paramActionDelete"
                onClick={(e) => {
                  setCurrentItemToBeDeleted(item);
                }

                }
              />
            </div>
          </div>
        );
      case "selectionIcon":
        const isChecked = item?.uiConfigs?.isSelected;
        return (
          <div className="columnContainer">
            <div className="dragIcon" tabIndex={0}>
              <RxDragHandleDots2 />
            </div>
            <div className="columnCheckbox">
              <CheckboxComponent
                label={""}
                checked={isChecked}
                onChange={handleCheckboxChange}
                index={item?.stepId - 1}
                disabled={false}
                className={"searchFormCheckbox"}
              />
            </div>
          </div>
        );
      default:
        return (
          <span>
            <DescriptionCell longValues={fieldContent} />
          </span>
        );
    }
  };

  const textBoxChangeAddCharacter = (userTypedString) => {
    let afterText = booleanExpression.substring(selectionPoints.current.startingPoint).replace(/ /g, '');
    let beforeText = booleanExpression.substring(0, selectionPoints.current.startingPoint).replace(/ /g, '');
    switch (userTypedString) {
      case '(':
        if (afterText.toLowerCase().startsWith('not')) {
          return true
        }
        else if (/^\d/.test(afterText) && !/\d$/.test(beforeText) && !beforeText.toLowerCase().endsWith('not')) {
          return true
        }
        else if (beforeText.toLowerCase().endsWith('(') || afterText.toLowerCase().startsWith('(')) {
          return true
        }
        else {
          setValidationMessage(Constant.LANDING_PAGE_CONSTANTS.OPEN_PARENTHESIS_POSITION);
          return false
        }
        break;
      case ')':
        if (/\d$/.test(beforeText) && !/^\d/.test(afterText)) {
          return true
        }
        else if (beforeText.toLowerCase().endsWith(')') || afterText.toLowerCase().startsWith(')')) {
          return true
        }
        else {
          setValidationMessage(Constant.LANDING_PAGE_CONSTANTS.CLOSE_PARENTHESIS_POSITION);
          return false

        }
        break;
      case ' ':
        if (/\d$/.test(beforeText) || /^\d/.test(afterText)) {
          if (/\d$/.test(beforeText) && !/^\d/.test(afterText)) {
            return true
          }
          else if (!/\d$/.test(beforeText) && /^\d/.test(afterText)) {
            return true
          }
          else {
            setValidationMessage(Constant.LANDING_PAGE_CONSTANTS.BLANK_SPACE_POSITION);
            return false
          }
        }
        else if (beforeText.toLowerCase().endsWith(')') || afterText.toLowerCase().startsWith(')') ||
          beforeText.toLowerCase().endsWith('(') || afterText.toLowerCase().startsWith('(')) {
          return true
        }
        else {
          setValidationMessage(Constant.LANDING_PAGE_CONSTANTS.BLANK_SPACE_POSITION);
          return false
        }
      default:
        setValidationMessage("");
        return false
    }
  }
  const textBoxChangeRemoveCharacter = (userInputType) => {
    const startEndPoints = selectionPoints.current
    const stPoint = Math.min(startEndPoints?.startingPoint, startEndPoints?.endingPoint)
    const endPoint = Math.max(startEndPoints?.startingPoint, startEndPoints?.endingPoint)
    let diffStartAndEndPoint = endPoint - stPoint
    if (diffStartAndEndPoint > 0) {
      const subStringToBeRemoved = booleanExpression.substring(stPoint, endPoint)
      if (/[A-Za-z]/.test(subStringToBeRemoved) || /\d/.test(subStringToBeRemoved)) {
        setValidationMessage(Constant.LANDING_PAGE_CONSTANTS.USE_ABOVE_SECTION);
        return false
      }
      else {
        return true
      }

    }
    else {
      const forBackSpace = booleanExpression.substring(0, stPoint);
      const forDelete = booleanExpression.substring(stPoint)
      switch (userInputType) {
        case "deletecontentbackward":
          if (/\d$/.test(forBackSpace) || /[A-Za-z]$/.test(forBackSpace)) {
            setValidationMessage(Constant.LANDING_PAGE_CONSTANTS.USE_ABOVE_SECTION);
            return false
          }
          else {
            return true
          }
          break;
        case "deletecontentforward":
          if (/^[A-Za-z]/.test(forDelete) || /^\d/.test(forDelete)) {
            setValidationMessage(Constant.LANDING_PAGE_CONSTANTS.USE_ABOVE_SECTION);
            return false
          }
          else {
            return true
          }
          break;

        default:
          setValidationMessage("");
          return false
      }
    }
  }
  const textBoxChange = (event, data) => {
    let userTypedString = event?.nativeEvent?.data !== null ? event?.nativeEvent?.data : ""
    let userInputType = event?.nativeEvent?.inputType !== null ? event?.nativeEvent?.inputType : ""
    let inputSuccess = false;
    if (userTypedString === "(" || userTypedString === ")" || userTypedString === " ") {
      inputSuccess = textBoxChangeAddCharacter(userTypedString)
    }
    else if (userInputType.toLowerCase() === "deletecontentbackward" ||
      userInputType.toLowerCase() === "deletecontentforward") {
      inputSuccess = textBoxChangeRemoveCharacter(userInputType.toLowerCase())
    }
    else {
      setValidationMessage(Constant.LANDING_PAGE_CONSTANTS.USE_ABOVE_SECTION);
    }
    if (inputSuccess) {
      setValidationMessage("");
      setBooleanExpression(data?.value);
    }
    else {
      setTimeout(() => {
        inputRef.current.setSelectionRange(selectionPoints.current.startingPoint, selectionPoints.current.startingPoint);
      }, 0);
    }
  }

  const onChangeSection = (selectedGroupName) => {
    const selectedParam = masterGroupsSorted.find(
      (param) => param?.groupName === selectedGroupName
    );
    if (selectedParam) {
      openPanel(selectedParam);
    }
  };
  const insertBeforeItem = (item) => {
    if (internalAppDetails?.contentTypeId === Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES) {
      const draggedItems = selection.isIndexSelected(draggedIndex) ? selection.getSelection()
        : [(draggedItem !== null && draggedItem !== undefined) ? draggedItem : -1];
      const insertIndex = gridData.indexOf(item);
      if (draggedItems.some(draggedItem => gridData.indexOf(draggedItem) === 0)) {
        return;
      }
      if (insertIndex === 0 && draggedItems.some(draggedItem => gridData.indexOf(draggedItem) > 0)) {
        return;
      }
    }
    const draggedItems = selection.isIndexSelected(draggedIndex) ? selection.getSelection()
      : [(draggedItem !== null && draggedItem !== undefined) ? draggedItem : -1];
    const insertIndex = gridData.indexOf(item);
    const filterGrid = gridData.filter(
      (data) => draggedItems.indexOf(data) === -1
    );
    filterGrid.splice(insertIndex, 0, ...draggedItems);
    const firstSelectedIndex = filterGrid.filter((item) => item?.uiConfigs?.isSelected)?.[0] ?? {};
    const modifiedIndexOfMovedStep = filterGrid.findIndex(
      (item) => item?.stepId === firstSelectedIndex?.stepId
    );
    filterGrid[modifiedIndexOfMovedStep].uiConfigs.reOrderIndex = -1;
    const updatedGrid = filterGrid.map((data, index) => {
      const newData = { ...data, stepId: index + 1 };
      if (index === 0) {
        if (newData.userInputs.selectedOperators.length > 0 && newData.userInputs.selectedOperators[0]?.toLowerCase() === "not") {
          newData.userInputs.selectedOperators = firstSelectedIndex?.userInputs?.selectedOperators;
        }
        else {
          newData.userInputs.selectedOperators = [];
        }
        if (newData.uiConfigs.reOrderIndex === -1) {
          newData.uiConfigs.reOrderIndex = 1;
        }
        else {
          newData.uiConfigs.reOrderIndex = 0;
        }
      }
      else {
        if (newData.uiConfigs.reOrderIndex === -1) {
          newData.uiConfigs.reOrderIndex = 1;
        }
        else {
          newData.uiConfigs.reOrderIndex = index + 1;
        }
      }
      if (index > 0 && newData?.userInputs?.selectedOperators?.length < 1) {
        newData.userInputs.selectedOperators = ["AND"];
      }
      return newData;
    });
    const initialIndex = updatedGrid.indexOf(draggedItems[0]);
    if (initialIndex !== insertIndex) {
      setButtonContent(Constant.LANDING_PAGE_CONSTANTS.EXECUTE);
    }
    setGridData(updatedGrid);
  };

  const onDragStart = (gridData, itemIndex, selectedItems, event, columnIndex) => {
    setDraggedItem(gridData);
    setDraggedIndex(
      (itemIndex !== null && itemIndex !== undefined) ? itemIndex : -1
    );
  };
  const onDragEnd = (gridData, event) => {
    setDraggedItem(undefined);
    setDraggedIndex(-1);
  };

  const onDrop = (gridData, event) => {
    setDraggedItem(gridData);
    if (draggedItem) {
      insertBeforeItem(gridData);
    }
  };
  const dragDropEventsCalls = {
    canDrag: () => true,
    canDrop: () => true,
    onDragStart,
    onDragEnd,
    onDrop,
  }
  const commonGridFooter = () =>{
    return (
      <>
      <DetailsList
        items={gridData}
                    columns={columnsForSearchGrid}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.multiple}
                    isSelectedOnFocus={false}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                    onRenderItemColumn={_renderItemColumn}
                    draggable={true}
                    dragDropEvents={dragDropEventsCalls}
                    selectionIndex={0}
                    checkboxVisibility={2}
                    onActiveItemChanged={() => {}}
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderDetailsHeader={(headerProps, defaultRender) => {
                      if (window.innerWidth >= 900) {
                        return defaultRender({
                          ...headerProps,
                          onRenderColumnHeaderTooltip: (tooltipHostProps) => {
                            return <TooltipHost {...tooltipHostProps} />;
                          },
                        });
                      } else {
                        return defaultRender({
                          ...headerProps,
                          onRenderColumnHeaderTooltip: (tooltipHostProps) => {
                            return <TooltipHost {...tooltipHostProps} />;
                          },
                          styles: {
                            root: {
                              selectors: {
                                ".ms-DetailsHeader-cell": {
                                  whiteSpace: "normal",
                                  textOverflow: "clip",
                                  lineHeight: "normal",
                                },
                                ".ms-DetailsHeader-cellTitle": {
                                  height: "100%",
                                  alignItems: "center",
                                },
                              },
                            },
                          },
                        });
                      }
                    }}
        className={"searchPageDetailsList"}
      />
      {!gridData?.length > 0 && (
                    <div className="letGetStartContainer">
                      <div className="letGetStartLabel">
                        Let's get started!
                      </div>
                      <div className="letGetStartIcon">
                        <img
                          src={
                            require("../../../assets/SlideSearch.svg").default
                          }
                          alt="Search"
                          className="letGetStartImage"
                        />
                      </div>
                      <div className="letGetStartSubText">
                        Choose a search parameter to begin.
                      </div>
                    </div>
            )}
        {gridData?.length > 0 && (
                <div className="searchPageFooter">
                <div className="booleanParametersContainer">
                  <div className="booleanParametersFieldsLeft">
                    <div className="booleanParametersFieldwithIcon">
                      <Label
                        content={Constant.LANDING_PAGE_CONSTANTS.BOOLEAN_PARAMETERS}
                        title={Constant.LANDING_PAGE_CONSTANTS.BOOLEAN_PARAMETERS}
                        className="booleanLabel"
                      />
                      <TooltipHost
                        content={Constant.LANDING_PAGE_CONSTANTS.BOOLEAN_PARAMETERSH_SEARCH}
                      >
                        <IoInformationCircle />
                      </TooltipHost>
                    </div>
                      <Input
                        onChange={textBoxChange}
                        ref={inputRef}
                        disabled={false}
                        onKeyDown={(event) => {
                          if (
                            event?.key === "Backspace" ||
                            event?.key === "Delete" ||
                            event?.key === "(" ||
                            event?.key === ")" ||
                            event?.key === " "
                          ) {
                            selectionPoints.current = {
                              startingPoint: event?.target?.selectionStart,
                              endingPoint: event?.target?.selectionEnd
                            }
                          }
                        }}
                        value={booleanExpression}
                        className="booleanExpressionTextBox"
                      />
                      <p className={validationMessage?.length < 1 ? "booleanParameterNote" : "booleanParameterErrorNote"}>
                      {validationMessage?.length > 1 ?
                        validationMessage :
                        `${Constant.LANDING_PAGE_CONSTANTS.BOOLEAN_PARAM_CLICK_EXECUTE_MESSAGE}
                         ${Constant.LANDING_PAGE_CONSTANTS.BOOLEAN_PARAM_CLICK_SEARCH_RESULTS_MESSAGE}
                        `
                      }
                    </p>
                    </div>
                    <div className="booleanParametersFieldsRight">
                    <div className="stepsAppliedFeilds">
                      <Label
                        className="booleanLabel"
                        content={Constant.LANDING_PAGE_CONSTANTS.STEPS_APPLIED}
                      />
                      <TextBox
                        onChange={controlsChangeHandler}
                        name={Constant.LANDING_PAGE_CONSTANTS.STEPS_APPLIED}
                        disabled={true}
                        value={gridData?.filter((item) => item?.uiConfigs?.isSelected === true)?.length.toString() ?? "0"}
                        id={Constant.LANDING_PAGE_CONSTANTS.STEPS_APPLIED}
                        isLibrarySearch={true}
                      />
                    </div>
                      <div className="totalSearchResults">
                      <Label
                        className="booleanLabel"
                        content={
                          Constant.LANDING_PAGE_CONSTANTS.TOTAL_SEARCH_RESULTS
                        }
                      />
                      <TextBox
                        onChange={controlsChangeHandler}
                        name={
                          Constant.LANDING_PAGE_CONSTANTS.TOTAL_SEARCH_RESULTS
                        }
                        id={Constant.LANDING_PAGE_CONSTANTS.TOTAL_SEARCH_RESULTS}
                        disabled={true}
                        value={searchFormPayload.customData.totalSearchResults === 0 ? "0" : searchFormPayload.customData.totalSearchResults}
                        isLibrarySearch={true}
                      />
                      </div>
                    </div>
                  </div>
                  <div className="searchPageFooterButtons">
                  <Button
                    className="excuteButton"
                    size="small"
                    onClick={handleExecuteClick}
                    content={buttonContent}
                    disabled={false}
                  />
                </div>
              </div>
        )}
    </>
     );
  }

  return (
    <>
      {isLoading ? (
        <ReusableLoader isOverlay={true} isAppBody={true} />
      ) : (
        <>
        {
            isGridLoading &&
            <ReusableLoader isOverlay={true} isAppBody={true} />
          }
        <div
          className={`searchPageContainer ${
            gridData?.length > 0 ? "searchPageContainerHeight" : ""
          }`}
        >
          <div className="searchFiltersContainer">
            <div className="searchFiltersLabel">
              {Constant.LANDING_PAGE_CONSTANTS.SEARCH_PARAMETERS}:
            </div>
            <div className="searchFilterAllPills">
              {masterGroupsSorted?.length > 0 &&
                masterGroupsSorted?.map((param) => (
                  <Button
                    className="searchFilterPills"
                    key={param?.groupId}
                    onClick={() => {
                      openPanel(param);
                    }}
                    size="small"
                    content={param?.groupName}
                    disabled={isButtonDisabled(param)}
                  />
                ))}
            </div>
          </div>
          {/* search parameter dropdown for mobile screens */}
          {checkIfDeviceIsMob() && (
            <div className="seachParametersMoblile">
              <DropdownComponent
                items={masterGroupsSorted?.map((param) => param?.groupName)}
                onDropDownChange={(e) => {
                  setSelectedSection(e);
                  onChangeSection(e);
                }}
                multiple={false}
                placeholder={"Select parameters"}
                initialLoad={false}
                name={"Section"}
                className={"sectionDropdown"}
                value={selectedSection}
                renderColumns={renderColumns}
              />
            </div>
          )}
          <div className="footnotesSearchPageGrid">
            {shouldShowAccordion && (
              <Accordion collapsible openItems={openItems} >
                <AccordionItem value="1">
                  <AccordionHeader className="footnotesAccordionHeaders" onClick={() => handleAccordionClick('1')}>
                    Search Steps
                  </AccordionHeader>
                  {openItems.includes('1') && (
                  <AccordionPanel className="footnotesAccordionBody">
                    <div
                      className={`clearsSelection ${
                        !gridData.length > 0 ? "disabled" : ""
                      }`}
                    >
                      <span onClick={handleClearSelection}>
                      <TbXboxX />
                      <span className="clearsSelectionText">
                        {Constant.INTERNALAPP_CONSTANTS.CLEAR_SELECTION}
                      </span>
                      </span>
                    </div>
                    {commonGridFooter()}
                  </AccordionPanel>
                    )}
                </AccordionItem>
                <AccordionItem value="2" className="footnotesAccordionHeaders" disabled={isAccordionDisabled} onClick={() => handleAccordionClick('2')}>
                  <AccordionHeader>Visualization</AccordionHeader>
                  {openItems.includes('2') && (
                  <AccordionPanel className="footnotesAccordionBody">
                  <div className="reportDetailsViewPage">
                    <PowerBiReport
                      item={pbReportConfig}
                      closeLoader={closeLoader}
                    />
                  </div>
                  </AccordionPanel>
                  )}
                </AccordionItem>
              </Accordion>
            )}
          </div>

            {!shouldShowAccordion && (
          <div ref={scrollDiv}>
            <div
              className={`${!gridData?.length > 0 ? "detailslist_wrapper initialGridHeight" : "detailslist_wrapper "
                }`}
            >
        {commonGridFooter()}
                </div>
          </div>
            )}
          <PanelView
                closePanel={() => {
                  closePanel();
                }}
                panelOpen={panelOpen}
                param={currentParam}
                renderControl={(item) => renderControl(item)}
                addGridData={(isEdit) => addGridData(isEdit)}
                updateStepData={updateStepData}
                searchFormStepData={searchFormStepData}
              />
             <DialogBox
              cancelButton={Constant.INTERNALAPP_CONSTANTS.BTN_CANCEL}
              confirmButton={
                Constant.INTERNALAPP_CONSTANTS.BTN_DELETE
              }
              onCancelMethod={() => { setCurrentItemToBeDeleted({}) }}
              onConfirmMethod={() => { deleteStep(currentItemToBeDeleted) }}
              openDialog={Object.keys(currentItemToBeDeleted)?.length > 0}
              content={
                <div className="deleteStepMessage">
                  <p>
                    {Constant.INTERNALAPP_CONSTANTS.DELETE_SEARCH_STEP_MSG}
                  </p>
                </div>
              }
              headerName={`${Constant.INTERNALAPP_CONSTANTS.DELETE_SEARCH_STEP_HEADER} ${currentItemToBeDeleted?.stepId}`}
              className={`dialogBoxSearchStepDelete`}
            />
          </div>
        </>
      )}
    </>
  );
}

const DescriptionCell = ({ longValues }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const textToDisplay = useMemo(() => {
    return isExpanded ? longValues : longValues?.slice(0, 100);
  }, [isExpanded, longValues]);
  return (
    <div onClick={(event) => event.stopPropagation()}>
      <span className={isExpanded ? "expand" : "truncate"}>
        {textToDisplay}
      </span>
    </div>
  );
};
export default LandingPage;