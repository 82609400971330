import React, { useContext } from "react";
import { BsXLg } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import { BreadCrumbContext } from "../../context/breadcrumbcontext/BreadCrumbContext";
import { getUrlParams } from "../../utility/utility";
import "./backButton.css";
import Constant from "../../utility/constants";
import { app, pages } from "@microsoft/teams-js";

export const BackButton = (props) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { children } = props;
  const { ResetBreadCrumb3LevelTitle } = useContext(BreadCrumbContext);
  const goBack = () => {
    ResetBreadCrumb3LevelTitle();
    let fromManageTeam = (window.location.pathname === "/yourchannel") || (window.location.pathname === "/channelManagement") || (window.location.pathname === "/requestChannel") || (window.location.pathname === "/channelSettings") || (window.location.pathname === "/pendingApprovals") ? "&fromManageTeam=true" : "";
    if (getUrlParams("BB") == "true" || getUrlParams("BB") == true) {
      // app.getContext().then((item) => {
      //   let pageState = {
      //     from: "SearchTabSidebar",
      //     to: "/",
      //   };
      //   let parms = {
      //     // @ts-ignore
      //     tabName: "Home",
      //     appId: Constant.GetUserAppId(item?.user?.userPrincipalName),
      //     pageId: Constant.HomeTab,
      //     subPageId: pageState,
      //   };
      //   pages.navigateToApp(parms);
      // });
      navigate(props?.pathToBeRedirected + `?loadFromCache=true&backButton=true`);
    }
    else if (props.cameFromSearchTab) {
      //write search tab back button
    } else {
      if (window.history.length < 3) {
        if (props?.pathToBeRedirected) {
          navigate(props?.pathToBeRedirected);
        } else {
          window.location.assign("/");
          return;
        }
      }
      let a = getUrlParams("prevPath");
      getUrlParams("prevPath")
        ? navigate(
          getUrlParams("prevPath") +
          `?loadFromCache=true&backButton=${props?.isCloseClicked}${fromManageTeam}`
        )
        : navigate(props?.pathToBeRedirected + `?loadFromCache=true&backButton=true`);
    }
  };

  return (
    <div
      className={`${props?.className != undefined ? props?.className : "close-btn"
        }`}
    >
      {children}
      <BsXLg
        tabIndex={0}
        title="Close"
        aria-label="Close"
        role="button"
        onClick={props?.allowRedirection ? goBack : undefined}
        className={`close-btn-icon ${props.allowRedirection ? "" : "actionIcon-disable"}`}
        onKeyDown={(e) => { if (e.key === "Enter" && props?.allowRedirection) { goBack(); } }}
      />
    </div>
  );
};
