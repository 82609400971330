import { ISignalHandler } from "./ISignalHandler";

export class SignalHandler implements ISignalHandler {
  private connection: signalR.HubConnection;

  constructor(conn: signalR.HubConnection) {
    this.connection = conn;
  }

  public static offAll(conn: signalR.HubConnection): void {
    conn.off("DataLibraryUpdated");
    conn.off("ReportLibraryUpdated");
    conn.off("PinLibraryUpdated");
    //conn.off("DuplicateFileUpdated");
  }

  public onDataLibraryUpdated(action: () => void): void {
    this.connection.on("DataLibraryUpdated", () => {
      action();
    });
  }
  public onReportLibraryUpdated(action: () => void): void {
    this.connection.on("ReportLibraryUpdated", () => {
      action();
    });
  }
  public onPinLibraryUpdated(action: () => void): void {
    this.connection.on("PinLibraryUpdated", () => {
      action();
    });
  }
  public onNotificationSent(
    action: (messageType: string, header: string) => void
  ): void {
    this.connection.on("NotificationSent", (messageType, header) => {
      action(messageType, header);
    });
  }
  public onDuplicateFileUpdated(
    action: (dataFileId: number) => void
  ): void {
    this.connection.on("DuplicateFileUpdated", (dataFileId) => {
      action(dataFileId);
    });
  }
}
