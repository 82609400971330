import React from "react";
import "./pageNotFound.css";

function refreshPage() {
  window.location.reload(false);
}

function PageNotFound() {
  return (
    <div className="pagenotfound_container">
      <img
        src={require("../../assets/pagenotfoundturtle.png")}
        alt=""
      />
      <p className="pagenotfound_containerHeader">Something went wrong</p>
      <p className="pagenotfound_containerSubtext">
        Your content can't be shown right now. Try refreshing this page or start
        over if that
        <br />
        doesn't do the trick.
      </p>
      <div>
        <button className="button_pagenotfound" onClick={refreshPage}>
          Refresh
        </button>
      </div>
    </div>
  );
}

export default PageNotFound;
