//comment.jsx
import React, { useState, useEffect } from "react";
import "./comments.css";
import _ from "lodash";
import {
  ImageUtil,
  getUrlParams,
  base64ToUtf8,
  sanitizeHTML,
} from "../../../utility/utility";
import ShowMoreLessText from "../../../commonui/showmorelesstext/showMoreLessText";
import { UseUserInfoContext } from "../../../context/usercontext/userContext";
import { Loader } from "@fluentui/react-northstar";
import { BsFillTrash3Fill } from "react-icons/bs";
import { getDateFromUTC } from "../../../utility/timefunctions";
import { HiOutlineTrash, HiOutlinePencil } from "react-icons/hi";
import TextEditor from "../texteditor/textEditor";
import ReactionPill from "../reactionPill/reactionPill";
import CustomizedToolTip from "../../../commonui/tooltip/toolTip";
import emojisList from "../../../data/emojislist/emojislist.json";
import { postData, deleteDataByPayload } from "../../../services/customApis";
import APIEndPoints from "../../../utility/apiendpoints";
import Constant from "../../../utility/constants";
import { UseToasterNotificationsContext } from "../../../context/toasternotificationscontext/toasterNotificationsContext";
import { useMsal } from "@azure/msal-react";

const Comment = (props) => {
  const emojiHexCodes = emojisList.map((item) => item.emojiUnicode);
  const { item, config, contentTypeId, isGlobal } = props;
  const [loader, setLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const { userInfo } = UseUserInfoContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const [emojiSet, setEmojiSet] = useState([]);
  const [hoveredEmoji, setHoveredEmoji] = useState(null);
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  let pressTimer;
  const { instance } = useMsal();

  const reactToReactions = async (_emoji) => {
    let _emojiTitle = getEmojiTitle(_emoji);
    const payload = {
      pinMetaDataCommentsId: item?.pinMetaDataCommentsId,
      emojiTitle: _emojiTitle,
      emojiUnicode: _emoji,
    };

    await postData(
      payload,
      APIEndPoints.CREATE_UPDATE_BB_COMMENT_REACTION(
        contentTypeId,
        isGlobal == Constant.GLOBAL ? true : false,
        userInfo?.teams?.companyId
      )
    )
      .then((res) => {
        if (res?.status === 200) {
          setEmojiSet((prev) => {
            let foundEmoji = false;
            const newState = prev.map((emojiObj) => {
              const index = _.findIndex(emojiObj.userDetails, {
                userEmail: userInfo.email,
              });

              if (index !== -1) {
                emojiObj.userDetails.splice(index, 1);
                emojiObj.count -= 1;
              }
              if (emojiObj.emojiUnicode === _emoji) {
                foundEmoji = true;
                emojiObj.userDetails.push({
                  userId: userInfo.id,
                  userName:
                    userInfo.displayName !== "" &&
                    userInfo.displayName !== undefined
                      ? userInfo.displayName
                      : userInfo.email,
                  userEmail: userInfo.email,
                  pinMetaDataCommentsReactionId:
                    res?.data?.pinMetaDataCommentsReactionsId,
                });
                emojiObj.count += 1;
              }
              return emojiObj;
            });

            if (!foundEmoji) {
              newState.push({
                emojiTitle: _emojiTitle,
                emojiUnicode: _emoji,
                count: 1,
                userDetails: [
                  {
                    userId: userInfo.id,
                    userName:
                      userInfo.displayName !== "" &&
                      userInfo.displayName !== undefined
                        ? userInfo.displayName
                        : userInfo.email,
                    userEmail: userInfo.email,
                    pinMetaDataCommentsReactionId:
                      res?.data?.pinMetaDataCommentsReactionsId,
                  },
                ],
              });
            }

            newState.sort((a, b) => b.count - a.count);
            return newState;
          });
        }
      })
      .catch((err) => {
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors,
          type: "fail",
          isFailed: false,
        });
      });
  };

  useEffect(() => {
    getUrlParams("backButton") ? getDataFromCache() : getDataFromAPI();
  }, []);

  const getDataFromCache = () => {
    let dataItem = JSON.parse(
      localStorage.getItem("reactions" + item?.pinMetaDataCommentsId)
    );

    if (!dataItem) {
      //if data is not present in cache, we are calling api
      getDataFromAPI();
      return;
    }
    setEmojiSet(dataItem?.emojiSet);
    localStorage.removeItem("reactions" + item?.pinMetaDataCommentsId);
  };

  const getDataFromAPI = () => {
    if (item?.reactions !== null && item?.reactions !== undefined) {
      setEmojiSet(item?.reactions.sort((a, b) => b.count - a.count));
    }
    localStorage.removeItem("reactions" + item?.pinMetaDataCommentsId);
  };

  useEffect(() => {
    return () => {
      if (
        instance?.getActiveAccount() &&
        instance?.getAllAccounts()?.length > 0
      ) {
        setLocalStorage();
      }
    };
  }, [emojiSet]);

  const setLocalStorage = () => {
    emojiSet.length > 0 &&
      localStorage.setItem(
        "reactions" + item?.pinMetaDataCommentsId,
        JSON.stringify({
          emojiSet: emojiSet,
        })
      );
  };

  const handleReactionClick = (reaction, ignoreMobile = false) => {
    if (!isMobile || ignoreMobile) {
      if (userReactionExist(reaction.emojiUnicode)) {
        unreactToReactions(reaction);
      } else {
        reactToReactions(reaction.emojiUnicode);
      }
    }
  };

  const handleMobileTouchStart = (reaction) => {
    if (isMobile) {
      pressTimer = setTimeout(() => {
        handleReactionClick(reaction, true);
        pressTimer = null;
      }, 500);
    }
  };

  const handleMobileTouchEnd = (reaction) => {
    if (isMobile && pressTimer) {
      clearTimeout(pressTimer);
      pressTimer = null;
    }
  };

  const userReactionExist = (_emoji) => {
    return emojiSet.some(
      (emoji) =>
        emoji.emojiUnicode === _emoji &&
        emoji.userDetails.some((user) => user.userEmail === userInfo.email)
    );
  };

  const unreactToReactions = async (reaction) => {
    let reactionId = reaction.userDetails.reduce((acc, user) => {
      if (user.userEmail === userInfo.email) {
        return user.pinMetaDataCommentsReactionId;
      }
      return acc;
    }, 0);

    await deleteDataByPayload(
      APIEndPoints.DELETE_BB_COMMENT_REACTION(
        contentTypeId,
        isGlobal == Constant.GLOBAL ? true : false,
        userInfo?.teams?.companyId,
        reactionId
      )
    )
      .then((res) => {
        if (res?.status === 200) {
          setEmojiSet((prev) => {
            const newState = prev
              .map((emojiObj) => {
                if (emojiObj.emojiUnicode === reaction.emojiUnicode) {
                  const userIndex = emojiObj.userDetails.findIndex(
                    (user) => user.userEmail === userInfo.email
                  );
                  if (userIndex !== -1) {
                    // If user has reacted with this emoji before, remove their reaction
                    emojiObj.userDetails.splice(userIndex, 1);
                    emojiObj.count -= 1;
                  }
                }
                return emojiObj;
              })
              .filter((emojiObj) => emojiObj.count > 0); // Remove emojis with no reactions

            newState.sort((a, b) => b.count - a.count);
            return newState;
          });
        }
      })
      .catch((err) => {
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors,
          type: "fail",
          isFailed: false,
        });
      });
  };

  const handleReactionMouseOver = (reaction) => {
    setHoveredEmoji(reaction.emojiUnicode);
  };

  const getEmojiTitle = (emojiHexCode) => {
    return (
      _.find(emojisList, {
        emojiUnicode: emojiHexCode,
      })?.emojiTitle || ""
    );
  };

  const toggleDelete = (isDeleted) => {
    setLoader(true);
    props.deleteUndoComment(item, isDeleted).finally((val) => {
      setLoader(false);
    });
  };

  const changeEditLoader = (val) => {
    setEditLoader(val);
  };

  const onChangeToggleEdit = () => {
    setToggleEdit((prev) => !prev);
  };

  return (
    <>
      {toggleEdit ? (
        <TextEditor
          edit={true}
          onChangeToggleEdit={onChangeToggleEdit}
          htmlString={base64ToUtf8(item?.comment)}
          changeEditLoader={changeEditLoader}
          item={item}
          {...props}
        />
      ) : (
        <>
          {editLoader ? (
            <div className="comments-loader">
              <Loader />{" "}
            </div>
          ) : (
            <div
              className={`comment-box ${
                userInfo?.email?.toLowerCase() ===
                item?.createdByEmail?.toLowerCase()
                  ? "comment__mycomment"
                  : ""
              }`}
            >
              {item?.isDeleted ? (
                <div className="comment-deleted">
                  <BsFillTrash3Fill className="comment-delete_icon" />
                  <p className="comment-delete_msg">
                    This message has been deleted.{" "}
                  </p>{" "}
                  <p
                    onClick={loader ? undefined : () => toggleDelete(false)}
                    className="comment-undo_delete"
                  >
                    {loader ? <Loader className="undo-reply-loader" /> : "Undo"}
                  </p>
                </div>
              ) : (
                <div className="comment">
                  <div className="comment-author__avatar">
                    <img
                      className="comment-author__avatar_img"
                      src={ImageUtil(item?.createdByName)}
                    />
                  </div>
                  <div className="comment-body">
                    <div className="comment-author__details">
                      <div className="comment-author__name">
                        {item?.createdByName}
                      </div>
                      <div className="comment-datetime">
                        {getDateFromUTC(item?.updatedOn)}
                      </div>
                    </div>
                    <div className="comment-body__text">
                      {config?.reactReplies?.reactRepliesDisplay ? (
                        <CustomizedToolTip
                          tabIndex={0}
                          normalTooltip={true}
                          position="above"
                          pointing={false}
                          resetShowAll={() => setShowAll(false)}
                          content={[
                            <div className="emoji-menu-reply">
                              <ul className="emoji-list">
                                {(showAll
                                  ? emojiHexCodes
                                  : emojiHexCodes.slice(0, 4)
                                ).map((emojiHexCode) => {
                                  const emoji = emojisList.find(
                                    (emoji) =>
                                      emoji.emojiUnicode === emojiHexCode
                                  );
                                  return (
                                    <li
                                      onClick={(event) => {
                                        let mouseOutEvent = new Event(
                                          "mouseout",
                                          { bubbles: true }
                                        );
                                        event.target.dispatchEvent(
                                          mouseOutEvent
                                        );
                                        reactToReactions(emojiHexCode);
                                      }}
                                      title={getEmojiTitle(emojiHexCode)}
                                    >
                                      {String.fromCodePoint(emojiHexCode)}
                                    </li>
                                  );
                                })}
                                {!showAll && emojiHexCodes.length > 4 && (
                                  <li onClick={() => setShowAll(true)}>
                                    {/* <div
                                    style={{
                                      "font-size": "x-small",
                                      padding: "6px 0px 0px 5px",
                                    }}
                                  >
                                    {String.fromCodePoint("0x2795")}
                                  </div> */}
                                    <div className="emoji-ellipsis-div">
                                      <img
                                        className="emoji-ellipsis-img"
                                        src={
                                          require("../../../assets/bulletinboard/emojiEllipsis.svg")
                                            .default
                                        }
                                      />
                                    </div>
                                  </li>
                                )}
                              </ul>
                            </div>,
                          ]}
                        >
                          <div>
                            <ShowMoreLessText>
                              <div
                                className="comment-two-line-text"
                                dangerouslySetInnerHTML={{
                                  __html: sanitizeHTML(
                                    base64ToUtf8(item?.comment)
                                  ),
                                }}
                              />
                            </ShowMoreLessText>
                          </div>
                        </CustomizedToolTip>
                      ) : (
                        <div>
                          <ShowMoreLessText>
                            <div
                              className="comment-two-line-text"
                              dangerouslySetInnerHTML={{
                                __html: sanitizeHTML(
                                  base64ToUtf8(item?.comment)
                                ),
                              }}
                            />
                          </ShowMoreLessText>
                        </div>
                      )}
                      {config?.reactReplies?.reactRepliesEnabled && (
                        <ReactionPill
                          hoveredEmoji={hoveredEmoji}
                          key={
                            item.pinMetaDataCommentsId +
                            "_" +
                            item.pinMetaDataId
                          }
                          onMouseOver={(reaction) =>
                            handleReactionMouseOver(reaction)
                          }
                          onDesktopClick={(reaction) =>
                            handleReactionClick(reaction)
                          }
                          onTouchStart={(reaction) =>
                            handleMobileTouchStart(reaction)
                          }
                          onTouchEnd={(reaction) =>
                            handleMobileTouchEnd(reaction)
                          }
                          reactions={emojiSet}
                        />
                      )}
                    </div>
                  </div>
                  <div className="comment-action_btns">
                    {userInfo?.email?.toLowerCase() ===
                      item?.createdByEmail?.toLowerCase() &&
                      config?.editReply?.editReplyDisplay && (
                        <div>
                          <span title="Edit" onClick={onChangeToggleEdit}>
                            {<HiOutlinePencil />}
                          </span>
                        </div>
                      )}
                    {(userInfo?.email?.toLowerCase() ===
                      item?.createdByEmail?.toLowerCase() ||
                      config?.adminDeleteReply?.adminDeleteReplyDisplay) && (
                      <div>
                        <span
                          title="Delete"
                          onClick={() => {
                            toggleDelete(true);
                          }}
                        >
                          {loader ? (
                            <Loader className="delete-reply-loader" />
                          ) : (
                            <HiOutlineTrash />
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
  //}
};

export default Comment;
