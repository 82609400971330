import * as signalR from "@microsoft/signalr";
import { getData } from "../customApis";
import { getBaseUrl } from "../../utility/utility";
import APIEndPoints from "../../utility/apiendpoints";
import { ISignalHandler } from "./ISignalHandler";
import { SignalHandler } from "./SignalHandler";
const URL = getBaseUrl() + "/api/hubs/signalr";

class Connector {
  private connection: signalR.HubConnection;
  private groupName: string;
  public conStatus!: signalR.HubConnectionState;
  static instance: Connector;
  private signalHandler!: ISignalHandler;

  constructor() {
    this.conStatus = signalR.HubConnectionState.Disconnected;
    this.groupName = "";
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(URL, {
        accessTokenFactory: async (): Promise<any> => {
          const token = await getData(APIEndPoints.SIGNALR_TOKEN);
          if (token) {
            return token.data;
          }
        },
        withCredentials: false,
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();
    this.connection
      .start()
      .then(() => {
        this.conStatus = signalR.HubConnectionState.Connected;
      })
      .catch((err) => {
        console.log(err);
      });
    this.signalHandler = new SignalHandler(this.connection);
  }

  public async joinGroup(group: string | undefined) {
    try {
      if (this.connection && group !== undefined) {
        console.log(`Azure SignalR => Joinning group: ${group}`);
        if (this.groupName === "") {
          if (group !== "") {
            await this.connection.invoke("JoinGroup", group).then((item) => {
              console.log("group join success", group);
              this.groupName = group;
            });
          }
        } else {
          await this.leaveGroup(this.groupName).then(async () => {
            if (group !== "") {
              this.groupName = group;
              await this.connection.invoke("JoinGroup", group).then(() => {
                console.log("group join success", group);
              });
            }
          });
        }
      }
    } catch (err) {
      if ((this.conStatus = signalR.HubConnectionState.Disconnected)) {
        console.log("it's disconnected");
        // wait for connection to be re-established
        let reconInterval = setInterval(() => {
          if ((this.conStatus = signalR.HubConnectionState.Connected)) {
            clearInterval(reconInterval);
            this.joinGroup(group);
          }
        }, 1000);
      }
      console.log(
        `Azure SignalR => Group joinning failed: (${err}) for group ${group})`
      );
    }
  }

  private async leaveGroup(group: string | undefined) {
    try {
      if (this.connection && group !== undefined) {
        console.log(`Azure SignalR => Leaving group: ${group}`);
        SignalHandler.offAll(this.connection);
        await this.connection
          .invoke("LeaveGroup", group)
          .then((item) => {
            this.groupName = "";
          })
          .catch((err) => {
            console.log("error while leaving", err);
          });
      }
    } catch (err) {
      console.log(
        `Azure SignalR => Leaving the group failed: (${err} for group ${group})`
      );
    }
  }

  public connect(
    group: string | undefined,
    init: (handler: ISignalHandler) => void,
    reconnect?: () => void
  ) {
    if (this.connection) {
      this.connection.onreconnected(async () => {
        if (group !== undefined) {
          await this.joinGroup(this.groupName);
        }
        this.conStatus = signalR.HubConnectionState.Connected;
        if (reconnect !== undefined) reconnect();
      });
    }
    init(this.signalHandler);
  }

  public static getInstance(): Connector {
    if (!Connector.instance) Connector.instance = new Connector();
    return Connector.instance;
  }
}

export default Connector.getInstance;
