export default class APIEndPoints {
  public static CARD_LIMIT = (contentTypeId, companyId) => `/CardLimit/retrieveCardLimit/${contentTypeId}/${companyId}`;
  public static CALENDAR_INVITE = (contentTypeId, isGlobal, companyId) => `/Content/sendCalendarInvite/${contentTypeId}/${isGlobal}/${companyId}`;
  public static COMPANY_LIST = "/Company/companiesConfigurationDetails";
  public static COMPANY_NOTIFICATIONS_LIST = "/Notification/getPagedNotificationsByCompany";
  public static UPDATE_NOTIFICATION = (companyId) => `/Notification/${companyId}/updateNotifications`;
  public static CONTENT_BOOKMARK = (contentTypeId, isGlobal, companyId) => `/Content/updateBookmark/${contentTypeId}/${isGlobal}/${companyId}`;
  public static CONTENT_CONFIG = "/ContentConfig/getContentConfigDetails/";
  public static CONTENT_DATASET = (contentTypeId, companyId) => `/Content/getContentDataSet/${contentTypeId}/${companyId}`;
  public static CONTENT_DETAILS_BY_ID = (contentTypeId, isGlobal, companyId) => `/Content/getDetailById/${contentTypeId}/${isGlobal}/${companyId}`;
  public static CONTENT_LIKE = (contentTypeId, isGlobal, companyId) => `/Content/updateLikeCounter/${contentTypeId}/${isGlobal}/${companyId}`;
  public static CONTENT_SHARE_COUNTER = (contentTypeId, isGlobal, companyId) => `/Content/updateShareCounter/${contentTypeId}/${isGlobal}/${companyId}`;
  public static CONTENT_VIEW_COUNTER = (contentTypeId, isGlobal, companyId) => `/Content/updateViewCounter/${contentTypeId}/${isGlobal}/${companyId}`;
  public static DOWNLOAD_ATTACHMENT = (contentTypeId, isGlobal, companyId) => `/Content/downloadAttachment/${contentTypeId}/${isGlobal}/${companyId}`;
  public static DOWNLOAD_IMAGES = (contentTypeId, isGlobal, companyId) => `/Content/downloadAttachmentFromBlob/${contentTypeId}/${isGlobal}/${companyId}`;
  public static MENU_DATA = "/Menu/menuItemContent/";
  public static SEARCH_LIBRARY_CONTENT = (contentTypeId, companyId) => `/LibraryContent/filterLibraryContent/${contentTypeId}/${companyId}`;
  public static SEND_EMAIL = (contentTypeId, isGlobal, companyId) => `/Email/sendEmail/${contentTypeId}/${isGlobal}/${companyId}`;
  public static TAX_ALERTS = "/taxalerts";
  public static USER_SEARCH = (userDetails, companyId = null) => `/UserSetting/searchUsers/?contains=${userDetails}${companyId !== null ? `&companyId=${companyId}` : ""}`;
  public static USER_SETTING_INDUSTRIES = "/UserSetting/getIndustries";
  public static USER_SETTING_TOPICS = "/UserSetting/getTopics";
  public static USER_SETTING_LOCATIONS = "/UserSetting/getLocations";
  public static USER_SETTING_COUNTRIES = "/UserSetting/getCountries";
  public static USER_SETTING_PROFILE_INFO = "/UserSetting/getUserProfileInformation";
  public static USER_SETTINGS_SAVE_PROFILE_INFO = "/UserSetting/saveUserProfileInformation";
  public static USER_SETTINGS_DATA = "/UserSetting/getUserSettings";
  public static USER_SETTINGS_SAVE = "/UserSetting/saveUserSettings";
  public static GET_USERDETAILS = "/user/Onboard";
  public static SAVE_USERDETAILS = "/user/saveUserOnboardDetails";
  public static REGISTER_USERDETAILS = "/user/registerUserDetails";
  public static UPLOAD_EFILE = (companyId) => `/DataFile/${companyId}/uploadEFile`;
  public static UPLOAD_TAX_FORM_PDF_FILE = (companyId, dataFileFormType) => `/DataFile/${companyId}/UploadTaxFormPdf/${dataFileFormType}`;
  public static SPECIAL_CHARACTERS = '/Common/getSpecialCharacters';
  public static CREATE_CHAT = (contentTypeId, isGlobal, companyId) => `/Content/createChat/${contentTypeId}/${isGlobal}/${companyId}`;
  public static DATA_LIBRARY = (id) => `/DataFile/${id}/dataLibrary`;
  public static SIGNALR_TOKEN = "/Token/authenticate/signalrtoken";
  public static DOWNLOAD_FILE = (companyId) => `/DataFile/${companyId}/downloadEFile`;
  public static DOWNLOAD_FILE_WITH_TOKEN = '/Common/downloadFileWithToken?token=';
  public static DOWNLOAD_PDF_WITH_TOKEN = '/Common/PDF?token=';
  public static GET_EXCEL_REPORTS = (companyId) => `/Report/${companyId}/getExcelReports`;
  public static GET_FILE_TOKEN = "/Token/authenticate/getfiletoken";
  public static EFILE_TYPES = (companyId) => `/Report/${companyId}/getEFileDataTypes`;
  public static EFILE_DETAILS = (companyId) => `/Report/${companyId}/getEFileVersionDetails`;
  public static EFILE_PDF_DETAILS = (companyId) => `/Report/${companyId}/getTaxFormDetails`;
  public static REPORT_TEMPLATES = (companyId) => `/Report/${companyId}/getReportTemplates`;
  public static CREATE_REPORT = (companyId) => `/Report/${companyId}/createReport`;
  public static DOWNLOAD_EXCEL_REPORT = (companyId) => `/Report/${companyId}/downloadExcelReport`;
  public static DUPLICATE_EFILE = (companyId, actionTypeId) => `/DataFile/${companyId}/handleDuplicateFile/${actionTypeId}`;
  public static ALLOWED_SPECIAL_CHARACTERS = '/Common/getAllowedSpecialCharacters';
  public static PBREPORT_TOKEN_ENDPOINT = (companyId) => `/Report/${companyId}/getVisualizationEmbedReportDetails`;
  public static CONTENT_CATAGORIES_ENDPOINT = (companyId) => `/Content/getAllContentCategories/${companyId}`;
  public static CREATE_CONTENT = (contentTypeId, companyId) => `/Content/${contentTypeId}/createContent/${companyId}`;
  public static REQUEST_TOKEN = "/Token/authenticate/requesttoken";
  public static GET_CONTENT_ACTIONITEMS = (companyId) => `/Content/getAllContentActionItems/${companyId}`;
  public static GET_CONTENTTYPES = "/ContentConfig/getContentTypes";
  public static DELETE_CONTENT = (contentType, isGlobal, companyId) => `/Content/deleteContent/${contentType}/${isGlobal}/${companyId}`;
  public static CHECK_USERPRIVILAGES = "/User/CheckForUserPrivilegesModified";
  public static GET_PAGE_LEVEL_FLAGS = (companyId = null, subMenuItemId = null, contentType = null) => `/ContentConfig/getPageLevelFeatureConfigDetails${companyId !== null ? '/' + companyId : ""}?${subMenuItemId !== null ? 'subMenuItemId=' + subMenuItemId : ""}${contentType !== null && subMenuItemId !== null ? '&' : ""}${contentType !== null ? 'contentType=' + contentType : ""}`;
  public static GET_CONTACTUS_DETAILS = '/ContactUs/getContactUsDetails';
  public static SUBMIT_CONTACTUS_DETAILS = (companyId, subMenuItemId = null) => `/ContactUs/${companyId}/SendContactUsSupportEmail${subMenuItemId !== null ? '?subMenuItemId=' + subMenuItemId : ""}`;
  public static DELETE_EFILE = (companyId, subMenuItemId) => `/DataFile/${companyId}/deleteEfile/${subMenuItemId}`;
  public static DELETE_REPORT = (companyId, subMenuItemId) => `/Report/${companyId}/deleteReport/${subMenuItemId}`;
  public static GET_STOCK_OF_IMAGES = `/Content/getDefaultStockImages?contentType=`;
  public static UPDATE_CONTENT = (contentType, isGlobal, companyId) => `/Content/updateContent/${contentType}/${isGlobal}/${companyId}`;
  public static GET_PRIVACY_NOTICE_FILE = '/PrivacyNotice/getPrivacyNotice';
  public static ACCEPT_PRIVACY_AGREEMENT = (companyId) => `/PrivacyNotice/accept/${companyId}`;
  public static GET_CONTENT_CATEGORIES = (contentType, companyId, categoryId = null) => `/Content/getContentCategories/${contentType}/${companyId}${categoryId !== null ? '/' + categoryId : ""}`;
  public static GET_PINNED_CARDS = (companyId) => `/Content/getPinCardsDataSet/${companyId}`;
  public static GET_SINGLE_PINNED_CARD = (isGlobal, companyId) => `/Content/getPinCardsDataSetSingle/${isGlobal}/${companyId}`;
  public static PIN_UNPIN_CARD = (contentType, isGlobal, companyId, isPinned) => `/Content/${isPinned ? "" : "un"}pinCard/${contentType}/${isGlobal}/${companyId}`;
  public static GET_BB_COMMENTS = (contentType, isGlobal, companyId, pinMetaDataId) => `/Content/getReplyMessagesByPinMetaDataId/${contentType}/${isGlobal}/${companyId}/${pinMetaDataId}`;
  public static ADD_BB_COMMENT = (contentType, isGlobal, companyId) => `/Content/createReplyMessage/${contentType}/${isGlobal}/${companyId}`;
  public static UPDATE_BB_COMMENT = (contentType, isGlobal, companyId) => `/Content/updateReplyMessage/${contentType}/${isGlobal}/${companyId}`;
  public static DELETE_UNDO_BB_COMMENT = (contentType, isGlobal, companyId) => `/Content/deleteOrUndoReplyMessage/${contentType}/${isGlobal}/${companyId}`;
  public static UPDATE_POLL_CONTENT_STATUS = (contentType, isGlobal, companyId) => `/Content/UpdateContentStatus/${contentType}/${isGlobal}/${companyId}`;
  public static SAVE_POLL_RESPONSE = (isGlobal, companyId) => `/Content/SavePollResponse/${isGlobal}/${companyId}`;
  public static USER_MANAGEMENT_GET_USERS = (companyId = null) => `/UserManagement/getUsers${companyId !== null ? '/' + companyId : ""}`;
  public static USER_MANAGEMENT_GET_ROLES = (companyId = null) => `/UserManagement/getRoles${companyId !== null ? '/' + companyId : ""}`;
  public static USER_MANAGEMENT_ADD_USER = (companyId = null) => `/UserManagement/addUser${companyId !== null ? '/' + companyId : ""}`;
  public static USER_MANAGEMENT_DELETE_USERS = (companyId = null) => `/UserManagement/deleteUser${companyId !== null ? '/' + companyId : ""}`;
  public static USER_MANAGEMENT_LEAVE_COMPANY = (companyId = null) => `/UserManagement/leaveCompany${companyId !== null ? '/' + companyId : ""}`;
  public static USER_MANAGEMENT_CHANGE_ROLE = (companyId = null) => `/UserManagement/editUserRole${companyId !== null ? '/' + companyId : ""}`;
  public static AD_USER_SEARCH = (userDetails, userType = null, companyId = null, searchAd = null, searchUserGroup = null) => `/UserManagement/searchADUsers${userDetails !== null ? '/' + userDetails : ""}?${userType !== null ? "userType=" + userType : ""}${companyId !== null ? "&companyId=" + companyId : ""}${searchAd !== null ? "&searchAd=" + searchAd : ""}${searchUserGroup !== null ? "&searchUserGroup=" + searchUserGroup : ""}`;
  public static CHECK_IF_CONTENT_EXIST = (contentType, metaDataId, companyId = null) => `/Content/checkIfContentMetaDataExists/${contentType}/${metaDataId}${companyId !== null ? '/' + companyId : ""}`;
  public static GET_BASIC_CONTENT_METADATA = (contentType, companyId = null) => `/Content/getBasicContentMetaDataList/${contentType}${companyId !== null ? '/' + companyId : ""}`;
  public static GET_TAKETOUR_DETAILS = (pageTypeId) => `/Page/details/${pageTypeId}`;
  public static DOWNLOAD_TAKETOUR_IMAGES = '/Page/files';
  public static GET_COMPANIES = "/Company/getCompaniesWithFilter";
  public static UPDATE_CHANNEL_ACTIVITYFEED_SETTING = (companyId = null, isCompanyActivityFeedFlagEnabled) => `/Company/updateCompanyActivityFeedFlag/${companyId}/${isCompanyActivityFeedFlagEnabled}`;
  public static UPDATE_USER_ACTIVITYFEED_SETTING = (isCompanyActivityFeedFlagEnabled) => `/User/UpdateActivityFeedFlag/${isCompanyActivityFeedFlagEnabled}`;
  public static UPLOAD_COMPANY_LOGO = (companyId) => `/Company/uploadCompanyLogo/${companyId}`;
  public static GET_COMPANY_LOGO = (companyId) => `/Company/getCompanyLogo/${companyId}`;
  public static GET_NOTIFICATION_FLAG_STATE = (companyId = null) => `/Notification/${companyId}/getNotificationFlagsState`;
  public static GET_AVAILABLE_FORMS = (companyId) => `/DataFile/${companyId}/getDocumentIntelligenceFormsList`;
  public static CTA_ACTION_STATUS = (contentType, isGlobal, companyId) => `/Content/getCTAWorkflowTrackingStatus/${contentType}/${isGlobal}/${companyId}`;
  public static TRIGGER_CTA_ACTION = (contentType, companyId, isGlobal, eventType) => `/Content/TriggerCTAAction/${contentType}/${companyId}/${isGlobal}/${eventType}`;
  public static CREATE_CUSTOM_USER_GROUP = (companyId) => `/CustomUserGroup/createCustomUserGroup/${companyId}`;
  public static UPDATE_CUSTOM_USER_GROUP = (companyId) => `/CustomUserGroup/updateCustomUserGroup/${companyId}`;
  public static GET_CUSTOM_USER_GROUP_BY_ID = (companyId, customUserGroupId) => `/CustomUserGroup/getCustomUserGroupById/${companyId}/${customUserGroupId}`;
  public static GET_CUSTOM_USER_GROUPS = (companyId) => `/CustomUserGroup/getCustomUserGroups/${companyId}`;
  public static DELETE_CUSTOM_USER_GROUP = (companyId, customUserGroupId) => `/CustomUserGroup/deleteCustomUserGroup/${companyId}/${customUserGroupId}`;
  public static CREATE_UPDATE_BB_COMMENT_REACTION = (contentType, isGlobal, companyId) => `/Content/upsertPinMetaDataCommentsReactions/${contentType}/${isGlobal}/${companyId}`;
  public static DELETE_BB_COMMENT_REACTION = (contentType, isGlobal, companyId, pinMetadataReactionId) => `/Content/deletePinMetaDataCommentsReactions/${contentType}/${isGlobal}/${companyId}/${pinMetadataReactionId}`;
  public static GET_DEEPLINK_CARDDETAILS_BY_ID = (currentContextCompanyId, contentType, companyId) => `/Content/GetDeeplinkCardDetailsById/${currentContextCompanyId}/${contentType}/${companyId}`;
  public static GET_COMPANY_DETAILS_BY_USER = `/Company/CompanyDetailsByUser`;
  public static GET_COMPANY_CONFIG_BY_ID = (companyId) => `/Company/companiesConfigurationDetails/${companyId}`;
  public static CONTENT_DATASOURCE_POWERK = (contentTypeId, companyId) => `/InternalApps/getDataSources/${contentTypeId}/${companyId}`;
  public static CONTENT_DATALIBRARY_INTERNALAPPS = (contentTypeId, companyId) => `/InternalApps/getLibraryData/${contentTypeId}/${companyId}`;
  public static GET_SEARCHTEMPLATE_JSON_DETAILS = (contentTypeId, companyId) => `/InternalApps/getSearchTemplateJsonDetails/${contentTypeId}/${companyId}`;
  public static GET_LIBRARY_SEARCH_FIELD_VALUES = (contentTypeId, companyId) => `/InternalApps/getLibrarySearchFieldValues/${contentTypeId}/${companyId}`;
  public static GET_LIBRARY_HEADERS_TEMPLATE_DETAILS = (contentTypeId, companyId) => `/InternalApps/getLibraryHeadersForTemplate/${contentTypeId}/${companyId}`;
  public static DOWNLOAD_ATTACHMENT_FROM_BLOB = (contentTypeId, isGlobal, companyId) => `/Content/downloadAttachmentFromBlob/${contentTypeId}/${isGlobal}/${companyId}`;
  public static DOWNLOAD_EXCEL_REPORT_INTERNAL_APPS = (contentTypeId) => `/InternalApps/getExportExcelData/${contentTypeId}`;
  public static UPDATE_DOCUMENT_STATUS_INTERNAL_APPS = (contentTypeId, companyId) => `/InternalApps/updateDataLibraryDocumentStatus/${contentTypeId}/${companyId}`;
  public static GET_INTERNAL_APPS_PROMPT_RESULT = (contentTypeId, companyId) => `/Prompt/getInternalAppsPromptResult/${contentTypeId}/${companyId}`;
  public static SAVE_PROMPT_RESULT_AS_DRAFT = (contentTypeId, companyId) => `/Prompt/savePromptResultAsDraft/${contentTypeId}/${companyId}`;
  public static GET_CHANNEL_LIST = (companyId) => `/Company/${companyId}/getChannelList`;
  public static GET_USER_SHORTCUTS = `/UserShortcut/getUserShortcuts`;
  public static ADD_USER_SHORTCUTS = (subMenuItemId) => `/UserShortcut/addUserShortcut/${subMenuItemId}`;
  public static REMOVE_USER_SHORTCUTS = (shortcutId) => `/UserShortcut/deleteUserShortcut/${shortcutId}`;
  public static PUBLISH_PROMPT_RESULT = (contentTypeId, companyId) => `/Prompt/publishPromptResult/${contentTypeId}/${companyId}`;
  public static GET_DATA_LIBRARY_COLUMN_FILTER_DATA = (contentTypeId, companyId) => `/InternalApps/getDataLibraryColumnFilterData/${contentTypeId}/${companyId}`;
  public static GET_CHANNELDROPDOWN_LIST = (companyId) => `/Company/${companyId}/ClientList`;
  public static GET_CLIENTDETAILS_CARD_DATA = (companyId, clientId) => `/Company/${companyId}/ClientDetails/${clientId}`;
  public static ONBOARD_CHANNEL = (companyId) => `/Company/${companyId}/onboardChannel`;
  public static Approve_Deny_Request = (companyId, isApproved) => `/Company/${companyId}/approveChannelOnboard/${isApproved}`;
  public static GET_PENDINGCHANNELS_LIST = (companyId) => `/Company/${companyId}/getPendingChannelApprovalsList`;
  public static GET_EY_TOKEN = `/Token`;
  public static Delete_EY_TOKEN = `/Token/deleteToken`;
  public static GET_FILTERED_DATA_LIBRARY_META_DATA = (contentTypeId, companyId) => `/InternalApps/getFilteredDataLibraryMetaData/${contentTypeId}/${companyId}`;
  public static DELEGATE_GCSP_OWNER = (companyId) => `/Company/${companyId}/delegateChannelRequestApprover`;
  public static DOWNLOAD_DATABASE_FILES = (contentTypeId, companyId) => `/InternalApps/downloadDatabaseFiles/${contentTypeId}/${companyId}`;
  public static CONTENT_INTERNALAPPS_DISLIKE = (contentTypeId, companyId) => `/InternalApps/SaveLike/${contentTypeId}/${companyId}`;
  public static GET_INTERNAL_APP_DETAILS = (contentTypeId, companyId) => `/InternalApps/getInternalAppDetails/${contentTypeId}/${companyId}`;
  public static GET_CLIENTBASICDETAILS_CARD_DATA = (companyId, clientId) => `/Company/${companyId}/ClientBasicDetails/${clientId}`;
  public static DOWNLOAD_ATTACHMENT_FOR_INTERNALAPPS = (contentTypeId, companyId) => `/InternalApps/downloadFileForInternalAppsFromBlob/${contentTypeId}/${companyId}`;
  public static EXPORT_VISUALIZATION = (contentTypeId, companyId) => `/Visualization/${contentTypeId}/${companyId}/exportVisualizationReport`;
  public static CHECK_REFERENCE_TEMPLATE_AVAILABILITY = (contentTypeId, companyId) => `/Visualization/${contentTypeId}/${companyId}/checkReferenceTemplateAvailability`;
  public static UPLOAD_TPALERTS_REFERENCE_GUIDE = (contentTypeId, companyId) => `/InternalApps/uploadTPAlertsReferenceGuide/${contentTypeId}/${companyId}`;
  public static LIBRARY_HYPERLINK_MYTECH_UNAUTH = (assetName) => `/assets/downloadAssetFileByName/${assetName}`;
  public static REQUEST_CHANNEL_ACCESS = (clientId) => `/Company/RequestAccessChannel/${clientId}`;
  public static GET_ALL_SPECIAL_CHARACTERS = '/Common/getAllSpecialCharacters';
  public static UPLOAD_DDENGINE_EXCEL_REPORT_PPOD = (companyId) => `/Report/${companyId}/uploadTaxFormPdfExcelReport`;
}
