/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  AiFillCheckCircle,
  AiFillExclamationCircle,
  AiFillBell,
} from "react-icons/ai";
import { MdInfo } from "react-icons/md";
import { Alert } from "@fluentui/react-northstar";
import { useEffect, useRef } from "react";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import "./toasterNotifications.css";

function ToasterNotifications(props) {
  const { notificationMsgsData, handleNotificationMsgsData } =
    UseToasterNotificationsContext();
  let { showMsgBar, completed, msg, type, button } = notificationMsgsData;
  const timeoutRef = useRef(null);
  useEffect(() => {
    if (completed === true) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        handleNotificationMsgsData({
          showMsgBar: false,
          started: false,
          completed: false,
          msg: "",
          type: "",
          isFailed: false,
          button: null
        });
        timeoutRef.current = null;
      }, 5000);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [completed, notificationMsgsData]);
  const onClear = () => {
    handleNotificationMsgsData({
      showMsgBar: false,
      started: false,
      completed: false,
      msg: "",
      type: "",
      isFailed: false,
      button: null
    });
  };
  return (
    <div className={props.showClass}>
      {showMsgBar && (
        <Alert
          content={
            <span style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ display: "flex" }}>
                {type.toLocaleLowerCase() === "success" ? (
                  <AiFillCheckCircle />
                ) : type.toLocaleLowerCase() === "fail" ? (
                  <AiFillExclamationCircle />
                ) : type.toLocaleLowerCase() === "warning" || type.toLocaleLowerCase() === "bulletin board" ? (
                  <AiFillBell />
                ) : type.toLocaleLowerCase() === "info" ? (
                  <MdInfo />
                ) : null}
                {msg}
              </div>
              {button && <span style={{ margin: "0 5px", minWidth: "fit-content" }}>{button}</span>}
            </span>
          }
          visible={showMsgBar}
          success={type.toLocaleLowerCase() === "success" ? true : false}
          danger={type.toLocaleLowerCase() === "fail" ? true : false}
          warning={
            type.toLocaleLowerCase() === "info" ||
              type.toLocaleLowerCase() === "warning" || type.toLocaleLowerCase() === "bulletin board"
              ? true
              : false
          }
          dismissible
          dismissAction={{
            onClick: onClear,
          }}
        />
      )}
    </div>
  );
}

export default ToasterNotifications;
