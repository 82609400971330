import React from "react";
import { BsPinAngleFill } from "react-icons/bs";
import {
  MdEvent,
  MdCollectionsBookmark,
  MdStore,
  MdInsertChart,
  MdStackedLineChart,
  MdRateReview,
  MdApps,
  MdAssistant,
  MdDonutLarge,
} from "react-icons/md";
import uuid from "react-uuid";

function generateUUID() {
  return uuid();
}
// JSON format to understand the parent children relation
// [
//   {
//     section {
//       [
//         menu{
//           submenu:[]
//         }
//       ]
//     }

//   }
// ]

const sideNavMainMenuData = [
  {
    //section
    id: generateUUID(),
    title: "",
    divider: false,
    menu: [
      {
        id: generateUUID(),
        title: "Bulletin Board",
        badge: "",
        to: "/",
        // icon: (
        //   <img
        //   width="25.5"
        //   height="25.5"
        //     src={
        //       require("../../assets/bulletinboard/bulletinBoardIcon.svg")
        //         .default
        //     }
        //   />
        // ),
        icon: <BsPinAngleFill className="BBICON" />,
        submenu: [],
      },
    ],
  },
  {
    //section
    id: generateUUID(),
    title: "CONTENT",
    divider: false,
    menu: [
      {
        id: generateUUID(),
        title: "Events",
        badge: "",
        icon: <MdEvent />,

        submenu: [
          {
            id: generateUUID(),

            title: "Conferences",
            badge: "",
            to: "/conferences",
          },
          {
            id: generateUUID(),

            title: "Webcasts",
            badge: "",
            to: "/webcasts",
          },
          {
            id: generateUUID(),

            title: "Podcasts",
            badge: "",
            to: "/podcasts",
          },
        ],
      },

      {
        id: generateUUID(),
        title: "The Collective",
        badge: "",
        icon: <MdRateReview />,

        submenu: [
          {
            id: generateUUID(),

            title: "Perspectives",
            badge: "",
            to: "/perspectives",
          },
          {
            id: generateUUID(),

            title: "Polls",
            badge: "",
            to: "/polls",
          },
          // {
          //   id: generateUUID(),

          //   title: "Bulletin Board",
          //   badge: "",
          //   to: "/surveys",
          // },
        ],
      },

      {
        id: generateUUID(),
        title: "Resources",
        badge: "",
        icon: <MdCollectionsBookmark />,

        submenu: [
          {
            id: generateUUID(),

            title: "Tax Agenda",
            badge: "",
            to: "/taxagenda",
          },
          {
            id: 1,
            title: "Tax Alerts",
            badge: "",
            to: "/taxalerts",
          },
          {
            id: generateUUID(),

            title: "Accounting Link",
            badge: "",
            to: "/accountinglink",
          },
          {
            id: generateUUID(),

            title: "Guides",
            badge: "",
            to: "/guides",
          },
          {
            id: generateUUID(),

            title: "Executives",
            badge: "",
            to: "/executives",
          },
        ],
      },
      {
        id: generateUUID(),
        title: "Marketplace",
        badge: "",
        icon: <MdStore />,

        submenu: [
          {
            id: generateUUID(),

            title: "App Catalog",
            badge: "",
            to: "/appcatalog",
          },
          {
            id: generateUUID(),

            title: "Tax On-Demand",
            badge: "",
            to: "/taxOnDemand",
          },
        ],
      },
    ],
  },

  {
    //section
    id: generateUUID(),
    title: "MY TECHNOLOGY",
    divider: false,
    menu: [
      {
        id: generateUUID(),
        title: "Data Transformation",
        badge: "",
        icon: <MdInsertChart />,

        submenu: [
          { id: generateUUID(), title: "Data Drop", badge: "", to: "/dataDrop" },
          {
            id: generateUUID(),

            title: "Data Library",
            badge: "",
            to: "/dataLibrary",
          },
          {
            id: generateUUID(),

            title: "Data Retrieve",
            badge: "",
            to: "/dataRetrieve",
          },
          // {
          //   id: generateUUID(),

          //   title: "EY Subscription",
          //   badge: "EY Only",
          //   to: "/eySubscription",
          // },
        ],
      },

      {
        id: generateUUID(),
        title: "Reports",
        badge: "",
        icon: <MdStackedLineChart />,

        submenu: [
          {
            id: generateUUID(),

            title: "Create Report",
            badge: "",
            to: "/createReport",
          },
          {
            id: generateUUID(),

            title: "Report Library",
            badge: "",
            to: "/reportLibrary",
          },
        ],
      },
      {
        id: generateUUID(),
        title: "Visualizations",
        badge: "",
        icon: <MdDonutLarge />,

        submenu: [
          {
            id: generateUUID(),

            title: "Visualization Library",
            badge: "",
            to: "/visualizationlibrary",
          },
          {
            id: generateUUID(),
            title: "Create Visualization",
            badge: "",
            to: "/createPin",
          },
          {
            id: generateUUID(),
            title: "Visualization Board",
            badge: "",
            to: "/pinBoard",
          },
        ],
      },
    ],
  },

  {
    //section
    id: generateUUID(),
    title: "",
    badge: "",
    divider: false,
    menu: [
      {
        id: generateUUID(),
        title: "Apps",
        badge: "",
        to: "/apps",
        icon: <MdApps />,
        submenu: [],
      },
      {
        id: generateUUID(),
        title: "TransAct",
        badge: "EY Only",
        icon: <MdAssistant />,

        submenu: [
          { id: generateUUID(), title: "Robobudget", badge: "", to: "/robobudget" },
          { id: generateUUID(), title: "Roboscope", badge: "", to: "/roboscope" },
          {
            id: generateUUID(),

            title: "Engagement Metrics",
            badge: "",
            to: "/engagementMetrics",
          },
          {
            id: generateUUID(),

            title: "EY Campaigns",
            badge: "",
            to: "/eycampaigns",
          },
        ],
      },
    ],
  },
];

export default sideNavMainMenuData;

export const companyFeatureFlags = [
  {
    subMenuItemId: 1,
    featureFlagName: "Overview",
    isEnabled: false,
  },
  // {
  //   "subMenuItemId": 2,
  //   "featureFlagName": "Value Drivers",
  //   "isEnabled": false
  // },
  // {
  //   "subMenuItemId": 3,
  //   "featureFlagName": "What's Coming",
  //   "isEnabled": false
  // },
  {
    subMenuItemId: 5,
    featureFlagName: "Conferences",
    isEnabled: false,
  },
  {
    subMenuItemId: 6,
    featureFlagName: "Webcasts",
    isEnabled: false,
  },
  {
    subMenuItemId: 7,
    featureFlagName: "Podcasts",
    isEnabled: false,
  },
  {
    subMenuItemId: 9,
    featureFlagName: "Polls",
    isEnabled: false,
  },
  {
    subMenuItemId: 8,
    featureFlagName: "Perspectives",
    isEnabled: false,
  },
  {
    subMenuItemId: 10,
    featureFlagName: "Bulletin Board",
    isEnabled: false,
  },
  {
    subMenuItemId: 11,
    featureFlagName: "Tax Agenda",
    isEnabled: false,
  },
  {
    subMenuItemId: 12,
    featureFlagName: "Tax Alerts",
    isEnabled: false,
  },
  {
    subMenuItemId: 13,
    featureFlagName: "Guides",
    isEnabled: false,
  },
  {
    subMenuItemId: 14,
    featureFlagName: "Executives",
    isEnabled: false,
  },
  {
    subMenuItemId: 23,
    featureFlagName: "Visualization Library",
    isEnabled: false,
  },
  {
    subMenuItemId: 15,
    featureFlagName: "App Catalog",
    isEnabled: false,
  },
  {
    subMenuItemId: 16,
    featureFlagName: "Tax On-Demand",
    isEnabled: false,
  },
  {
    subMenuItemId: 17,
    featureFlagName: "Data Drop",
    isEnabled: false,
  },
  {
    subMenuItemId: 18,
    featureFlagName: "Data Library",
    isEnabled: false,
  },
  {
    subMenuItemId: 19,
    featureFlagName: "Data Retrieve",
    isEnabled: false,
  },
  {
    subMenuItemId: 20,
    featureFlagName: "EY Subscription",
    isEnabled: false,
  },
  {
    subMenuItemId: 21,
    featureFlagName: "Create Report",
    isEnabled: false,
  },
  {
    subMenuItemId: 22,
    featureFlagName: "Report Library",
    isEnabled: false,
  },
  {
    subMenuItemId: 24,
    featureFlagName: "Create Visualization",
    isEnabled: false,
  },
  {
    subMenuItemId: 25,
    featureFlagName: "Visualization Board",
    isEnabled: false,
  },
  {
    subMenuItemId: 26,
    featureFlagName: "Apps",
    isEnabled: false,
  },
  {
    subMenuItemId: 27,
    featureFlagName: "Robobudget",
    isEnabled: false,
  },
  {
    subMenuItemId: 29,
    featureFlagName: "EY Campaigns",
    isEnabled: false,
  },
  {
    subMenuItemId: 30,
    featureFlagName: "Engagement Metrics",
    isEnabled: false,
  },
  {
    subMenuItemId: 28,
    featureFlagName: "Roboscope",
    isEnabled: false,
  },
  {
    subMenuItemId: 31,
    featureFlagName: "Profile",
    isEnabled: false,
  },

  {
    subMenuItemId: 32,
    featureFlagName: "My Experience",
    isEnabled: false,
  },

  {
    subMenuItemId: 33,
    featureFlagName: "Subscription",
    isEnabled: false,
  },

  {
    subMenuItemId: 34,
    featureFlagName: "Billing Details",
    isEnabled: false,
  },
  {
    subMenuItemId: 35,
    featureFlagName: "Notification",
    isEnabled: false,
  },
  {
    subMenuItemId: 38,
    featureFlagName: "Your Channel",
    isEnabled: false
  },
  {
    subMenuItemId: 39,
    featureFlagName: "Add Member",
    isEnabled: false
  },
  {
    subMenuItemId: 40,
    featureFlagName: "Leave Company",
    isEnabled: false
  },
  {
    subMenuItemId: 41,
    featureFlagName: "Get Channel Link",
    isEnabled: false
  },
  {
    subMenuItemId: 42,
    featureFlagName: "Upload Company Logo",
    isEnabled: false
  },
  {
    subMenuItemId: 43,
    featureFlagName: "User Groups",
    isEnabled: false
  },
  {
    subMenuItemId: 46,
    featureFlagName: "Accounting Link",
    isEnabled: false,
  },
  {
    subMenuItemId: 47,
    featureFlagName: "Channel Management",
    isEnabled: false,
  },
  {
    subMenuItemId: 48,
    featureFlagName: "Pending Approvals",
    isEnabled: false,

  },

];

export const featureFlagObj = {
  Overview: {
    subMenuItemId: 1,
    featureFlagName: "Overview",
    isEnabled: false,
  },
  // "Value Drivers": {
  //   "subMenuItemId": 2,
  //   "featureFlagName": "Value Drivers",
  //   "isEnabled": false
  // },
  // "What's Coming": {
  //   "subMenuItemId": 3,
  //   "featureFlagName": "What's Coming",
  //   "isEnabled": false
  // },
  "Conferences": {
    subMenuItemId: 5,
    featureFlagName: "Conferences",
    isEnabled: false,
  },
  Webcasts: {
    subMenuItemId: 6,
    featureFlagName: "Webcasts",
    isEnabled: false,
  },
  Podcasts: {
    subMenuItemId: 7,
    featureFlagName: "Podcasts",
    isEnabled: false,
  },
  Polls: {
    subMenuItemId: 9,
    featureFlagName: "Polls",
    isEnabled: false,
  },
  Perspectives: {
    subMenuItemId: 8,
    featureFlagName: "Perspectives",
    isEnabled: false,
  },
  "Bulletin Board": {
    subMenuItemId: 10,
    featureFlagName: "Bulletin Board",
    isEnabled: false,
  },
  "Tax Agenda": {
    subMenuItemId: 11,
    featureFlagName: "Tax Agenda",
    isEnabled: false,
  },
  "Tax Alerts": {
    subMenuItemId: 12,
    featureFlagName: "Tax Alerts",
    isEnabled: false,
  },
  Guides: {
    subMenuItemId: 13,
    featureFlagName: "Guides",
    isEnabled: false,
  },
  Executives: {
    subMenuItemId: 14,
    featureFlagName: "Executives",
    isEnabled: false,
  },
  "Visualization Library": {
    subMenuItemId: 23,
    featureFlagName: "Visualization Library",
    isEnabled: false,
  },
  "App Catalog": {
    subMenuItemId: 15,
    featureFlagName: "App Catalog",
    isEnabled: false,
  },
  "Tax On-Demand": {
    subMenuItemId: 16,
    featureFlagName: "Tax On-Demand",
    isEnabled: false,
  },
  "Data Drop": {
    subMenuItemId: 17,
    featureFlagName: "Data Drop",
    isEnabled: false,
  },
  "Data Library": {
    subMenuItemId: 18,
    featureFlagName: "Data Library",
    isEnabled: false,
  },
  "Data Retrieve": {
    subMenuItemId: 19,
    featureFlagName: "Data Retrieve",
    isEnabled: false,
  },
  "EY Subscription": {
    subMenuItemId: 20,
    featureFlagName: "EY Subscription",
    isEnabled: false,
  },
  "Create Report": {
    subMenuItemId: 21,
    featureFlagName: "Create Report",
    isEnabled: false,
  },
  "Report Library": {
    subMenuItemId: 22,
    featureFlagName: "Report Library",
    isEnabled: false,
  },
  "Create Visualization": {
    subMenuItemId: 24,
    featureFlagName: "Create Visualization",
    isEnabled: false,
  },
  "Visualization Board": {
    subMenuItemId: 25,
    featureFlagName: "Visualization Board",
    isEnabled: false,
  },
  Apps: {
    subMenuItemId: 26,
    featureFlagName: "Apps",
    isEnabled: false,
  },
  Robobudget: {
    subMenuItemId: 27,
    featureFlagName: "Robobudget",
    isEnabled: false,
  },
  "EY Campaigns": {
    subMenuItemId: 29,
    featureFlagName: "EY Campaigns",
    isEnabled: false,
  },
  "Engagement Metrics": {
    subMenuItemId: 30,
    featureFlagName: "Engagement Metrics",
    isEnabled: false,
  },
  Roboscope: {
    subMenuItemId: 28,
    featureFlagName: "Roboscope",
    isEnabled: false,
  },

  Profile: {
    subMenuItemId: 31,
    featureFlagName: "Profile",
    isEnabled: false,
  },

  "My Experience": {
    subMenuItemId: 32,
    featureFlagName: "My Experience",
    isEnabled: false,
  },

  Subscription: {
    subMenuItemId: 33,
    featureFlagName: "Subscription",
    isEnabled: false,
  },

  "Billing Details": {
    subMenuItemId: 34,
    featureFlagName: "Billing Details",
    isEnabled: false,
  },
  Notification: {
    subMenuItemId: 35,
    featureFlagName: "Notification",
    isEnabled: false,
  },
  "Your Channel": {
    subMenuItemId: 38,
    featureFlagName: "Your Channel",
    isEnabled: false
  },
  "Add Member": {
    subMenuItemId: 39,
    featureFlagName: "Add Member",
    isEnabled: false
  },
  "Leave Company": {
    subMenuItemId: 40,
    featureFlagName: "Leave Company",
    isEnabled: false
  },
  "Get Channel Link": {
    subMenuItemId: 41,
    featureFlagName: "Get Channel Link",
    isEnabled: false
  },
  "Upload Company Logo": {
    subMenuItemId: 42,
    featureFlagName: "Upload Company Logo",
    isEnabled: false
  },
  "User Groups": {
    subMenuItemId: 43,
    featureFlagName: "User Groups",
    isEnabled: false
  },
  "Accounting Link": {
    subMenuItemId: 46,
    featureFlagName: "Accounting Link",
    isEnabled: false,
  },
  "Channel Management": {
    subMenuItemId: 47,
    featureFlagName: "Channel Management",
    isEnabled: false
  },
  "Pending Approvals":{
    subMenuItemId: 48,
    featureFlagName: "Pending Approvals",
    isEnabled: false
  }
};
