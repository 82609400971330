import React, { useState } from "react";
import { MdCheckCircle } from "react-icons/md";
import "./copyLink.css";
import { UseDeeplinkContext } from "../../../../context/deeplincontext/deeplinkContext";
import { postData } from "../../../../services/customApis";
import { UseUserInfoContext } from "../../../../context/usercontext/userContext";
import APIEndPoints from "../../../../utility/apiendpoints";
import { buildDeepLink } from "../../../../utility/utility";
import Constant from "../../../../utility/constants";
import { strUndefined } from "@microsoft/applicationinsights-core-js";
import { UseToasterNotificationsContext } from "../../../../context/toasternotificationscontext/toasterNotificationsContext";
import { TrackEvent } from '../../../../services/appInsights';
import TelemetryEventNames from "../../../../data/apptelemetry/appTelemetry.const";
import { getContentTypeNamesById } from "../../../../utility/utility";

const CopyLink = (props) => {
  const [copied, setCopied] = useState(false);
  const [disabled, setStatus] = useState(false);
  const encodedContext = encodeURI(
    `{"subEntityId": "${props.item.metaDataId}"}`
  );
  let { deeplinkUrl } = UseDeeplinkContext();
  const { userInfo } = UseUserInfoContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  // console.log(props.item, props.item.contentInfo[0].url, props.item.articleUrl);
  let url = "";
  if (props.contentType !== 4) {
    if (props?.item?.articleUrl !== undefined) {
      url = props?.item?.articleUrl;
    } else if (props?.item?.contentInfo[0]?.url !== "") {
      try {
        url = atob(props?.item?.contentInfo[0]?.url);
      } catch {
        url = props?.item?.contentInfo[0]?.url;
      }
    }
  }
  console.log("final url " + url);
  // deeplinkUrl = buildDeepLink(deeplinkUrl,props.contentType,userInfo.teams.companyId,props.item.metaDataId,props.item.articleUrl);
  deeplinkUrl = buildDeepLink(
    deeplinkUrl,
    props.contentType,
    userInfo.teams.companyId,
    props.item.metaDataId,
    url,
    props?.item?.contentInfo?.[0] != undefined
        ? props?.item?.contentInfo?.[0].contentSourceType
        : undefined,
      props.contentType == 4
        ? props.item.contentInfo[0].content?.requestJson?.dataInstanceId != null
          ? props.item.contentInfo[0].content?.requestJson?.dataInstanceId 
          : ""
        : "",
      props.contentType == 4
        ? props.item.contentInfo[0].content.requestJson.template != null
          ? props.item.contentInfo[0].content.requestJson.template.id
          : ""
        : "",
    props?.item?.pinDetail?.pinMetaDataId || props?.pinMetadataId,
    props?.isBulletinBoard,
    props?.item?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL ? true : props?.item?.contentSecurity?.availability?.domainLevel == Constant.LOCAL ? false : null,
  );
  if(!userInfo?.teams?.isClientTenant){
    deeplinkUrl = deeplinkUrl + "&tenantId=" + Constant.TENANT_ID;
  }


  async function copyToClipboard() {
    const el = document.createElement("input");
    el.value = deeplinkUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    setStatus(true);

    updateShareCounter();
  }

  const updateShareCounter = async () => {
    const copyCounterData = {
      metaDataId: props.item.metaDataId,
    };

    await postData(
      copyCounterData,
      APIEndPoints.CONTENT_SHARE_COUNTER(
        props.contentType,
        props?.item?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL ? true : false,
        userInfo?.teams?.companyId
      )
    )
      .then((response) => {
        //console.log(response);
        //TESTED
        TrackEvent(
          TelemetryEventNames.UCC_CONTENT_SHARE_LINK_EVENT,
          userInfo,
          {
            "SectionName": props?.contentType == 4 ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
            : TelemetryEventNames.SECTION_TYPE_CONTENT,
            "ContentTypeId": props.contentType.toString() ?? "",
            "ContentTypeName": getContentTypeNamesById(props.contentType) ?? "",
            "MetadataId": props.item.metaDataId.toString(),
            "UniqueId": props.item.contentUniqueId ?? "",
            "Title": props.item.title ?? "",
            "PublishDate": props.item.publishedDate ?? "",
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="startCopyLink">
      <div className="actionBoxCopyLink">
        <MdCheckCircle className="IconCheckmarkContainer" />
        <div className="linkCreated">
          <span>{!copied ? "Link Created" : "Link copied"}</span>
        </div>
        <div className="secondText">
          <span>
            {!copied
              ? "Make sure you copy the link below."
              : "Press Ctrl+V to use it"}
          </span>
        </div>
        <div className="buttonContainer" disabled={disabled}>
          <div className="linkField" tabIndex={0}>
            <span>{deeplinkUrl}</span>
          </div>
          <div className="copyButton" tabIndex={0} onClick={copyToClipboard} onKeyDown={(e) => {if(e.key === "Enter"){copyToClipboard();}}}>
            <span>Copy</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyLink;
