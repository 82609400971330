import React, { useState, useEffect, useRef, useMemo } from "react";
import { Popup, Input, SearchIcon, CloseIcon } from "@fluentui/react-northstar";
import "./searchAndFilterColumnDynamic.css";
import Constant from "../../utility/constants";
import { Checkbox } from "@fluentui/react-northstar";
import APIEndPoints from "../../utility/apiendpoints";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { postData } from "../../services/customApis";
import { Loader } from "@fluentui/react-northstar";
import { Button } from "@fluentui/react-northstar";
import { UseSearchAndFilterColumnContext } from "../../context/searchandfiltercolumncontext/searchAndFilterColumnContext";

const PAGE_SIZE = Constant.INTERNAL_APPS_GENERAL_CONSTANTS.FIELD_LEVEL_PAGE_SIZE;

export function SearchAndFilterColumnDynamic(props) {
  const { searchAndFilterColumnsData, handleSearchAndFilterColumnsData } = UseSearchAndFilterColumnContext();
  const params = props?.controlParams;
  const controlName = params?.controlObj?.controlName;
  let { userInfo } = UseUserInfoContext();
  const [open, setOpen] = useState(false);
  const [textBoxValue, setTextBoxValue] = useState("");
  const [noRecordsFound, setNoRecordsFound] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({});
  const [filteredColumnResultsApplied, setFilteredColumnResultsApplied] =
    useState([]);
  const [selectedValuesTrue, setSelectedValuesTrue] = useState([]);
  const [width, setWidth] = useState(0);
  const widthRef = useRef(null);
  const popupRef = useRef(null);
  const [isSearchClicked, setIsSearchClicked] = useState(0);
  const [timer, setTimer] = useState(null);
  const filteredColumnResultsApplied_Copy = useRef([]);
  let selectedParams = props?.itemsSelected;
  useEffect(() => {
    if (selectedParams?.length > 0) {
      let selectedPillValues = selectedParams?.map((item) => ({
        key: item,
        label: item,
        name: item,
        selected: true,
      }));
      setSelectedValuesTrue(selectedPillValues);
    }
  }, [selectedParams]);

  const handleClickOutside = async (event, data) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setOpen(false);
      props?.columnFilteringUpdateMethod(
        params?.controlObj,
        params?.searchOptionID,
        params?.eachSection,
        params?.eachRow,
        filteredColumnResultsApplied?.filter((item) => item?.selected === true)
      );
      if (
        textBoxValue?.length > 0
        //&& !textBoxValue?.includes("items selected")
      ) {
        onClickCloseIcon();
      } else if (
        searchAndFilterColumnsData?.[controlName]
          ?.searchText?.length > 0
      ) {
      } else {
        await handleSearchAndFilterColumnsData({
          [controlName]: {
            ...searchAndFilterColumnsData[controlName],
            columnData: filteredColumnResultsApplied,
          },
        });
      }
      let pageInfo =
        searchAndFilterColumnsData?.[controlName]?.pageInfo;
      await setPageInfo(pageInfo);
      // let selectedValues = filteredColumnResultsApplied?.filter((item) => item?.selected === true);
      setSelectedValuesTrue(
        filteredColumnResultsApplied?.filter((item) => item?.selected === true)
      );
      // if (selectedValues?.length > 0) {
      //   setTextBoxValue(`${selectedValues?.length} items selected`)
      // }
    }
  };

  useEffect(() => {
    if (props?.isFromSearch) {
      updateWidth();
      window.addEventListener("resize", updateWidth);
    }
    return () => {
      if (props?.isFromSearch) {
        window.removeEventListener("resize", updateWidth);
      }
    };
  }, []);

  useEffect(() => {
    let selectedDropdownValues = params?.controlObj?.selectedValue?.value;
    if (props?.isLibrarySearch) {
      setLoading(true);
      if (
        (searchAndFilterColumnsData?.[controlName] &&
          Object.keys(
            searchAndFilterColumnsData?.[controlName]
          )?.length > 0) ||
        selectedDropdownValues?.length > 0
      ) {
        let combinedArray = [
          ...(filteredColumnResultsApplied?.filter(
            (item) => item?.selected === true
          ) ?? []),
          ...(searchAndFilterColumnsData?.[controlName]
            ?.columnData ?? []),
        ];
        if (selectedDropdownValues?.length > 0) {
          const formattedSelArray =
            selectedDropdownValues?.map((item) => ({
              name: item,
              key: item,
              label:
                // item.length > 40 ? item.substring(0, 40) + '...' :
                item,
              selected: true,
            })) || [];
          combinedArray = [...combinedArray, ...formattedSelArray];
        }
        let uniqueArray = Object.values(
          combinedArray.reduce((acc, cur) => {
            if (!acc[cur.name] || (acc[cur.name] && cur.selected)) {
              acc[cur.name] = cur;
            }
            return acc;
          }, {})
        );
        uniqueArray.sort((a, b) => {
          if (a.selected === b.selected) {
            return 0;
          }
          return a.selected ? -1 : 1;
        });
        setFilteredColumnResultsApplied(() => {
          return uniqueArray;
        });
        let pageInfo =
          searchAndFilterColumnsData?.[controlName]
            ?.pageInfo;
        setPageInfo(pageInfo);
        let selectedValues = uniqueArray?.filter(
          (item) => item?.selected === true
        );
        if (selectedValues?.length > 0) {
          //setTextBoxValue(`${selectedValues?.length} items selected`);
          setTextBoxValue("");
          noRecordsFound?.length > 0 &&
            setNoRecordsFound(() => {
              return "";
            });
        } else {
          setTextBoxValue("");
          noRecordsFound?.length > 0 &&
            setNoRecordsFound(() => {
              return "";
            });
        }
        setLoading(false);
      }
    }
  }, []);

  const updateWidth = () => {
    if (widthRef.current) {
      const sourceWidth = widthRef.current.offsetWidth;
      setWidth(sourceWidth);
    }
  };
  const removeSelectedValuesCloseIcon = (currentitem) => {
    let filteredColumnResultsAppliedArr = selectedValuesTrue.filter(item => item?.name !== currentitem?.name) ?? [];
    if (filteredColumnResultsApplied?.length > 0) {
      filteredColumnResultsAppliedArr = [...filteredColumnResultsApplied];
      let index = filteredColumnResultsApplied?.findIndex(
        (item) => item?.name === currentitem?.name
      );
      if (index != -1) {
        filteredColumnResultsAppliedArr[index]["selected"] = false;
      }
      setFilteredColumnResultsApplied(() => {
        return filteredColumnResultsAppliedArr;
      });
    }
    setSelectedValuesTrue(() => {
      return filteredColumnResultsAppliedArr.filter(
        (item) => item?.selected === true
      );
    });
    props?.columnFilteringUpdateMethod(
      params?.controlObj,
      params?.searchOptionID,
      params?.eachSection,
      params?.eachRow,
      filteredColumnResultsAppliedArr?.filter((item) => item?.selected === true)
    );

  };

  const setOpenState = () => {
    if (!open) {
      setOpen(() => {
        return true;
      });
    }
  };
  const setLoadingState = () => {
    if (!loading) {
      setLoading(() => {
        return true;
      });
    }
  };
  const textBoxChange = (event, addSpace) => {
    if (addSpace) {
      setTextBoxValue((prevVal) => {
        return prevVal + " ";
      });
    } else {
      setTextBoxValue(event?.target?.value);
    }
    if (event?.target?.value?.length > 2) {
      setNoRecordsFound(() => {
        return "";
      });
      setIsSearchClicked((prevVal) => prevVal + 1);
    } else if (event?.target?.value?.length > 0) {
      setNoRecordsFound(() => {
        return Constant.SEARCH_AND_FILTER_COM_CONSTANTS.MINIMUM_CHARACTERS;
      });
    } else {
      setNoRecordsFound(() => {
        return "";
      });
      setFilteredColumnResultsApplied(() => {
        return removeDuplicates(
          filteredColumnResultsApplied,
          filteredColumnResultsApplied_Copy.current
        )
      });
    }

  };
  const sortDropdown = async () => {
    let selectedDropdownValues = params?.controlObj?.selectedValue?.value;
    if (selectedDropdownValues?.length > 0) {
      let filteredColumnResultsAppliedCopy = [...filteredColumnResultsApplied];
      let uniqueArray = Object.values(
        filteredColumnResultsAppliedCopy.reduce((acc, cur) => {
          acc[cur.name] = cur;
          return acc;
        }, {})
      );
      uniqueArray.sort((a, b) => {
        if (a.selected === b.selected) {
          return 0;
        }
        return a.selected ? -1 : 1;
      });
      setFilteredColumnResultsApplied(() => {
        return uniqueArray;
      });
    }
  };
  const specialCharactersValidation = () => {
    let allowedSplChars = props?.specialCharacters;
    let regex = new RegExp(allowedSplChars?.replace("/", "//"));
    if (!regex.test(textBoxValue?.replace(/\s+/g, ""))) {
      setNoRecordsFound(() => {
        return Constant.SEARCH_AND_FILTER_COM_CONSTANTS
          .SPECIAL_CHARACTERS_NOT_ALLOWED;
      });
      return false;
    }
    return true;
  };
  const onClickSearchIcon = async () => {
    if (specialCharactersValidation(textBoxValue)) {
      setLoadingState();
      isSearchClicked < 2 &&
        (await handleSearchAndFilterColumnsData({
          [controlName]: {
            columnData: filteredColumnResultsApplied,
            pageInfo: pageInfo,
            searchText: textBoxValue,
          },
        }));
      setOpen(true);
      if (textBoxValue?.length < 3) {
        setNoRecordsFound(() => {
          return Constant.SEARCH_AND_FILTER_COM_CONSTANTS.MINIMUM_CHARACTERS;
        });
        setLoading(() => {
          return false;
        });
        let uniqueArray = Object.values(
          filteredColumnResultsApplied.reduce((acc, cur) => {
            acc[cur.name] = cur;
            return acc;
          }, {})
        );
        uniqueArray.sort((a, b) => {
          if (a.selected === b.selected) {
            return 0;
          }
          return a.selected ? -1 : 1;
        });
        setFilteredColumnResultsApplied(() => {
          return uniqueArray;
        });
        return;
      } else {
        await setFilteredColumnResultsApplied(() => {
          return filteredColumnResultsApplied?.filter(
            (item) => item?.selected === true
          );
        });
        if (textBoxValue?.length > 3) {
          await performSearchOnColumn(false, true);
        }
        else {
          setLoading(false);
        }
      }
    }
  };
  const onClickCloseIcon = async (isCloseClicked) => {
    let updatedArray = [];
    let text = textBoxValue;
    setLoading(true);
    setTextBoxValue("");
    noRecordsFound?.length > 0 &&
      setNoRecordsFound(() => {
        return "";
      });
    if (
      isCloseClicked
      //&& text?.includes("items selected")
    ) {
      setOpenState();
      if (
        filteredColumnResultsApplied?.filter((item) => item?.selected === true)
          ?.length > 0
      ) {
        updatedArray = filteredColumnResultsApplied?.map((item) => {
          if (item?.selected === true) {
            return {
              ...item,
              selected: false,
            };
          } else {
            return item;
          }
        });
        await setFilteredColumnResultsApplied(() => {
          return updatedArray;
        });
        props?.columnFilteringUpdateMethod(
          params?.controlObj,
          params?.searchOptionID,
          params?.eachSection,
          params?.eachRow,
          updatedArray?.filter((item) => item?.selected === true),
          true
        );
      }
      await handleSearchAndFilterColumnsData({
        [controlName]: {
          ...searchAndFilterColumnsData[controlName],
          searchText: "",
          columnData:
            updatedArray?.length > 0
              ? updatedArray
              : filteredColumnResultsApplied,
        },
      });
    } else {
      await handleSearchAndFilterColumnsData({
        [controlName]: {
          ...searchAndFilterColumnsData[controlName],
          searchText: "",
        },
      });
      await loadContextData(isCloseClicked);
    }
    await setLoading(false);
  };
  const removeDuplicates = (arr1, arr2) => {
    let combinedArray = [...arr1, ...arr2];
    let uniqueArray = Object.values(
      combinedArray.reduce((acc, cur) => {
        if (!acc[cur.name] || (acc[cur.name] && cur.selected)) {
          acc[cur.name] = cur;
        }
        return acc;
      }, {})
    );
    uniqueArray.sort((a, b) => {
      if (a.selected === b.selected) {
        return 0;
      }
      return a.selected ? -1 : 1;
    });
    if (uniqueArray?.length > 0) {
      return uniqueArray;
    } else {
      return [];
    }
  };
  const loadContextData = async (isCloseClicked) => {
    if (
      searchAndFilterColumnsData?.[controlName] &&
      Object.keys(searchAndFilterColumnsData?.[controlName])
        ?.length > 0
    ) {
      let combinedArray = [
        ...(filteredColumnResultsApplied?.filter(
          (item) => item?.selected === true
        ) ?? []),
        ...(searchAndFilterColumnsData?.[controlName]
          ?.columnData ?? []),
      ];
      let uniqueArray = Object.values(
        combinedArray.reduce((acc, cur) => {
          if (!acc[cur.name] || (acc[cur.name] && cur.selected)) {
            acc[cur.name] = cur;
          }
          return acc;
        }, {})
      );
      uniqueArray.sort((a, b) => {
        if (a.selected === b.selected) {
          return 0;
        }
        return a.selected ? -1 : 1;
      });
      if (uniqueArray?.length > 0) {
        await setFilteredColumnResultsApplied(() => {
          return uniqueArray;
        });
      }
      if (isCloseClicked) {
        let pageInfo =
          searchAndFilterColumnsData?.[controlName]
            ?.pageInfo;
        pageInfo.rowNumber =
          pageInfo.totalCount >= pageInfo.rowNumber
            ? pageInfo.rowNumber + PAGE_SIZE
            : 0;
        await setPageInfo(pageInfo);
      }
      return uniqueArray ?? [];
    }
  };

  const performSearchOnFirstClick = async (isFromOnClick) => {
    await setOpenState();
    if (isFromOnClick && !filteredColumnResultsApplied.length > 0) {
      await performSearchOnColumn(true);
    } else {
      if (noRecordsFound?.length > 0) {
        setNoRecordsFound(() => {
          return "";
        });
      }
      if (
        searchAndFilterColumnsData?.[controlName]
          ?.searchText?.length > 0
      ) {
        await onClickCloseIcon(false);
      } else {
        // if (textBoxValue?.length > 0) {
        //   setTextBoxValue("");
        //   setNoRecordsFound(() => { return "" });
        // }
        await sortDropdown();
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  };
  const performSearchOnColumn = async (isFromOnClick, clearExistingData) => {
    if (
      !clearExistingData &&
      !isFromOnClick &&
      pageInfo != undefined &&
      pageInfo?.rowNumber == pageInfo?.totalCount
    ) {
      !loading && setLoading(false);
      // return;
    } else {
      await setOpenState();
      await setLoadingState();
      await sortDropdown();
      let requestJSON = {
        filters: {
          fields: props.searchFilters ?? {},
        },
        pageInfo: {
          pageLength: PAGE_SIZE,
          pageNumber:
            clearExistingData || isFromOnClick
              ? 1
              : pageInfo?.rowNumber / PAGE_SIZE + 1,
        },
      };
      const columnSearchPayload = {
        templateId: props?.templateDetails?.templateId?.toString() ?? "",
        fieldName: props?.appDetails?.appID ? props?.fieldName : null,
        searchText: textBoxValue?.trim(),
        searchQuery: props?.appSearchQueryValue,
        appId: props?.appDetails?.appID,
        requestJson: JSON.stringify(requestJSON),
      };
      await postData(
        columnSearchPayload,
        APIEndPoints.GET_DATA_LIBRARY_COLUMN_FILTER_DATA(
          props?.appDetails?.contentTypeId,
          userInfo?.teams?.companyId
        )
      )
        .then(async (filterValuesResponseColumn) => {
          if (filterValuesResponseColumn?.data?.status === "success") {
            if (
              filterValuesResponseColumn?.data?.resultData?.values?.length < 1
            ) {
              setNoRecordsFound(() => {
                return Constant.SEARCH_AND_FILTER_COM_CONSTANTS.NO_RECORD_FOUND;
              });
              setLoading(false);
              return;
            } else {
              setNoRecordsFound(() => {
                return "";
              });
            }
            if (
              filterValuesResponseColumn?.data?.resultData?.values?.length > 0
            ) {
              if (
                Object.keys(
                  filterValuesResponseColumn?.data?.resultData?.pageInfo
                )?.length > 0
              ) {
                setPageInfo(
                  filterValuesResponseColumn?.data?.resultData?.pageInfo
                );
                if (isFromOnClick) {
                  await handleSearchAndFilterColumnsData({
                    [controlName]: {
                      ...searchAndFilterColumnsData[
                      controlName
                      ],
                      pageInfo:
                        filterValuesResponseColumn?.data?.resultData?.pageInfo,
                    },
                  });
                }
              } else {
                setLoading(false);
                return;
              }
              const existingSelectedValues =
                filteredColumnResultsApplied?.filter(
                  (item) => item?.selected === true
                );
              const filterValuesResponseColumnArr =
                filterValuesResponseColumn?.data?.resultData?.values?.map(
                  (item) => ({
                    name: item,
                    key: item,
                    label:
                      //item.length > 40 ? item.substring(0, 40) + '...' :
                      item,
                    selected: false,
                  })
                ) || [];
              if (filteredColumnResultsApplied.length > 0) {
                clearExistingData
                  ? setFilteredColumnResultsApplied(() => {
                    return removeDuplicates(
                      existingSelectedValues,
                      filterValuesResponseColumnArr
                    );
                  })
                  : isFromOnClick
                    ? setFilteredColumnResultsApplied(() => {
                      return [...filterValuesResponseColumnArr];
                    })
                    : (() => {
                      let removeDuplicatesFilterValues = removeDuplicates(
                        filteredColumnResultsApplied,
                        filterValuesResponseColumnArr
                      );
                      setFilteredColumnResultsApplied(removeDuplicatesFilterValues);
                      filteredColumnResultsApplied_Copy.current = removeDuplicatesFilterValues;

                    })()
              } else {
                // setFilteredColumnResultsApplied(() => {
                //   return filterValuesResponseColumnArr;
                // });
                setFilteredColumnResultsApplied(() => {
                  return removeDuplicates(
                    selectedValuesTrue,
                    filterValuesResponseColumnArr
                  );
                })
                filteredColumnResultsApplied_Copy.current = filterValuesResponseColumnArr;

              }
            }
            setLoading(false);
          } else {
            setNoRecordsFound(() => {
              return Constant.SEARCH_AND_FILTER_COM_CONSTANTS.NO_RECORD_FOUND;
            });
            setLoading(false);
          }
        })
        .catch((apiError) => {
          setNoRecordsFound(() => {
            return Constant.SEARCH_AND_FILTER_COM_CONSTANTS
              .UNABLE_TO_FETCH_DATA;
          });
          setLoading(false);
        });
    }
  };

  const checkboxChanged = (event, checkboxProps) => {
    let filteredColumnResultsAppliedArr = [...filteredColumnResultsApplied];
    let index = filteredColumnResultsApplied?.findIndex(
      (item) => item?.name === checkboxProps?.name
    );
    if (index != -1) {
      filteredColumnResultsAppliedArr[index]["selected"] =
        checkboxProps?.checked;
    }
    setFilteredColumnResultsApplied(() => {
      return filteredColumnResultsAppliedArr;
    });
  };

  useEffect(() => {
    const dropdownOptions = document?.getElementById(
      "filteredColumnResultsCheckBox_Dynamic"
    );
    const handleScroll = () => {
      if (!loading) {
        if (
          dropdownOptions.scrollHeight - dropdownOptions.scrollTop <=
          dropdownOptions.clientHeight + 10
        ) {
          if (
            pageInfo.rowNumber <= pageInfo.totalCount &&
            !noRecordsFound.length > 0
          ) {
            if (pageInfo.rowNumber == pageInfo.totalCount) {
              return;
            }
            setLoading(true);
            setTimeout(() => {
              performSearchOnColumn();
            }, 1000);
          }
        }
      }
    };
    dropdownOptions?.addEventListener("scroll", handleScroll);
    return () => dropdownOptions?.removeEventListener("scroll", handleScroll);
  }, [pageInfo, loading]);

  const popupContent = (
    <div>
      {loading == true && filteredColumnResultsApplied?.length == 0 ? (
        <div className={`filteredColumnResultsCheckBoxLoader_Dynamic`}>
          <Loader />
        </div>
      ) : <> {loading == false && noRecordsFound.length > 0 &&
        <div className="recordsMessage_Dynamic">{noRecordsFound}</div>}
        {<div key={controlName}>
          {filteredColumnResultsApplied?.length > 0 ? (
            <div
              className="filteredColumnResultsCheckBox_Dynamic"
              id="filteredColumnResultsCheckBox_Dynamic"
            >
              {filteredColumnResultsApplied.map((item) => (
                <div className="checkBoxDiv_Dynamic">
                  <Checkbox
                    key={item?.key}
                    name={item?.name}
                    label={item?.label}
                    onChange={checkboxChanged}
                    checked={item?.selected}
                    title={item?.name}
                    className={`checkBoxClass_Dynamic ${item?.selected ? "showOnlyOnChecked_Dynamic" : ""
                      }`}
                  />

                </div>
              ))}
              {loading && (
                <div className={`filteredColumnResultsCheckBoxLoader_Dynamic`}>
                  <Loader />
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        }</>}
    </div>
  );
  useEffect(() => {
    if (isSearchClicked != 0) {
      if (timer) {
        clearTimeout(timer);
      }
      const newTimer = setTimeout(() => {
        onClickSearchIcon();
      }, 750);
      setTimer(newTimer);
      return () => clearTimeout(newTimer);
    }
  }, [isSearchClicked]);
  return (
    <div ref={popupRef}>
      <Popup
        open={open}
        position="below"
        offset={() => [0, 4]}
        onOpenChange={(event, data) => {
          handleClickOutside(event, data);
        }}
        closeOnScroll={false}
        trigger={
          <div ref={widthRef}>
            <Input
              placeholder="Click to make a selection"
              clearable
              value={textBoxValue}
              onChange={(e) => {
                textBoxChange(e);
              }}
              onClick={() => {
                performSearchOnFirstClick(true);
              }}
              id={controlName}
              className={`search-filter-input_Dynamic ${controlName}`}
              // icon={
              //   <div className="search-filter-icons_Dynamic">
              //     {textBoxValue?.length >= 1 && (
              //       <CloseIcon
              //         title="clear"
              //         className="clear-icon_Dynamic"
              //         onClick={() => {
              //           onClickCloseIcon(true);
              //         }}
              //       />
              //     )}
              //     {/* <SearchIcon
              //       className="search-icon_Dynamic"
              //       onClick={() => {
              //         setIsSearchClicked((prevVal) => prevVal + 1);
              //       }}
              //       title="search"
              //     /> */}
              //   </div>
              // }
              onKeyDown={(event) => {
                if (event?.key === "Enter") {
                  setIsSearchClicked((prevVal) => prevVal + 1);
                } else if (event?.key === " ") {
                  textBoxChange(event, true);
                }
              }}
            />
          </div>
        }
        content={{
          content: popupContent,
          styles: { width: `${width}px` },
          className: `${loading &&
            (filteredColumnResultsApplied == undefined ||
              filteredColumnResultsApplied == null ||
              filteredColumnResultsApplied?.length == 0)
            ? "initialLoaderSearchFilter_Dynamic"
            : "popupSearchFilter_Dynamic"
            }`,
        }}
      // trapFocus
      />
      {selectedValuesTrue?.length > 0 && (
        <div className="searchFilterPillsParent_Dynamic">
          {" "}
          {selectedValuesTrue.map(
            (item) => (
              (
                <div
                  className="searchFilterPills_span_Dynamic"
                  title={item?.label}
                >
                  <p>{item?.label} </p>
                  <CloseIcon
                    className="closeIcon-SearchFilterPills_Dynamic"
                    onClick={() => {
                      removeSelectedValuesCloseIcon(item);
                    }}
                  />
                </div>
              )
            )
          )}
        </div>
      )}
    </div>
  );
}
