import React, { useState, useEffect, useRef, useMemo } from "react";
import DataGrid from "../../../commonui/datagrid/dataGrid";
import { UseUserInfoContext } from "../../../context/usercontext/userContext";
import ReusableLoader from "../../../commonui/reusableloader/reusableLoader";
import { utils } from "../../../commonui/datagrid/utils";
import {
  useMenuContext,
  useHamburgerMenuContext,
} from "../../../context/menucontext/menuContext";
import sideNavMainMenuData from "../../../data/menu/sideNavMainMenuData";
import APIEndPoints from "../../../utility/apiendpoints";
import { getData, postData } from "../../../services/customApis";
import PageNotFound from "../../../commonui/pagenotfound/pageNotFound";
import UnauthorizedPage from "../../../commonui/unauthorizedpage/unauthorized";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import Constant from "../../../utility/constants";
import { TbDatabaseSearch } from "react-icons/tb";
import { UseToasterNotificationsContext } from "../../../context/toasternotificationscontext/toasterNotificationsContext";
import { getPrivilegeValue, downloadExcelFile } from "../../../utility/utility";
import { Button, Text } from '@fluentui/react-northstar'
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { UseLibrarySearchFormContext } from "../../../context/librarysearchformcontext/librarySearchFormContext";
import { BackButton } from "../../../commonui/backbutton/backButton";
import { HiUpload } from "react-icons/hi";
import { GrClose } from "react-icons/gr";
import { MdCloudUpload } from "react-icons/md";
import UploadData from "../../../commonui/uploaddata/UploadData";
import DialogBox from "./../../../commonui/dialogBox/DialogBox";
import { formatBytes } from "../../../utility/utility";
import PostWithProgressEvent from "../../../services/postWithProgressEvent";
import { AiFillExclamationCircle } from "react-icons/ai";
import Attachment from "../../../commonui/attachment/Attachment";
import { AiFillFileText } from "react-icons/ai";
import FooterTechButtons from "../../../commonui/commonformcomponents/footertechbuttons/FooterTechButtons";
import { CiCircleInfo } from "react-icons/ci";
import FootNoteSearchForm from "../../../data/librarySearchForm/footNoteSearchForm.json";
import PowerBiReport from "../../../components/visualizations/pinlibrary/pinlibrarydetails/pbireport/pbiReport";
import "./libraryGrid.css";

const EDIT_CONFIG_LIMIT = Constant.INTERNAL_APPS_GENERAL_CONSTANTS.EDIT_LIMIT;
const CHARACTER_CONFIG_LIMIT = Constant.INTERNAL_APPS_GENERAL_CONSTANTS.CHARACTER_LIMIT;
const CANCEL_TEXT = Constant.INTERNALAPP_CONSTANTS.BTN_CANCEL;
const SUBMIT_TEXT = Constant.INTERNALAPP_CONSTANTS.BTN_SUBMIT;

const LibraryGrid = () => {
  const { librarySearchFormJsonData, handleLibrarySearchFormJsonData } =
    UseLibrarySearchFormContext();
  const location = useLocation();
  const navigate = useNavigate();
  let internalAppDetails = location.state?.appDetails ?? {};
  let formJsonDetails = librarySearchFormJsonData?.jsonStructure ?? {};
  let templateDetails = location.state?.templateDetails ?? {};
  let seeDetailsGridData = librarySearchFormJsonData.powerKLibrarySearchGridData;
  let executedData = librarySearchFormJsonData.powerKLibrarySearchGridDataLastRow;
  let { userInfo } = UseUserInfoContext();
  const PAGE_SIZE = internalAppDetails?.contentTypeId == Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS
  ? Constant.INTERNAL_APPS_GENERAL_CONSTANTS.PAGE_SIZE_TPALERTS
  : Constant.INTERNAL_APPS_GENERAL_CONSTANTS.PAGE_SIZE;
  const [loading, setLoading] = useState(false);
  const [hasMoreData, sethasMoreData] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [gridData, setGridData] = useState(undefined);
  const { menuState, handleChangeNav } = useMenuContext();
  const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();
  const [currentUser, setCurrentUser] = useState(userInfo);
  const [errorAccessDenied, setAccessDenied] = useState(false);
  const [errorApiCall, setErrorApiCall] = useState(false);
  const [editConfigLimit, setEditConfigLimit] = useState(EDIT_CONFIG_LIMIT);
  const { notificationMsgsData, handleNotificationMsgsData } = UseToasterNotificationsContext();
  const [disableDownload, setDisableDownload] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [disablePublish, setDisablePublish] = useState(false);
  const [disableActionItems, setShowActionItems] = useState(false);
  const [dynamicHeaderDataLibrary, setDynamicHeaderDataLibrary] = useState([]);
  const [columnsValuesArray, setDynamicGridDataLibrary] = useState([]);
  const [isVisibleSeeDetails, setIsVisibleSeeDetails] = useState(false);
  const [matches, setMatches] = useState(librarySearchFormJsonData.totalRecordsCount);
  const [internalAppAdmin, setInternalAppAdmin] = useState(false);
  const [searchQueryValue, setSearchQueryValue] = useState(librarySearchFormJsonData.searchQuery);
  const [searchFilterObjectForPayload, setSearchFilterObjectForPayload] = useState(librarySearchFormJsonData.appliedFilteredValues)
  const [seeMoreMetaData, setSeeMoreMetaData] = useState([]);
  const receivedSeeMoreData = useRef("");
  const resultDynamicHeaderData = useRef([]);
  const [privilagesForInternalApp, setPrivilagesForInternalApp] = useState(location.state?.privilagesPowerKApp ?? {});
  const [apiGridData, setApiGridData] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [newFiles, setNewFiles] = useState([]);
  const [isExcel, setIsExcel] = useState(false);
  const [attachmentValidationMsg, setAttachmentValidationMsg] = useState({
    message: "",
    isValid: true,
    isError: false,
  });

  const [isDropzoneEnabled, setIsDropzoneEnabled] = useState(true);
  const [newAttachments, setNewAttachments] = useState([]);
  const [specialCharacters, setSpecialCharacters] = React.useState();
  const { progress } = PostWithProgressEvent();
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [removeAnimation, setRemoveAnimation] = React.useState(false);
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true);
  const [searchIdValue, setSearchIdValue] = useState(undefined);
  const [pbReportConfig, setPBReportConfig] = useState({});

  const contentTypeMappingForTPAlerts = {
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS]: true,
  };
  const contentTypeMappingDatabaseLinkShow = {
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK]: true,
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS]: true,
  };
  const contentTypeMappingCancelButtonShow = {
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS]: true,
  };
  const validContentTypeIdsForDirectLibraryPage = new Set([
    Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS,
    //Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES,
    // Add more content types as needed
  ]);
  const exportFilrNamesMapping = {
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK]: Constant.INTERNALAPP_CONSTANTS.POWERK_LIBRARY_GRID_EXPORT_NAME,
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS]: Constant.INTERNALAPP_CONSTANTS.TPALERTS_LIBRARY_GRID_EXPORT_NAME,
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES]: Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_LIBRARY_GRID_EXPORT_NAME,
  };
  const privilegeMapping = {
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK]: 'ViewDatabase',
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS]: 'ViewDatabase',
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES]: 'ViewDatabase',
    // Add more content types here as needed
  };
  const specialContentTypesMappingForInternalApps = {
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK]: true,
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES]: true,
    // Add more content types here as needed
  };
  const contentTypeMappingForRibbonShowcase = {
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK]: true,
  };
  const tpAlerts = contentTypeMappingForTPAlerts[internalAppDetails?.contentTypeId] || false;
  const isUploadReferenceGuideDisplay = getPrivilegeValue(privilagesForInternalApp, "uploadReferenceGuide", "uploadReferenceGuideDisplay");
  const isUploadReferenceGuideEnabled = getPrivilegeValue(privilagesForInternalApp, "uploadReferenceGuide", "uploadReferenceGuideEnabled");

  const loadMoreData = (pageNo_) => {
    setPageNo(pageNo_ + 1);
  };

  const setBreadCrumbValue = () => {
    menuState?.menu?.name == "Apps" &&
      handleChangeNav({
        section: {
          id: sideNavMainMenuData[3]?.id,
          name: "",
        },
        menu: {
          id: sideNavMainMenuData[3]?.menu[0]?.id,
          name: "Apps",
        },
        submenu: {
          id: sideNavMainMenuData[3]?.menu[0]?.submenu[0]?.id,
          name: internalAppDetails?.breadCrumb,
        },
        data: "",
        to: "/apps/LibraryGrid",
      });
  };
  const setPrivilageForInternalApps = () => {
    const contentTypeId = internalAppDetails?.contentTypeId;
    const privilegeName = privilegeMapping[contentTypeId];
    const isAdmin = privilegeName && getPrivilegeValue(privilagesForInternalApp, privilegeName, 'display');
    setInternalAppAdmin(isAdmin || false);
  };

  const toggleHamburgerMenu = () => {
    handleHamburgerChange();
  };
  const getGridData = async (reloadTheGrid = false, pgNo) => {
    let searchFiltersString = JSON.stringify(searchFilterObjectForPayload);
    let searchFiltersEncoded = JSON.parse(searchFiltersString)
    Object.entries(searchFiltersEncoded).forEach(([key, values]) => {
      if (Array.isArray(values)) {
        searchFiltersEncoded[key] = values.map(value => btoa(unescape(encodeURIComponent(value))));
      }
    });
    const formData = new FormData();
    const pNo = reloadTheGrid ? pgNo : pageNo;
    let params = {};

    if (internalAppDetails) {
      if (internalAppDetails?.version == Constant.SEARCH_TEMPLATE_VERSIONS.NEW_TEMPLATE) {
        params = {
          pageLength: PAGE_SIZE,
          pageNumber: pNo,
          sortBy: {
            type: "",
            order: "",
          },
          filters: {
            fileDetail: {},
            fields: searchFiltersEncoded,
          },
          searchQuery: searchQueryValue
        };
        if (internalAppDetails?.contentTypeId == Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK)
          params["version"] = internalAppDetails?.version;
        formData.append("RequestJson", JSON.stringify(params));
        formData.append("AppId", internalAppDetails.appID);
        formData.append("isInternalApp", internalAppDetails.isInternal);
        formData.append("TemplateId", templateDetails?.templateId?.toString() ?? "");
      }
      else {
        params = {
          pageLength: PAGE_SIZE,
          pageNumber: pNo,
          sortBy: {
            type: "",
            order: "",
          },
          filters: {
            fileDetail:
              specialContentTypesMappingForInternalApps[internalAppDetails?.contentTypeId] ?
                {
                  actualFileName: "librarySearchForm.json"
                } :
                {},
            fields: searchFiltersEncoded,
          }
        };
        formData.append("RequestJson", JSON.stringify(params));
        formData.append("AppId", internalAppDetails.appID);
        formData.append("isInternalApp", internalAppDetails.isInternal);
        formData.append("TemplateId", templateDetails?.templateId?.toString() ?? "");
        if (specialContentTypesMappingForInternalApps[internalAppDetails?.contentTypeId]) {
          const jsonStr = JSON.stringify(formJsonDetails);
          const file = new Blob([jsonStr], { type: 'application/json' });
          formData.append("Attachments", file, 'librarySearchForm.json');
        }
        formData.append("searchQuery", searchQueryValue);
      }
    }
    try {
      const powerKDBData = await postData(
        formData,
        APIEndPoints.CONTENT_DATALIBRARY_INTERNALAPPS(
          internalAppDetails?.contentTypeId,
          userInfo?.teams?.companyId
        ),
        true
      );
      const { searchQuery, sections } = powerKDBData?.data[0]?.resultData ?? {};
      setSearchIdValue(searchQuery);
      const resultPowerKLibraryData = sections?.all ?? [];
      if (Object.keys(searchFilterObjectForPayload).length === 0) {
        setSearchQueryValue(powerKDBData?.data[0]?.resultData?.searchQuery);
      }
      if (resultPowerKLibraryData.length > 0) {
        const lastRow =
          resultPowerKLibraryData[resultPowerKLibraryData.length - 1];
        const pageInfo = lastRow?.customData?.pageinfo;
        if (pageInfo) {
          const { rowIndex, totalCount } = pageInfo;
          if (Object.keys(searchFilterObjectForPayload).length > 0 && librarySearchFormJsonData.totalRecordsCount.length > 0) {
            setMatches(librarySearchFormJsonData.totalRecordsCount);
          }
          else if (Object.keys(searchFilterObjectForPayload).length === 0) {
            setMatches(totalCount);
          }
          if (rowIndex === totalCount) {
            sethasMoreData(false);
          }
          else {
            sethasMoreData(true);
          }
        }
      }
      setApiGridData(resultPowerKLibraryData);
      return resultPowerKLibraryData;
    } catch (error) {
      console.error(error);
      setLoading(false);
      if (
        error?.response?.data?.Errors?.[0]?.toLowerCase() === "access denied."
      ) {
        setAccessDenied(true);
      } else {
        setErrorApiCall(true);
      }
    }
  };
  const getDynamicHeaderData = async () => {
    let params = {
      isInternal: false,
      appId: null,
      templateId: templateDetails?.templateId?.toString() ?? "",
      customData: {},
    };
    // if internalAppDetails is undefined below code wont run
    if (internalAppDetails) {
      params = {
        isInternalApp: internalAppDetails.isInternal,
        appId: internalAppDetails.appID,
        templateId: templateDetails?.templateId?.toString() ?? "",
        customData: {},
      };
    }
    // making api call with params
    await postData(
      params,
      APIEndPoints.GET_LIBRARY_HEADERS_TEMPLATE_DETAILS(
        internalAppDetails?.contentTypeId,
        userInfo?.teams?.companyId
      )
    )
      .then((response) => {
        resultDynamicHeaderData.current =
          response?.data?.resultData?.sections?.all?.headerFields;
        internalAppDetails["headerDataResponse"] = response?.data?.resultData?.sections?.all?.headerFields;
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (
          error?.response?.data?.Errors?.[0]?.toLowerCase() == "access denied."
        ) {
          setAccessDenied(true);
        } else {
          setErrorApiCall(true);
        }
      });
    return resultDynamicHeaderData.current;
  };
  // Function to toggle the visibility of the div
  const toggleDiv = () => {
    setIsVisibleSeeDetails(!isVisibleSeeDetails);
  };

  useEffect(() => {
    if (newAttachments?.length > 0) {
      setIsConfirmButtonDisabled(false);
    } else {
      setIsConfirmButtonDisabled(true);
    }
  }, [newAttachments]);

  useEffect(() => {
    if (resultDynamicHeaderData.current?.length > 0 && apiGridData) {
      const dynamicLibraryGridData = apiGridData?.filter(({ promptResponse }) =>
        promptResponse !== null)?.map((currentItem) => {
          const dynamicDataObject = {
            metaDataId: currentItem?.metaDataId,
            title: currentItem?.title,
            customData: currentItem?.customData
          };
          let isChangeImpactApplicable = false;
          resultDynamicHeaderData.current.forEach((item) => {
            if (item?.fieldType?.toLowerCase() === Constant.INTERNALAPP_FIELD_VALUES.METADATA_FIELD) {
              if (currentItem?.hasOwnProperty(item?.responsePropertyInfo?.responsePropertyName)) {
                dynamicDataObject[item.fieldUniqueId] = currentItem[item.responsePropertyInfo.responsePropertyName];
              }
            } else if (Constant.INTERNALAPPS_FIELDTYPES.includes(item?.fieldType?.toLowerCase())) {
              if (item?.fieldType === Constant.INTERNALAPPS_FIELDTYPES[1] && item?.fieldUniqueId === Constant.INTERNALAPP_CONSTANTS.DOES_CHANGE_IMPACT_TP_DOC_FIELD_UNIQUE_ID) {
                if (currentItem?.promptResponse?.[0]?.promptFields?.find(
                  promptItem => (promptItem[item.responsePropertyInfo.responsePropertyName] === item.responsePropertyInfo.value && promptItem?.value === "Yes"))) {
                  isChangeImpactApplicable = true;
                }
              }
              const filteredPromptField = currentItem?.promptResponse?.[0]?.promptFields?.find(
                promptItem => promptItem[item.responsePropertyInfo.responsePropertyName] == item.responsePropertyInfo.value
              );
              if (filteredPromptField) {
                const isComparisonField = filteredPromptField.promptQuestionUniqueId === Constant.INTERNALAPP_CONSTANTS.HOW_DOES_IT_CHANGE_TP_DOC_FIELD_UNIQUE_ID;
                const fieldValue = isComparisonField
                  ? (isChangeImpactApplicable ? (
                    Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_DETALINK_COLUMN_MSG
                  ) : "N/A")
                  : filteredPromptField.value;

                dynamicDataObject[item.fieldUniqueId] = fieldValue;
              }
            }
          });
          if (currentItem?.documentInfo && currentItem?.documentInfo[0]) {
            dynamicDataObject["rawDocumentId"] = currentItem?.documentInfo[0].rawDocumentId;
            dynamicDataObject["templateId"] = currentItem?.documentInfo[0].templateId;
            dynamicDataObject["searchIndex"] = currentItem?.documentInfo?.[0]?.searchIndex;
          }
          return dynamicDataObject;
        });
        // if (internalAppDetails?.version == Constant.SEARCH_TEMPLATE_VERSIONS.OLD_TEMPLATE) {
        //   console.log("FootNote Search JSON", FootNoteSearchForm);
        //   setLoading(true);
        //   let templatID = FootNoteSearchForm?.templateConfig.VizualisationReportId;
        //   const decodeUniqeId = atob(searchQueryValue);
        //   let obj = {
        //     templateid: templatID,
        //     uniqueid: decodeUniqeId,
        //   };
  
        //   let reportDetail = [];
        //   reportDetail.push(obj);
  
        //   let postParams = {
        //     contentType: internalAppDetails?.contentTypeId,
        //     reportDetail: reportDetail[0],
        //   };
        //   getPowerBIReportDetails(postParams, userInfo);
        // }
      setGridData(pageNo == 1 ? dynamicLibraryGridData : [...gridData, ...dynamicLibraryGridData]);
    }
  }, [resultDynamicHeaderData.current, apiGridData]);
  // const getPowerBIReportDetails = async (postParams, userInfo) => {
  //   try {
  //     const tokenDetails = await postData(
  //       postParams,
  //       APIEndPoints.PBREPORT_TOKEN_ENDPOINT(userInfo?.teams?.companyId),
  //       false,
  //       true
  //     );
  //     console.log("Token Details", tokenDetails);
  //     setPBReportConfig(tokenDetails.data);
  //   } catch (err) {
  //     console.log(err);
  //     if (err?.response?.data?.Errors[0]?.toLowerCase() === "access denied.") {
  //       setAccessDenied(true);
  //     } else {
  //       setErrorApiCall(true);
  //     }
  //   }
  // }

  //whenever the below method gets called with true as param e.g getInitialData(true) ,the grid will reload from the begining
  const getInitialData = async (reloadTheGrid = false, isFromColumnLevelFilters) => {
    const { teams } = userInfo || {};
    if (teams?.key) {
      setLoading(true);
      await getGridData(reloadTheGrid, 1);
      !isFromColumnLevelFilters && setLoading(false);
      if (receivedSeeMoreData.current?.length < 1 && specialContentTypesMappingForInternalApps[internalAppDetails?.contentTypeId] && internalAppDetails?.version == Constant.SEARCH_TEMPLATE_VERSIONS.OLD_TEMPLATE) {
        const formDataMetaData = new FormData();
        const filteredMetaDataParams = {
          filters: {
            fileDetail:
              specialContentTypesMappingForInternalApps[internalAppDetails?.contentTypeId] ?
                {
                  actualFileName: "librarySearchForm.json"
                } :
                {},
          },
          "searchQuery": searchQueryValue
        }
        formDataMetaData.append("RequestJson", JSON.stringify(filteredMetaDataParams));
        formDataMetaData.append("AppId", internalAppDetails.appID);
        formDataMetaData.append("TemplateId", templateDetails?.templateId?.toString() ?? "");
        if (specialContentTypesMappingForInternalApps[internalAppDetails?.contentTypeId]) {
          const jsonStr = JSON.stringify(formJsonDetails);
          const file = new Blob([jsonStr], { type: 'application/json' });
          formDataMetaData.append("Attachments", file, 'librarySearchForm.json');
        }
        try {
          const powerKMetaData = await postData(
            formDataMetaData,
            APIEndPoints.GET_FILTERED_DATA_LIBRARY_META_DATA(
              internalAppDetails?.contentTypeId,
              userInfo?.teams?.companyId
            ),
            true
          );
          if (powerKMetaData?.data[0]?.status === "success") {
            const receivedMetaData = powerKMetaData?.data[0]?.resultData;
            const withoutOverallresponse = receivedMetaData.filter((item) => item?.value !== "");
            const mergeSortedResponse = withoutOverallresponse.sort((a, b) => {
              if (a?.fieldName < b?.fieldName) {
                return -1;
              }
              else if (a?.fieldName > b?.fieldName) {
                return 1;
              }
              return 0;
            })
            mergeSortedResponse.push(receivedMetaData.filter((item) => item?.value === "")[0]);
            setSeeMoreMetaData(mergeSortedResponse);
            //  setConcatSeeMoreData(receivedMetaData[lastItemIndex])
            receivedSeeMoreData.current = "success";
          }
          else {
            console.log(powerKMetaData?.data?.length > 0 ? powerKMetaData.data[0].errorMessages : "No data available or data array is empty");
            receivedSeeMoreData.current = "Something went wrong!";
          };
        } catch (error) {
          console.error(error);
          receivedSeeMoreData.current = "Something went wrong!";
          setLoading(false);
        }
      }
      isFromColumnLevelFilters && setLoading(false);
    }
  };
  const fetchAndProcessHeaders = (isFootnote) => {
    getDynamicHeaderData()
      .then((dynamicHeadersLibrary) => {
        const width = 150;
        const filteredFieldName = "M_1";
        const showStatusPrivilege = getPrivilegeValue(privilagesForInternalApp, 'Status', 'display');
        let filteredHeaders = dynamicHeadersLibrary.filter(header => header.isVisible === 1 || header.isVisible === 3);
        if (isFootnote) {
          filteredHeaders = filteredHeaders.filter(header =>
            header?.fieldType?.toLowerCase() === Constant.INTERNALAPP_FIELD_VALUES.METADATA_FIELD.toLowerCase() ||
            header?.fieldType?.toLowerCase() === Constant.INTERNALAPP_FIELD_VALUES.METAFIELD.toLowerCase()
          );
        }
        const filteredArray = showStatusPrivilege ? filteredHeaders : filteredHeaders.filter(item => item.fieldName !== filteredFieldName);
        const dynamicHeadersArray = filteredArray?.map(({ fieldUniqueId, fieldName, type, isColumnLevelFilterEnabled }) => ({
          key: fieldUniqueId,
          name: fieldName,
          ariaLabel: fieldName,
          fieldName: fieldUniqueId,
          type: fieldUniqueId == filteredFieldName ? "Status" : type,
          minWidth: width,
          maxWidth: width,
          isResizable: true,
          isMultilineEnable: true,
          isFiltered: isColumnLevelFilterEnabled
        }));

        if (!dynamicHeadersArray) {
          setErrorApiCall(true);
          console.error('dynamicHeadersArray is null or undefined');
        }
        setDynamicHeaderDataLibrary(dynamicHeadersArray);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
        if (error?.response?.data?.Errors?.[0]?.toLowerCase() == "access denied.") {
          setAccessDenied(true);
        } else {
          setErrorApiCall(true);
        }
      });
  };
  const checkPrivilagesForInternalApp = async () => {
    if (!userInfo?.teams?.key) return;
    const contentTypeId = internalAppDetails?.contentTypeId;
    try {
      const response = await getData(
        APIEndPoints.GET_PAGE_LEVEL_FLAGS(
          userInfo?.teams?.companyId,
          null,
          contentTypeId
        )
      );
      const targetItem = await response.data.contentType.find(
        (item) => item.contentTypeId === contentTypeId
      );
      if (targetItem) {
        const featureFlagsDetails = internalAppDetails?.isGlobal ? targetItem?.subContentTypes?.[0]?.contentCategories?.[0]?.featureFlagsDetails
          : targetItem?.subContentTypes?.[0]?.contentCategories?.[1]?.featureFlagsDetails;

        if (featureFlagsDetails) {
          setPrivilagesForInternalApp(featureFlagsDetails);
        }
      }
    } catch (error) {
      if (
        error?.response?.data?.Errors[0]?.toLowerCase() === "access denied."
      ) {
        setAccessDenied(true);
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    if (librarySearchFormJsonData.specialCharacters.length < 2) {
      getData(APIEndPoints.ALLOWED_SPECIAL_CHARACTERS).then((response) => {
        if (response?.data) {
          handleLibrarySearchFormJsonData({
            specialCharacters: response?.data
          });
        }
      });
    }
    if (validContentTypeIdsForDirectLibraryPage.has(internalAppDetails?.contentTypeId)) {
      checkPrivilagesForInternalApp().then(() => {
        fetchAndProcessHeaders(false);
      });
    } else {
      fetchAndProcessHeaders(internalAppDetails?.contentTypeId == Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES);
    }
  }, []);

  useEffect(() => {
    if (toggleHamburger) {
      toggleHamburgerMenu();
    }
    setBreadCrumbValue();
    internalAppDetails.contentTypeId != 15 && setPrivilageForInternalApps();
    if (pageNo != 1) {
      getInitialData();
    }
  }, [pageNo]);

  useEffect(() => {
    if (Object.keys(privilagesForInternalApp)?.length > 0 && internalAppDetails.contentTypeId == 15) {
      setPrivilageForInternalApps();
    }
  }, [privilagesForInternalApp, pageNo])

  useEffect(() => {
    if (menuState.submenu.name === internalAppDetails?.breadCrumb) {
      getInitialData(true);
    } else if (currentUser.teams.key !== userInfo.teams.key) {
      resetGrid();
    }
    setCurrentUser(userInfo);
  }, [menuState.submenu.name]);

  const getAllowedChacter = () => {
    getData(APIEndPoints.ALLOWED_SPECIAL_CHARACTERS).then((response) => {
      if (response.data) {
        setSpecialCharacters(response.data);
      }
    });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        !isApiCalled &&
        menuState.submenu.name === internalAppDetails?.name
      ) {
        setIsApiCalled((prevValue) => {
          if (prevValue === false) {
            getAllowedChacter();
            return true;
          }
          return false;
        });
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [menuState.submenu.name]);

  const contextualMenuOptions = {};
  //reset the grid
  const resetGrid = () => {
    setPageNo(1);
    setGridData([]);
    sethasMoreData(true);
    getInitialData(true);
  };

  const handleSelection = (items, selectionCount) => {
    const isPublish = CheckPromptedStatus(items);
    const isSingleSelection = selectionCount === 1;
    const isMaxSelection = selectionCount === editConfigLimit;
    if (isMaxSelection) {
      setShowActionItems(true);
      handleNotificationMsgsData({
        showMsgBar: true,
        started: false,
        completed: true,
        msg: `Maximum ${editConfigLimit} files allowed.`,
        type: "info",
        isFailed: false,
      });
    } else {
      //setShowActionItems(isSingleSelection ? !(items[0]?.customData?.userLevel?.liked) : !checkDisLiked(items));
      setShowActionItems(true);
      setDisableDownload(true);
      setDisableEdit(true);
    }

    setDisablePublish(isPublish);
  };
  const getFieldUniqueId = (internalAppDetails, fieldName) => {
    return internalAppDetails?.headerDataResponse?.find(item => item.responsePropertyInfo?.responsePropertyName === fieldName)?.fieldUniqueId ?? null;
  }
  const CheckPromptedStatus = (items) => {
    const fieldUniqueId = getFieldUniqueId(internalAppDetails, 'status');
    return fieldUniqueId && items.some(item => item[fieldUniqueId] === Constant.STATUS_CONSTANTS.STATUS_PROMPTED);
  }

  const showNotification = (msg, type) => {
    handleNotificationMsgsData({
      showMsgBar: true,
      started: false,
      completed: true,
      msg,
      type,
      isFailed: false,
    });
  };

  const handleDownload = async (items) => {
    showNotification(Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_INIT_MSG, "Info");
    const tempId = items?.length ? items[0].templateId : templateDetails?.templateId;
    const metaDataIds = items?.map((item) => item.metaDataId);
    const { appID, contentTypeId } = internalAppDetails || {};
    const params = {
      metaDataIds,
      appId: appID,
      templateId: tempId,
      searchId: ""
    };
    if (internalAppDetails?.contentTypeId == Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES) {
      params.searchId = searchIdValue ?? "";
    }
    try {
      const response = await postData(
        params,
        APIEndPoints.DOWNLOAD_EXCEL_REPORT_INTERNAL_APPS(contentTypeId)
      );

      if (response.status !== 200) {
        showNotification(Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_FAILED_MSG, "Fail");
        return;
      }
      const resultData = response?.data?.resultData;
      if (resultData && typeof resultData === 'object') {
        const inputFileName = exportFilrNamesMapping[contentTypeId] || "";
        const isDownload = downloadExcelFile(resultData, inputFileName);
        const message = isDownload
          ? Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_SUCCESS_MSG
          : Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_FAILED_MSG;
        const type = isDownload ? "Success" : "Fail";
        showNotification(message, type);
      } else {
        showNotification(Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_FAILED_MSG, "Fail");
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error?.response?.data?.Errors?.[0]?.toLowerCase();
      if (errorMessage === "access denied.") {
        setAccessDenied(true);
      } else {
        const message = errorMessage || Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_FAILED_MSG;
        showNotification(message, "Fail");
      }
    }
  };

  const handlePublish = async (items) => {
    showNotification(Constant.INTERNALAPP_CONSTANTS.PUBLISH_INIT_MSG, "Info");
    const fieldUniqueId = getFieldUniqueId(internalAppDetails, 'status');
    const metaDataIds = fieldUniqueId ? items?.filter(item => item[fieldUniqueId] === Constant.STATUS_CONSTANTS.STATUS_PROMPTED).map(item => item.metaDataId) : [];
    const metaDataIDArrayLength = metaDataIds.length;
    if (metaDataIDArrayLength === 0) {
      showNotification(Constant.INTERNALAPP_CONSTANTS.PUBLISH_FAILED_OUTPUT_MSG, "Fail");
      return;
    }
    const { appID, contentTypeId } = internalAppDetails || {};
    const params = {
      metaDataIds,
      appId: appID,
      dataLibraryStatus: 2,
    };
    try {
      const response = await postData(
        params,
        APIEndPoints.UPDATE_DOCUMENT_STATUS_INTERNAL_APPS(contentTypeId, userInfo?.teams?.companyId)
      );

      if (response.status !== 200) {
        showNotification(Constant.INTERNALAPP_CONSTANTS.PUBLISH_FAILED_MSG, "Fail");
        return;
      }
      const resultStatus = response?.data.status;
      if (resultStatus === "success") {
        const message = metaDataIDArrayLength + Constant.INTERNALAPP_CONSTANTS.PUBLISH_SUCCESS_MSG;
        showNotification(message, "Success");
        getInitialData(true);
        setDisablePublish(false);
      } else {
        showNotification(Constant.INTERNALAPP_CONSTANTS.PUBLISH_FAILED_MSG, "Fail");
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error?.response?.data?.Errors?.[0]?.toLowerCase();
      if (errorMessage === "access denied.") {
        setAccessDenied(true);
      } else {
        const message = errorMessage || Constant.INTERNALAPP_CONSTANTS.PUBLISH_FAILED_MSG;
        showNotification(message, "Fail");
      }
    }
  };
  const countLikesAndDisLikeRecords = (items) => {
    let notDisLikedRecords = 0;
    let disLikedRecords = 0;

    for (let item of items) {
      if (item.customData?.userLevel?.liked) {
        disLikedRecords++;
      } else if (item.customData?.userLevel?.liked === false) {
        notDisLikedRecords++;
      }
    }

    return { notDisLikedRecords, disLikedRecords };
  }
  const handleDisLike = async (items) => {
    showNotification(Constant.INTERNALAPP_CONSTANTS.DISLIKE_INIT_MSG, "Info");
    const { notDisLikedRecords, disLikedRecords } = countLikesAndDisLikeRecords(items);
    const metaDataAndLikes = items
      ?.filter(item => item.customData?.userLevel?.liked === false)
      ?.map(({ metaDataId }) => ({
        metaDataId,
        isLiked: true,
      }));
    const metaDataAndLikesArrayLength = metaDataAndLikes.length || 0;
    if (metaDataAndLikesArrayLength === 0) {
      const message =
        items.length > 1
          ? Constant.INTERNALAPP_CONSTANTS.DISLIKE_WARNING_MSG
          : Constant.INTERNALAPP_CONSTANTS.DISLIKE_SINGLE_WARNING_MSG;
      showNotification(message, "Fail");
      return;
    }
    const { appID, contentTypeId } = internalAppDetails || {};
    const params = {
      appId: appID,
      metaDataList: metaDataAndLikes
    }
    try {
      const response = await postData(
        params,
        APIEndPoints.CONTENT_INTERNALAPPS_DISLIKE(
          contentTypeId,
          userInfo?.teams?.companyId)
      );

      const { status, data: { status: resultStatus } = {} } = response || {};

      if (status !== 200) {
        showNotification(Constant.INTERNALAPP_CONSTANTS.DISLIKE_FAILED_MSG, "Fail");
        return;
      }

      if (resultStatus === "success") {
        let message = "";
        if (notDisLikedRecords > 0) {
          const dislikeMsg =
            notDisLikedRecords === 1
              ? Constant.INTERNALAPP_CONSTANTS.DISLIKE_SINGLE_SUCCESS_MSG
              : Constant.INTERNALAPP_CONSTANTS.DISLIKE_SUCCESS_MSG;
          message = `${notDisLikedRecords} ${dislikeMsg} `;
        }

        if (disLikedRecords > 0) {
          const warningMsg =
            disLikedRecords === 1
              ? Constant.INTERNALAPP_CONSTANTS.DISLIKE_SINGLE_WARNING_MSG
              : Constant.INTERNALAPP_CONSTANTS.DISLIKE_WARNING_MSG;
          message += `${disLikedRecords} ${warningMsg}`;
        }
        showNotification(message, "Success");
        getInitialData(true);
      } else {
        showNotification(
          Constant.INTERNALAPP_CONSTANTS.DISLIKE_FAILED_MSG,
          "Fail"
        );
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error?.response?.data?.Errors?.[0]?.toLowerCase();
      if (errorMessage === "access denied.") {
        setAccessDenied(true);
      } else {
        const message = errorMessage || Constant.INTERNALAPP_CONSTANTS.DISLIKE_FAILED_MSG;
        showNotification(message, "Fail");
      }
    } finally {
      setShowActionItems(false);
    }
  };

  const handleEdit = async (items, selectionCount) => {
    navigate(`/apps/LibraryGrid/pdfPlayground/`, {
      state: {
        appDetails: internalAppDetails,
        selectedItemsDetails: items,
        privilagesPowerKApp: privilagesForInternalApp,
        templateDetails: templateDetails
      },
    });
  };

  const STATUS_CLASSES = {
    [Constant.STATUS_CONSTANTS.STATUS_NEW]: "status_pill_new",
    [Constant.STATUS_CONSTANTS.STATUS_IN_PROCESS]: "status_pill_in_progress",
    [Constant.STATUS_CONSTANTS.STATUS_PROCESSED]: "status_pill_processed",
    [Constant.STATUS_CONSTANTS.STATUS_PROMPTED]: "status_pill_prompted",
    [Constant.STATUS_CONSTANTS.STATUS_PUBLISHED]: "status_pill_published",
    [Constant.STATUS_CONSTANTS.STATUS_COMPLETED]: "status_pill_completed",
    [Constant.STATUS_CONSTANTS.STATUS_SKIPPED]: "status_pill_skipped",
    [Constant.STATUS_CONSTANTS.STATUS_FAILED]: "status_pill_failed",
  };

  const renderColumns = (fieldContent, column, item) => {
    if (!fieldContent) {
      return null;
    }
    switch (column.type) {
      case "Status":
        const statusClass = STATUS_CLASSES[fieldContent] || "status_pill_new";
        return (
          <span
            data-selection-disabled={true}
            className={`status_pill ${statusClass}`}
          >
            {fieldContent}
          </span>
        );
      case "DateTime":
        return <span>{utils.convertDate(fieldContent, false)}</span>;
      case "LetterCase":
        return <span>{fieldContent.toLowerCase()}</span>;
      case "link":
        return (
          <a href={fieldContent} target="_blank" rel="noopener noreferrer" className="SourceLink">
            {fieldContent}
          </a>
        );
      case "detailLink":
        return (
          <span className="detailLinkFlex">
            {fieldContent === Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_DETALINK_COLUMN_MSG &&
              (<div className="icon-column">
                <CiCircleInfo />
              </div>)}
            <div className="content-column">{fieldContent}</div>
          </span>
        );
      default:
        return fieldContent.length < CHARACTER_CONFIG_LIMIT
          ? <span>{fieldContent}</span>
          : <span><DescriptionCell longValues={fieldContent} /></span>;
    }
  };
  const columnFilteringUpdate = (fieldName, fieldNameFilters, applyOrClearFilter) => {
    setLoading(true);
    let createOrUpdateValues;
    if (fieldNameFilters.length > 0) {
      if (searchFilterObjectForPayload.hasOwnProperty(fieldName)) {
        createOrUpdateValues = searchFilterObjectForPayload;
        createOrUpdateValues[fieldName] = fieldNameFilters.map((item) => item.name);
        setSearchFilterObjectForPayload(() => { return createOrUpdateValues });
      }
      else {
        createOrUpdateValues = searchFilterObjectForPayload;
        createOrUpdateValues[fieldName] = fieldNameFilters.map((item) => item.name);
        setSearchFilterObjectForPayload(() => { return createOrUpdateValues });
      }

    }
    else {
      // const{[fieldName]: _, ...remainingFieldValues} = searchFilterObjectForPayload
      // const newarray = {...remainingFieldValues}
      const deleteArray = delete searchFilterObjectForPayload[fieldName];
      createOrUpdateValues = searchFilterObjectForPayload;
      setSearchFilterObjectForPayload(() => { return searchFilterObjectForPayload });

    }
    if (Object.keys(createOrUpdateValues).length > 0) {
      handleLibrarySearchFormJsonData({
        columnFilteringApplied: true,
        appliedFilteredValues: createOrUpdateValues,
        searchQuery: searchQueryValue,
        totalRecordsCount: matches
      });

    }
    else {
      handleLibrarySearchFormJsonData({
        columnFilteringApplied: false,
        appliedFilteredValues: {},
        searchQuery: "",
        totalRecordsCount: 0
      });

    }
    setPageNo(1);
    getInitialData(true, true);
  }
  const handleClickOpen = (event) => {
    event.preventDefault(); // Prevent the default link behavior
    setIsOpen(true);
  };

  const cancelSubmit = () => {
    setIsOpen(false);
    setAttachmentValidationMsg({});
    setNewAttachments([]);
    setIsDropzoneEnabled(true);
  };
  const dropzoneContent = () => {
    return (
      <div>
        <MdCloudUpload className="uploadButton_UploadReferenceGuide" />
        <p>
          Drag and drop your file or{" "}
          <span className={isDropzoneEnabled === true ? "Browse" : "BrowseDisabled"}>
            browse
          </span>
        </p>
      </div>
    );
  };

  const handleDropFileDialog = (files, fileTypes) => {
    setNewFiles(files);
    const isExcelFile =
      files?.[0]?.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (isExcelFile) {
      setAttachmentValidationMsg({
        message: "",
        isError: false,
        isValid: true,
      });
    }

    setIsExcel(isExcelFile);
    let fileValidation = {
      message: "",
      isError: false,
      isValid: true,
    };
    if (!isExcelFile) {
      fileValidation = {
        isValid: false,
        message:
          Constant.INTERNALAPP_CONSTANTS
            .INVALID_FILE_FORMAT_FOR_REFERENCE_GUIDE,
        isError: true,
      };
      setAttachmentValidationMsg(fileValidation);
    } else {
      fileValidation = validateFile(files[0], fileTypes);
      if (fileValidation.isValid) {
        setNewAttachments(files);
        setAttachmentValidationMsg(fileValidation);
        setIsDropzoneEnabled(false);
        setRemoveAnimation(true);
      } else {
        setNewAttachments(newAttachments.length > 0 ? newAttachments : []);
        setAttachmentValidationMsg(fileValidation);
        setIsDropzoneEnabled(true);
        setRemoveAnimation(false);
      }
    }
  };

  const validateFile = (files, allowedFiles) => {
    let validation = { isValid: true, message: "", isError: false };
    // Remove spaces from the file name
    let sanitizedFileName = files.name.replace(/\s+/g, "");
    let regex = new RegExp(specialCharacters?.replace("/", "//"));
    let fileNameRegex = new RegExp("(" + allowedFiles.join("|") + ")$");
    let fileSize = formatBytes(files.size).split(" ");
    if (!fileNameRegex.test(sanitizedFileName.toLowerCase())) {
      validation.isValid = false;
      validation.isError = true;
      validation.message = Constant.INTERNALAPP_CONSTANTS.INVALID_FILE_FORMAT_FOR_REFERENCE_GUIDE;
    } else if (!regex.test(sanitizedFileName.toLowerCase())) {
      validation.isValid = false;
      validation.isError = true;

      let substr = null;
      for (var i = 0; i < sanitizedFileName.length; i++) {
        if (!regex.test(sanitizedFileName[i])) {
          substr = sanitizedFileName[i];
          break;
        }
      }
      validation.message = "'" + substr + "' is not allowed in file name";
    } else if (sanitizedFileName.length > 250) {
      validation.isValid = false;
      validation.isError = true;
      validation.message = Constant.FILENAME_LENGTH_MESSAGE;
    } else if (files.size === 0) {
      validation.isValid = false;
      validation.isError = true;
      validation.message = Constant.EMPTY_FILE_MESSAGE;
    } else if (
      parseInt(fileSize[0].trim()) > 250 &&
      ["MB", "GB", "TB", "PB", "EB", "ZB", "YB"].includes(fileSize[1].trim())
    ) {
      validation.isValid = false;
      validation.isError = true;
      validation.message = Constant.FILESIZE_LIMIT_MESSAGE;
    }
    return validation;
  };

  const submitReferenceGuide = async () => {
    setIsOpen(false);
    showNotification(
      Constant.INTERNALAPP_CONSTANTS.UPLOAD_REFERENCE_GUIDE_INIT_MSG,
      "Info"
    );
    const { appID, contentTypeId } = internalAppDetails || {};
    const companyId = userInfo?.teams?.companyId;
    if (attachmentValidationMsg.isValid) {
      try {
        let formData = new FormData();
        formData.append("appId", appID);
        formData.append(
          "templateId",
          templateDetails?.templateId?.toString() ?? ""
        );
        formData.append(
          "ReferenceGuideDocumentInfo.FileDetail.ActualFileName",
          newFiles[0].path ?? ""
        );
        formData.append("ReferenceGuideDocument", newFiles[0]);

        const response = await postData(
          formData,
          APIEndPoints.UPLOAD_TPALERTS_REFERENCE_GUIDE(
            contentTypeId,
            companyId
          ),
          true
        );

        if (response.status !== 200) {
          showNotification(
            Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_FAILED_MSG,
            "Fail"
          );
          return;
        }

        const resultData = response?.data[0];
        if (resultData && typeof resultData === "object") {
          const message =
            resultData.status === "success"
              ? Constant.INTERNALAPP_CONSTANTS
                .UPLOAD_REFERENCE_GUIDE_SUCCESS_MSG
              : getErrorMessageFromResultData(resultData);
          const type = resultData.status === "success" ? "Success" : "Fail";
          showNotification(message, type);
        } else {
          showNotification(
            Constant.INTERNALAPP_CONSTANTS.UPLOAD_REFERENCE_GUIDE_FAILED_MSG,
            "Fail"
          );
        }
        setNewAttachments([]);
        setIsDropzoneEnabled(true);
        setRemoveAnimation(true);
      } catch (error) {
        const errorMessage = error?.response?.data?.Errors?.[0]?.toLowerCase();
        if (errorMessage === "access denied.") {
          setAccessDenied(true);
        } else {
          const message =
            errorMessage ||
            Constant.INTERNALAPP_CONSTANTS.UPLOAD_REFERENCE_GUIDE_FAILED_MSG;
          showNotification(message, "Fail");
        }
        setNewAttachments([]);
        setIsDropzoneEnabled(true);
        setRemoveAnimation(true);
      }
    } else {
      setNewAttachments(newAttachments.length > 0 ? newAttachments : []);
      setIsDropzoneEnabled(true);
      setRemoveAnimation(true);
    }
  };

  function getErrorMessageFromResultData(resultData) {
    if (
      resultData.statusCode === "STCS003" ||
      resultData.statusCode === "STCS004"
    ) {
      return resultData.statusMessage;
    } else {
      return Constant.INTERNALAPP_CONSTANTS.UPLOAD_REFERENCE_GUIDE_FAILED_MSG;
    }
  }
  const displayAttachments = () => {
    if (attachmentValidationMsg?.isError) {
      return (
        <div className="ErrorMessage_IncorrectFileType">
          <AiFillExclamationCircle className="ErrorIconStyle" />
          <Text
            error
            content={attachmentValidationMsg?.message}
            align="start"
            weight="regular"
          />
        </div>
      );
    }
  };

  const onRemoveItem = () => {
    setNewAttachments([]);
    setIsDropzoneEnabled(true);
    if (notificationMsgsData.isFailed === true) {
      handleNotificationMsgsData(...notificationMsgsData, { isFailed: false });
    }
  };

  const attachmentTitle = () => {
    return (
      <div
        title={`${newAttachments[0]?.name.length > 50 ? newAttachments[0]?.name : ""
          }`}
        className="uploadExcelAttachments"
      >
        <span className={`ProgressbarHeader_UploadReferenceGuide`}>
          <AiFillFileText /> {newAttachments[0]?.name}
        </span>
      </div>
    );
  };

  const closeLoader = () => {
    setLoading(false);
  };
  if (errorApiCall) return <PageNotFound />;
  if (errorAccessDenied) return <UnauthorizedPage />;
  return (
    <div className="powerKLibrary_container">
      <div
        className={`powerKLibrary_cover ${!loading && "hide_cover_powerKLibrary"
          }`}
      >
        <ReusableLoader />
      </div>
      {
        <div className="library_content">
          {gridData && (
            <div className="common_container_subtitle_powerKLibrary">
              <div className="common_container_rightMenu_powerKLibrary">
                <div className="divTxtSubmenu_powerKLibrary">
                  <header style={{ maxWidth: "100%" }}>
                    <p style={{ display: "flex" }}>
                      {
                        <>
                          <span className="commonTitleEllipses_powerKLibrary showEllipses_powerKLibrary">
                            {
                              Constant.INTERNALAPP_CONSTANTS
                                .LIBRARY_SCREEN_HEADER
                            }
                          </span>
                        </>
                      }
                    </p>
                  </header>
                </div>
                <div className="divTxtSubmenu_powerKLibrary_Right">
                  {tpAlerts && (
                    <BackButton
                      allowRedirection={true}
                      pathToBeRedirected={"/apps"}
                      isCloseClicked={true}
                      className="TPalerts-close-btn"
                    />
                  )}
                  {specialContentTypesMappingForInternalApps[
                    internalAppDetails?.contentTypeId
                  ] && (
                      <div className="backToLibrarySearchLink_PKLibrary">
                        <Link
                          to={"/apps/LibrarySearchPage"}
                          state={{
                            appDetails: internalAppDetails,
                            privilagesPowerKApp: privilagesForInternalApp,
                            templateDetails: templateDetails,
                          }}
                          className="linkStyleDetails_powerKLibrary"
                        >
                          {" "}
                          <IoArrowBack />{" "}
                          {Constant.INTERNALAPP_CONSTANTS.BACK_TO_LIBRARY_SEARCH}
                        </Link>
                      </div>
                    )}

                  {internalAppDetails && internalAppAdmin && (contentTypeMappingDatabaseLinkShow[internalAppDetails?.contentTypeId]) && (
                    <div className="dataBaseLink_powerKLibrary">
                      <Link
                        to="/apps/DatabaseGrid"
                        state={{
                          appDetails: internalAppDetails,
                          privilagesPowerKApp: privilagesForInternalApp,
                          templateDetails: templateDetails,
                        }}
                        className="databaseLinkStyleDetails_internalApp"
                      >
                        <TbDatabaseSearch />
                        {specialContentTypesMappingForInternalApps[
                          internalAppDetails?.contentTypeId
                        ]
                          ? Constant.INTERNALAPP_CONSTANTS.DATABASE_LINK
                          : Constant.INTERNALAPP_CONSTANTS
                            .DATABASE_LINK_TPALERTS}
                      </Link>
                    </div>
                  )}
                  {internalAppDetails?.contentTypeId ==
                    Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS && isUploadReferenceGuideDisplay && (
                      <div className="upload_reference_guide_container">
                        <button
                          className="btn_upload_reference_guide"
                          onClick={handleClickOpen}
                          disabled={!isUploadReferenceGuideEnabled}
                        >
                          <HiUpload />
                          {Constant.INTERNALAPP_CONSTANTS.UPLOAD_REFERENCE_GUIDE}
                        </button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
          {gridData &&
            contentTypeMappingForRibbonShowcase[
            internalAppDetails?.contentTypeId
            ] && (
              <div className="common_container_ribbon-container">
                {matches > 0 ? (
                  <span className="ribbon-text"
                    dangerouslySetInnerHTML={{
                      __html: Constant.SEARCH_AND_FILTER_COM_CONSTANTS.FOUND_MACHING_RECORDS_FOR_SEARCH_CRITERIA(matches)
                    }}
                  />
                ) : (
                  <span className="ribbon-text">{Constant.SEARCH_AND_FILTER_COM_CONSTANTS.NO_SEARCH_RESULT_FOUND}</span>
                )}
                {internalAppDetails?.contentTypeId !== Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES && (window.innerWidth > 431 ? (
                  <Button
                    className="button-without-border"
                    onClick={toggleDiv}
                    content={
                      isVisibleSeeDetails ? "Hide Details" : "See Details"
                    }
                    text
                  />
                ) : isVisibleSeeDetails ? (
                  <FaChevronUp
                    className="button-without-border"
                    onClick={toggleDiv}
                  />
                ) : (
                  <FaChevronDown
                    className="button-without-border"
                    onClick={toggleDiv}
                  />
                )
                )}
              </div>
            )}
          {(internalAppDetails?.contentTypeId !== Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES && isVisibleSeeDetails) ? (
            <div className="seeMoreSectionClass">
              {seeMoreMetaData?.length > 0 && internalAppDetails?.version == Constant.SEARCH_TEMPLATE_VERSIONS.OLD_TEMPLATE ? (
                <div className="seeMoreOuterClass">
                  {" "}
                  {seeMoreMetaData?.map((item, itemIndex) => (
                    <div className="seeMoreSectionInnerClass">
                      <div className="seeMoreSectionFirstThree">
                        {itemIndex !== seeMoreMetaData?.length - 1 ? (
                          <span className="seeMoreFieldName">
                            {item?.fieldName}:
                          </span>
                        ) : (
                          <span>{item?.fieldName}</span>
                        )}
                        {itemIndex !== seeMoreMetaData?.length - 1 ? (
                          <span className="seeMoreOperand">
                            {item?.operand}
                          </span>
                        ) : (
                          ""
                        )}
                        {itemIndex !== seeMoreMetaData?.length - 1 ? (
                          <span className="seeMoreValue">
                            {item?.fieldName.toLowerCase() === "filling date"
                              ? item?.value.replace(",", " -")
                              : item?.value}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <span className="seeMoreCount">
                        {item?.count} Records
                      </span>
                    </div>
                  ))}{" "}
                </div>
              ) : (
                receivedSeeMoreData.current
              )}
              {seeDetailsGridData?.length > 0 &&  internalAppDetails?.version == Constant.SEARCH_TEMPLATE_VERSIONS.NEW_TEMPLATE && (
                <div className="seeMoreOuterClass">
                  {" "}
                  {seeDetailsGridData?.map((item, itemIndex) => (
                    <div className="seeMoreSectionInnerClass">
                      <div className="seeMoreSectionFirstThree">
                        <span className="seeMoreFieldName">
                          {item?.searchParamsData?.displayName}:
                        </span>
                        <span className="seeMoreOperand">
                          {item?.userInputs?.selectedOperators?.map(operator => operator?.toUpperCase())?.includes("NOT") == true && item?.userInputs?.selectedOperators?.map(operator => operator?.toUpperCase())}
                          
                        </span>
                        <span className="seeMoreValue">
                          {item?.searchParamsData?.displayName.toLowerCase() === "filling date"
                            ? `${item?.userInputs?.selectedValues[0]} - ${item?.userInputs?.selectedValues[1]}`
                            : item?.searchParamsData?.isMultiSelect ? item?.userInputs?.selectedValues.join(" | ")
                            : item?.userInputs?.selectedValues}
                        </span>
                      </div>
                      <span className="seeMoreCount">
                        {item?.customData?.stepCount} Records
                      </span>
                    </div>
                  ))}{
                    Object.keys(executedData)?.length > 0 &&
                    <div className="seeMoreSectionInnerClass">
                      <div className="seeMoreSectionFirstThree">
                        <span>
                          {executedData?.fieldName}
                        </span>
                      </div>
                      <span className="seeMoreCount">
                        {executedData?.cascadeCount} Records
                      </span>
                    </div>
                  }{" "}
                </div>
              )}
            </div>
          ) : (
            ""
          )}
          {internalAppDetails?.contentTypeId === Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES ? (
            <div className="reportDetailsViewPage">
              <PowerBiReport
                item={pbReportConfig}
                closeLoader={closeLoader}
              />
            </div>
          ) : (
            gridData && (
              <DataGrid
                _columnsFromProps={dynamicHeaderDataLibrary}
                _data={gridData}
                _contextualMenuOptionsFromProps={contextualMenuOptions}
                handleDownload={handleDownload}
                handlePublish={handlePublish}
                handleDisLike={handleDisLike}
                handleEdit={(items, selectionCount) => {
                  handleEdit(items, selectionCount);
                }}
                loadMoreData={loadMoreData}
                hasMoreData={hasMoreData}
                pageNo={pageNo}
                handleSelection={(items, selectionCount) => {
                  handleSelection(items, selectionCount);
                }}
                disableDownload={!disableDownload}
                disableEdit={!disableEdit}
                disablePublish={!disablePublish}
                renderColumns={renderColumns}
                displayDownload={getPrivilegeValue(
                  privilagesForInternalApp,
                  "Export",
                  "display"
                )}
                displayEdit={getPrivilegeValue(
                  privilagesForInternalApp,
                  "Edit",
                  "display"
                )}
                displayView={getPrivilegeValue(
                  privilagesForInternalApp,
                  "DetailsPage",
                  "display"
                )}
                displayPublish={getPrivilegeValue(
                  privilagesForInternalApp,
                  "PublishLibrary",
                  "display"
                )}
                displayActionItems={true}
                tpAlerts={tpAlerts}
                displayDelete={false}
                displayRefresh={false}
                disableActionItems={!disableActionItems}
                privilagesInternalApp={privilagesForInternalApp}
                configLimit={editConfigLimit}
                fileID="metaDataId"
                pageRoute="Library"
                appDetails={internalAppDetails}
                appSearchQueryValue={searchQueryValue}
                columnFilteringUpdateMethodDataGrid={(
                  fieldName,
                  fieldNameFilters,
                  applyOrClearFilter
                ) => {
                  columnFilteringUpdate(
                    fieldName,
                    fieldNameFilters,
                    applyOrClearFilter
                  );
                }}
                searchFilters={searchFilterObjectForPayload}
                //initialFilterAppliedCountProperties ={Object.keys(searchFilterObjectForPayload).length>0 ? }
                templateDetails={templateDetails}
              />
            )
          )}
        </div>
      }

      {
        <DialogBox
          openDialog={isOpen}
          content={
            <div
              className="DatadropBody_UploadReferenceGuide"
            >
              <UploadData
                multiple={false}
                fileTypes={[".xlsx"]}
                isDropzoneEnabled={isDropzoneEnabled}
                dropzoneContent={dropzoneContent}
                handleDropFile={handleDropFileDialog}
                rootProps={
                  isDropzoneEnabled === true
                    ? { className: "DropzoneBodyUploadReferenceGuide" }
                    : { className: "DropzoneBodyUploadReferenceGuide DropzoneBodyDisabledUploadReferenceGuide" }
                }
                isDataDrop={true}
              />
              <Attachment
                files={newAttachments}
                onRemoveClick={onRemoveItem}
                uploadProgress={progress}
                title={attachmentTitle}
                actionTitle={""}
                actionIcon={<GrClose />}
                isErrorClass={false}
                description={
                  notificationMsgsData?.type?.toLowerCase() === "fail"
                    ? null
                    : progress === 100
                      ? newAttachments?.length > 0 &&
                      formatBytes(newAttachments[0]?.size)
                      : null
                }
                removeTransition={removeAnimation}
                actionable={true}
                progress={0}
                isPdf={isExcel}
              />
              {displayAttachments()}
            </div>
          }
          headerName={Constant.INTERNALAPP_CONSTANTS.UPLOAD_REFERENCE_GUIDE}
          headerActionName={{
            icon: <GrClose />,
            title: "Close",
            onClick: cancelSubmit,
          }}
          backdrop={true}
          className={`dialogDeleteStylesInternal dialogHeaderInternal dialogBoxInternal`}
          closeOnOutsideClick={false}
          footer={
            <FooterTechButtons
              disabled={isConfirmButtonDisabled}
              cancelText={CANCEL_TEXT}
              sendText={SUBMIT_TEXT}
              onClickAddData={submitReferenceGuide}
              onClickCancel={cancelSubmit}
            />
          }
        />
      }
    </div>
  );
};

const DescriptionCell = ({ longValues }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const textToDisplay = useMemo(() => {
    return isExpanded ? longValues : longValues?.slice(0, 100);
  }, [isExpanded, longValues]);
  return (
    <div onClick={(event) => event.stopPropagation()}>
      <span className={isExpanded ? 'expand' : 'truncate'}>
        {textToDisplay}
      </span>
      <button className="ReadContent" onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? 'Read Less' : 'Read More'}
      </button>
    </div>
  );
};

export default LibraryGrid;
