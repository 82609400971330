import { Dropdown } from "@fluentui/react-northstar";
import React, { useState, useEffect, useRef } from "react";
import "./peoplePicker.css";
import { MdError } from "react-icons/md";
import { checkIfInputIsValid } from "../../../utility/validator/controlvalidations";
import { getData, postData } from "../../../services/customApis";
import Constant from "../../../utility/constants";
import {
  ImageUtil,
  validateEmail,
  validateEmails,
} from "../../../utility/utility";
import APIEndPoints from "../../../utility/apiendpoints";
import { UseformDataContext } from "../../../context/genericdatacontext/formdatacontext";
import { UseUserInfoContext } from "../../../context/usercontext/userContext";
import _ from "lodash";

const PeoplePicker = (props) => {
  const [currentValue, setCurrentValue] = useState([]);
  const [hasAnyError, setError] = useState(props?.hasAnyError);
  const [errorMessage, setErrorMessage] = useState(props?.errMessage);
  const [loading, setLoading] = useState(false);
  const [noResultMessage, setnoResultMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [userGroupsData, setUserGroups] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const allValidations = props?.validations;
  const { handleFormDataIuput } = UseformDataContext();
  const [isFocused, setIsFocused] = useState(false);
  let { userInfo } = UseUserInfoContext();

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(true);
  };

  useEffect(() => {
    setError(props.hasAnyError);
    setErrorMessage(props.errMessage);
  }, [props?.hasAnyError]);

  useEffect(() => {
    // Function to run when a click event occurs
    const handleClickOutside = (event) => {
      // Check if the dropdown contains the target of the click event
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
  
    // Add the event listener
    document.addEventListener('mousedown', handleClickOutside);
  
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (props.editMode == true) {
      setSelectedUsers(props?.value);
      props.updateValidationState(
        props?.name,
        props?.value,
        props?.hasAnyError,
        props?.errMessage
      );
      props.setEditProperties({ [props?.name]: false });
    }
    if (props.fromCustomUserGroup) {
      setSelectedUsers(props?.value);
    }
  }, [props?.value]);

  const resetEmailList = () => {
    if (users.length < 1 && props?.freeFormEmail) {
      handleFormDataIuput({
        recipient: {
          userEmailList: [],
          userGroupList: [],
        },
      });
    }
  };

  const handlePeoplePickerChange = (
    event,
    itemData,
    fieldsName,
    name,
    isCustomField
  ) => {
    let emailsAsArray = itemData?.value
      ?.filter((item) => {
        return item.userid !== "";
      })
      .map((item) => {
        return item.userid;
      });

    let idsAsArrayForUserGroup = itemData?.value
      ?.filter((item) => {
        return item.userid == "";
      })
      .map((item) => {
        return {
          Id: item?.key,
          Name: item?.name,
        };
      });

    if (props?.isContentCuration) {
      setSelectedUsers(itemData.value);
      handleFormDataIuput({
        recipient: {
          userEmailList: emailsAsArray,
          userGroupList: [],
        },
      });
      if (allValidations != undefined && allValidations.length > 0) {
        const { hasError, errMessage } = checkIfInputIsValid(
          allValidations,
          itemData.value
        );
        setError(hasError);
        setErrorMessage(errMessage);
        setCurrentValue(itemData);
        props?.onUserChange(
          event,
          itemData.value,
          fieldsName,
          name,
          isCustomField,
          hasError,
          errMessage
        );
      } else {
        setCurrentValue(itemData.value);
        props?.onUserChange(
          event,
          itemData.value,
          fieldsName,
          name,
          isCustomField,
          false,
          ""
        );
      }
    } else {
      setSelectedUsers(itemData.value);
      // let emailsAsArray = itemData.value.map((item) => {
      //   return item.userid;
      // });
      if (props?.userManagement) {
        props?.onUserChange(itemData?.value);
        props?.setExternalUserVal(
          itemData?.value?.[0]?.isInternal !== undefined
            ? !itemData?.value?.[0]?.isInternal
            : false
        );
      }
      if (props?.fromCustomUserGroup) {
        props?.onUserChange(itemData?.value);
        // props?.setExternalUserVal(
        //   itemData?.value?.[0]?.isInternal !== undefined
        //     ? !itemData?.value?.[0]?.isInternal
        //     : false
        // );
      }
      handleFormDataIuput({
        recipient: {
          userEmailList: emailsAsArray,
          userGroupList: idsAsArrayForUserGroup,
        },
      });
    }
  }; 
  
  const getUserGroups = async () => {
    let resultedTeams = [];
    setIsFocused(true);
    let param = {
      pageSize: 10,
      page: 1,
      filter: "",
    };
   await postData(
    param,
    APIEndPoints.GET_CUSTOM_USER_GROUPS(userInfo?.teams?.companyId)
  )    
    .then((response) => {        
        const newData =
          response.data.resultData === undefined
            ? response?.data
            : response?.data?.resultData;
            console.log("newData", newData);

            if (newData.length > 0) {
              newData.map((element) => {
                resultedTeams.push({
                  key: element.customUserGroupId,
                  header: element.customUserGroupName,
                  name: element.customUserGroupName,
                  content: "User Group",
                  image: ImageUtil(element.customUserGroupName),
                  userid: "",
                  disabled: props?.isInternalApp == true ? true : false,
                  ...element,                
                });
                setUserGroups(resultedTeams) ;
               
              });
            } 
    });
    
  };  
 
  
  const onClickSearch =  (e) => {
    if (users.length === 0 && props?.fromChatCalenderSendMail){
      setIsClicked(true);
      setIsOpen(true);
      getUserGroups();
    }     
    console.log('setIsClicked', isClicked); 
    
  };

  const onUserSearchQueryChange = async (event, itemsData) => {
    let resultedTeams = [];
    setIsFocused(true);
    setIsClicked(false);
    setIsOpen(true);
    if (props?.userManagement) {
      props?.inviteUser(false);
      props?.setExternalUserVal(false);
      props?.clearSearchQuery &&
        props?.setClearSearchQueryVal(itemsData.searchQuery.length < 1);
    }
    if (!itemsData.searchQuery) {
      setUsers([]);
      setnoResultMessage("");
      resetEmailList();
    } else if (itemsData.searchQuery && itemsData.searchQuery.length <= 2) {
      setLoading(false);
      setnoResultMessage(Constant.HINT_MESSAGE);
      if (users.length < 1 && props?.freeFormEmail) {
        resetEmailList();
      }
    } else if (itemsData.searchQuery && itemsData.searchQuery.length > 2) {
      const result =
        itemsData.items &&
        itemsData.items.find((item) => {
          return item?.displayName
            ?.toLowerCase()
            ?.includes(itemsData?.searchQuery?.toLowerCase());
        });
      if (result) {
        return;
      }
      setLoading(true);
      setnoResultMessage("");
      try {
        const query = encodeURIComponent(
          itemsData?.searchQuery?.toLowerCase().trim()
        );
        await getData(
          APIEndPoints.AD_USER_SEARCH(
            query,
            props?.userType ?? null,
            props?.fromChatCalenderSendMail || props?.fromCustomUserGroup
              ? userInfo.teams.companyId
              : null,
            props?.searchAd ?? null,
            props?.searchUserGroup ?? null
          )
        )
          .then((response) => {
            let res = response?.["data"]?.filter((i) => {
              return props?.isContentCuration
                ? _.includes(i?.email, "ey.com")
                : i;
            });
            if (res.length > 0) {
              res
                .filter((obj, index) => {
                  return (
                    index ===
                    res.findIndex(
                      (o) => obj.id === o.id && obj.email === o.email
                    )
                  );
                })
                .map((element) => {
                  resultedTeams.push({
                    key: element.id || element?.email,
                    header:
                      element.displayName !== "" && element.displayName !== null
                        ? element.displayName
                        : element?.email,
                    name:
                      element.displayName !== "" && element.displayName !== null
                        ? element.displayName
                        : element?.email,
                    // content: element.designation,
                    content:
                      element.email !== "" && element.email !== null
                        ? element.email
                        : "User Group",
                    image: ImageUtil(element.displayName),
                    userid: element.email ?? "",
                    disabled: props?.isInternalApp == true ? true : false,
                    ...element,
                  });
                  setUsers(resultedTeams);
                  setLoading(false);
                  setnoResultMessage("");
                });
            } else if (props?.freeFormEmail && response?.data?.length === 0) {
              let emails = validateEmails(itemsData.searchQuery);
              if (emails?.isValid) {
                // props?.inviteUser(true);
                // props?.setFreeFormUserVal(itemsData.searchQuery);
                handleFormDataIuput({
                  recipient: {
                    userEmailList: [...emails?.validEmails],
                    userGroupList: [],
                  },
                });
                setnoResultMessage("");
              } else {
                setnoResultMessage(Constant.ENTER_VALID_EMAIL_MSG_FREE_FORM);
                handleFormDataIuput({
                  recipient: {
                    userEmailList: [],
                    userGroupList: [],
                  },
                });
              }
              setLoading(false);
            } else {
              setUsers(resultedTeams);
              setLoading(false);
              setnoResultMessage(Constant.NO_RESULT_MESSAGE);
            }

            if (props?.userManagement && response?.data?.length === 0) {
              setUsers([]);
              if (
                /[.@]/.test(itemsData.searchQuery) &&
                !userInfo?.isDemoCompany
              ) {
                if (validateEmail(itemsData.searchQuery)) {
                  props?.inviteUser(true);
                  props?.setFreeFormUserVal(itemsData.searchQuery);
                  setnoResultMessage("");
                } else {
                  setnoResultMessage(Constant.ENTER_VALID_EMAIL_MSG);
                }
              } else {
                setnoResultMessage(
                  userInfo?.isDemoCompany
                    ? Constant.NO_RESULT_MESSAGE
                    : Constant.NO_RESULT_INVITE_USER_MSG
                );
              }
            }
          })
          .catch((error) => {
            setLoading(false);
            setnoResultMessage(Constant.NO_RESULT_MESSAGE);
          });
      } catch (error) {
        return error;
      }
    }
  };  

  // const makeDropdownItems = (items) => {
  //   const resultedTeams = [];
  //   if (items) {
  //     items.forEach((element) => {
  //       //console.log(element.ImageUrl.isEmpty());
  //       resultedTeams.push({
  //         key: element.id || element?.email,
  //         header:
  //           element.displayName !== "" && element.displayName !== null
  //             ? element.displayName
  //             : element?.email,
  //         name:
  //           element.displayName !== "" && element.displayName !== null
  //             ? element.displayName
  //             : element?.email,
  //         // content: element.designation,
  //         content: element.email || element?.displayName,
  //         image: ImageUtil(element.displayName),
  //         userid: element.email ?? "",
  //         disabled: props?.isInternalApp == true ? true : false,
  //         ...element,
  //       });
  //     });
  //   }
  //   return resultedTeams;
  // }; 

  // const getUserItems = () => {
  //   if (users.length > 0) {
  //     return makeDropdownItems(users);
  //   }
  //   const dropdownItems = [];
  //   return dropdownItems;
  // };

  useEffect(() => {
    props?.clearSearchQuery && setSearchQuery("");
    props?.clear && setSelectedUsers([]);
  }, [props?.clearSearchQuery, props?.clear]);

  const wrapperRef = useRef(null);
  return (
    <>
      <div className="formPeoplePickerStyles">
        <Dropdown
          ref={wrapperRef}
          about={props?.about}
          search={props?.search}
          multiple={props?.multiple}
          items={ isClicked? userGroupsData : users }
          loading={loading}
          loadingMessage={Constant.LOADING_TEXT}
          onSearchQueryChange={(event, itemData) => {
            if (itemData.searchQuery !== "undefined undefined") {
              setSearchQuery(itemData.searchQuery);              
              onUserSearchQueryChange(event, itemData);
            }
          }}
            // defaultOpen={props?.fromChatCalenderSendMail}
           // open = {props?.fromChatCalenderSendMail}
          open={isOpen && (props?.fromChatCalenderSendMail ?(isClicked ? userGroupsData : users ): users) }
          searchQuery={searchQuery}
          noResultsMessage={noResultMessage}
          onChange={(event, itemData) => {
            handlePeoplePickerChange(
              event,
              itemData,
              props?.fieldsName,
              props?.name,
              props?.isCustomField
            );
          }}
          value={selectedUsers}
          placeholder={props?.placeholder}
          // itemToString={(item) => item?.content}
          itemToString={(item) => item?.header + " " + item?.content}
          checkable={props?.checkable}
          fluid={props?.fluid}
          getA11ySelectionMessage={{
            onAdd: (item) => `${item} has been selected.`,
          }}
          className={`peoplePickerdropdown ${
            hasAnyError ? "peoplepicker_error" : ""
          } ${isFocused ? "" : "hideDropdownList"} ${props?.className} ${
            props?.clearSearchQuery ? "noResultMessage" : ""
          } ${props?.disabled == true ? "disableSelectedVals" : ""}`}
          onChangeCapture={(data) => {
            console.log(data);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          // allowFreeform={props?.userManagement}
          id={props?.id}
          disabled={props?.disabled}
          onClick={(event)=> {onClickSearch(event);}}       
        />
      </div>
      <div>
        {hasAnyError && (
          <>
            <MdError color="rgb(196, 49, 75)" />
            <span className="errMessage">{errorMessage}</span>
          </>
        )}
      </div>
    </>
  );
};

export default PeoplePicker;
